import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  ReactPlugin,
  withAITracking
} from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { envs } from './configs/envs';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey: envs.get('REACT_APP_INSIGHTS_INSTRUMENTATIONKEY'),
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
});
ai.loadAppInsights();

export default (Component: React.ComponentType<unknown>) =>
  withAITracking(reactPlugin, Component);
export const { appInsights } = ai;
