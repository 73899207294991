import { useEffect, useMemo } from 'react';
import { Routes } from '../../../../routes/Routes';
import { Header, NavContainer, NavItem } from './styles';
import { useDashboard } from '../../hooks/useDashboard';
import AuthHelper from '../../../../helpers/auth';
import { ROLES } from '../../../../constants/roles';
import { useGetMalls, useGetMallStores } from '../../../../api';
import { Malls } from '../../../../api/mutator/analytics/get-malls';
import Autocomplete from '../../../../components/Common/Autocomplete';

interface DashboardHeaderProps {
  title: string;
  subtitle: string;
}

type StoreListType = string;

export function DashboardHeader({ title, subtitle }: DashboardHeaderProps) {
  const {
    handleSelectStore,
    selectedStore,
    stores: storesNonAdminUser,
    handleSelectShopping,
    selectedShopping
  } = useDashboard();
  const hasAdminPermission = AuthHelper.userHasPermission([
    ROLES.AnalyticsManager.slug
  ]);
  const { data: shoppingData, isLoading: isShoppingListLoading } =
    useGetMalls();
  const { data: storesAdminUser, isLoading: isStoreListAdminUserLoading } =
    useGetMallStores(selectedShopping || 0, {
      query: {
        enabled: !!selectedShopping && !!hasAdminPermission
      }
    });

  const shoppingList = useMemo(() => shoppingData ?? [], [shoppingData]);

  const onSelectStore = (newValue: StoreListType | string) => {
    handleSelectStore(newValue ?? '');
    localStorage.setItem('selectedStore', newValue ?? '');
  };

  const onSelectShopping = (newValue: Malls | string) => {
    handleSelectShopping(
      String(typeof newValue === 'object' ? newValue?.id : '')
    );
    handleSelectStore('');
    localStorage.setItem('selectedStore', '');
    localStorage.setItem(
      'selectedShopping',
      String(typeof newValue === 'object' ? newValue?.id : '')
    );
  };

  const selectedShoppingValue = useMemo(
    () =>
      hasAdminPermission &&
      localStorage.getItem('selectedShopping') &&
      shoppingList.length
        ? localStorage.getItem('selectedShopping')!
        : '',
    [selectedShopping, shoppingList]
  );

  const storeList = useMemo(() => {
    if (hasAdminPermission && selectedShoppingValue) {
      return storesAdminUser
        ? (storesAdminUser.map(
            (storesDataItem) => `${storesDataItem.luc}-${storesDataItem.brand}`
          ) as unknown as StoreListType[])
        : [];
    }
    if (!hasAdminPermission) {
      return storesNonAdminUser.map(
        (storesItem) => storesItem.store
      ) as unknown as StoreListType[];
    }
    return [];
  }, [storesNonAdminUser, storesAdminUser]);

  const selectInputShoppingValue = useMemo(
    () =>
      shoppingList.find(
        (list) => String(list.id) === selectedShoppingValue
      ) as unknown as Malls,
    [selectedShoppingValue]
  );

  const selectedStoreValue = useMemo(
    () =>
      localStorage.getItem('selectedStore') && storeList.length
        ? localStorage.getItem('selectedStore')!
        : '',
    [selectedStore, storeList]
  );

  const selectInputStoreValue = useMemo(
    () =>
      storeList.find(
        (list) => list === selectedStoreValue
      ) as unknown as StoreListType,
    [selectedStore, storeList]
  );

  useEffect(() => {
    if (
      hasAdminPermission &&
      shoppingList.length &&
      localStorage.getItem('selectedShopping')
    )
      handleSelectShopping(localStorage.getItem('selectedShopping')!);
    if (storeList.length && localStorage.getItem('selectedStore'))
      handleSelectStore(localStorage.getItem('selectedStore')!);
  }, [shoppingList, storeList]);

  return (
    <Header>
      <div className="title-container">
        <h1>{title}</h1>
        {hasAdminPermission && shoppingList && (
          <Autocomplete
            key="dashboard-shopping-selected"
            testid="dashboard-header-select-store"
            sx={{ background: 'white', fontWeight: 'bold' }}
            value={selectInputShoppingValue || null}
            getOptionLabel={(option: Malls | string) =>
              typeof option === 'string' ? '' : option?.name
            }
            options={shoppingList}
            loading={isShoppingListLoading}
            onChange={onSelectShopping}
            inputlabel="Selecione um Shopping"
          />
        )}

        <Autocomplete
          key="dashboard-luc-selected"
          sx={{ background: 'white', fontWeight: 'bold' }}
          value={selectInputStoreValue || null}
          isOptionEqualToValue={(option: StoreListType, value: StoreListType) =>
            option === value
          }
          options={storeList}
          loading={hasAdminPermission && isStoreListAdminUserLoading}
          onChange={onSelectStore}
          inputlabel="Selecione uma Loja"
          disabled={!storeList?.length}
        />
      </div>
      <p className="subtitle">{subtitle}</p>
      <NavContainer>
        <ul>
          <li>
            <NavItem to={`${Routes.DashboardStoreMonthly}`}>
              Visão Mensal
            </NavItem>
          </li>
          <li>
            <NavItem to={`${Routes.DashboardStoreDaily}`}>Visão Diária</NavItem>
          </li>
          <li>
            <NavItem to={`${Routes.DashboardStoreMall}`}>
              Visão Shopping
            </NavItem>
          </li>
        </ul>
      </NavContainer>
    </Header>
  );
}
