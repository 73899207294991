import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { GetBrandsBySegmentResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';

type BrandsBySegment = string;

const mapper = (res: AxiosResponse<GetBrandsBySegmentResponseDto[]>) => {
  const { data } = res;
  const result = data.map((item) => item.name);

  return result as unknown as Promise<BrandsBySegment[]>;
};

// eslint-disable-next-line
export const getBrandsBySegmentMutator = <T>(
  config: AxiosRequestConfig
): Promise<BrandsBySegment[]> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getBrandsBySegmentMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
