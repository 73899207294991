import { ApexOptions } from 'apexcharts';
import { formatMoneyToBRL } from '../../../../helpers/money';
import { themeColors } from '../../../../theme/schemes/PureLightTheme';

interface ChartsOptions {
  colors: string[];
  stacked: boolean;
  columnWidth: string;
}

export const buildChartConfigs = ({
  colors,
  stacked,
  columnWidth
}: ChartsOptions): ApexOptions => {
  return {
    chart: {
      stacked
    },
    noData: {
      align: 'center',
      text: 'Dados indisponíveis no momento',
      verticalAlign: 'middle'
    },
    colors: [
      ({ dataPointIndex }: { dataPointIndex: number }) => {
        for (let i = 0; i <= colors.length - 1; i = 1 + i) {
          if (dataPointIndex === i) {
            return colors[i];
          }
        }

        return colors[0];
      }
    ],
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth
      }
    },
    grid: {
      yaxis: {
        lines: {
          show: true
        }
      },
      strokeDashArray: 5
    },
    yaxis: {
      labels: {
        style: {
          fontWeight: 700,
          colors: themeColors.grayGoose[150]
        },
        formatter: (val: string | number) => {
          const result = Number(val) / 1000;
          if (result >= 1000) {
            return `R$ ${Number(val) / 1000000} MM`;
          }
          return `R$ ${Number(val) / 1000} M`;
        }
      }
    },
    xaxis: {
      labels: {
        style: {
          fontWeight: 700,
          colors: themeColors.grayGoose[150]
        }
      }
    },
    tooltip: {
      y: {
        formatter: (val: string | number) => formatMoneyToBRL(Number(val))
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: -10
    }
  };
};
