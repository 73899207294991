import { useMemo } from 'react';
import { useGetFlooringSalesAmount } from '../../../../../../../api';
import { DonutChart } from '../../../../../../../common/analytics/DonutChart';
import { useDashboard } from '../../../../../hooks/useDashboard';
import { useDashboardMonthlyContext } from '../../hooks/useDashboardMonthlyContext';

const FlooringSales = () => {
  const { selectedStore, storeDetails } = useDashboard();
  const { period } = useDashboardMonthlyContext();

  const { data, isLoading } = useGetFlooringSalesAmount(
    selectedStore,
    {
      'period.since': period.since,
      'period.to': period.to
    },
    {
      query: {
        enabled: !!selectedStore && !!period.since && !!period.to
      }
    }
  );

  const flooringSalesList = useMemo(() => data ?? [], [data]);

  return (
    <DonutChart
      data={flooringSalesList}
      title={`VENDAS NO ${storeDetails.flooring}`}
      monoColor={{ enable: true, graphColor: 'green' }}
      isLoading={isLoading}
      helpText="Representatividade (%) das vendas totais da marca em comparativo com as vendas totais das marcas situadas no mesmo pavimento (piso) no período de datas selecionado."
    />
  );
};

export { FlooringSales };
