import { Stack, Box } from '@mui/material';
import { useMemo, useState } from 'react';
import {
  useListMallSegments,
  useListMallSegmentSales
} from '../../../../../../api';
import {
  CheckboxList,
  ListCheckboxListOnSelectProps
} from '../../../../../../common/analytics/CheckboxList';
import { LineChart } from '../../../../../../common/analytics/LineChart';
import { useDashboard } from '../../../../hooks/useDashboard';
import { useDashboardShoppingContext } from '../hooks/useDashboardShoppingContext';

const SalesSegment = () => {
  const { selectedStore } = useDashboard();
  const { period } = useDashboardShoppingContext();
  const [segment, setSegments] = useState<string[]>([]);
  const [quantityDataUpdates, setQuantityDataUpdates] = useState(1);
  const { data: branchMallData, isLoading: isLoadingSegmentMall } =
    useListMallSegments(selectedStore, {
      query: {
        enabled: !!selectedStore
      }
    });
  const { data: mallBranchSalesData, isLoading: isLoadingMallBranchSales } =
    useListMallSegmentSales(
      selectedStore,
      {
        'period.since': period.since,
        'period.to': period.to,
        segments: segment.join(',')
      },
      {
        query: {
          enabled:
            !!selectedStore && !!period.since && !!period.to && !!segment.length
        }
      }
    );

  const branchMallList = useMemo(() => branchMallData, [branchMallData]);
  const salesSegmentAnnualList = useMemo(() => {
    setQuantityDataUpdates((prev) => prev + 1);
    return segment.length ? mallBranchSalesData : [];
  }, [segment, mallBranchSalesData]);

  const handleOnSelectBranch = ({
    checkboxes
  }: ListCheckboxListOnSelectProps) => {
    setSegments(
      checkboxes
        .filter((checkboxItem) => checkboxItem.checked)
        .map((checkboxItem) => checkboxItem.name)
    );
  };

  return (
    <Stack direction="row" gap={1} alignContent="space-between">
      <LineChart
        key={quantityDataUpdates}
        title="VENDAS POR SEGMENTO NO PERÍODO SELECIONADO"
        isLoading={isLoadingMallBranchSales}
        dataSerie={salesSegmentAnnualList}
        helpText="Somatório das vendas totais por segmento do ramo da marca por mês segmentada por ano."
      />
      <Box width={687}>
        <CheckboxList.Card
          title="SEGMENTOS DENTRO DO RAMO"
          isLoading={isLoadingSegmentMall}
        >
          <CheckboxList.List
            onSelect={handleOnSelectBranch}
            data={branchMallList}
          />
        </CheckboxList.Card>
      </Box>
    </Stack>
  );
};

export { SalesSegment };
