import {
  HeaderGroup,
  Row,
  TableBodyPropGetter,
  TableBodyProps,
  TablePropGetter,
  TableProps
} from 'react-table';
import { Table as CommonTable } from '../../../components/Common/DataTable/Table';
import { ChartContainer } from '../shared/ChartBase';

type AnalyticsTableProps<T extends object> = {
  title: string;
  isLoading: boolean;
  getTableProps: (propGetter?: TablePropGetter<T>) => TableProps;
  getTableBodyProps: (propGetter?: TableBodyPropGetter<T>) => TableBodyProps;
  headerGroups: HeaderGroup<T>[];
  footerGroups: HeaderGroup<T>[];
  rows: Row<T>[];
  prepareRow: (row: Row<T>) => void;
  helpText?: string;
};

const Table = <T extends object>({
  title,
  isLoading,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  footerGroups,
  rows,
  prepareRow,
  helpText
}: AnalyticsTableProps<T>) => {
  return (
    <ChartContainer title={title} helpText={helpText}>
      <CommonTable.Root getTableProps={getTableProps} showPagination={false}>
        <CommonTable.Header headerGroups={headerGroups} />
        <CommonTable.Body
          getTableBodyProps={getTableBodyProps}
          isLoading={isLoading}
          colSpan={headerGroups[0].headers.length}
          rows={rows}
          prepareRow={prepareRow}
        />
        <CommonTable.Footer footerGroups={footerGroups} />
      </CommonTable.Root>
    </ChartContainer>
  );
};

export { Table };
