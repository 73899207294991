export interface UserDTO {
  id: string;
  email: string | null;
  displayName: string | null;
  photoURL: string | null;
  roles?: string[] | undefined;
  isLoading?: boolean;
}

export class User {
  id: string;

  email: string;

  displayName: string;

  roles?: string[] | undefined;

  isLoading?: boolean;

  photoURL: string;

  constructor(data: UserDTO) {
    this.id = data.id ?? '';
    this.email = data.email ?? '';
    this.displayName = data.displayName ?? '';
    this.photoURL = data.photoURL ?? '';
    this.roles = data.roles ?? undefined;
    this.isLoading = data.isLoading ?? true;
  }
}
