import { Stack } from '@mui/material';
import { useMemo } from 'react';
import {
  useGetBrandsByBranch,
  useGetBrandsBySegment
} from '../../../../../api';
import { ChartContainer } from '../../../../../common/analytics/shared/ChartBase';
import { List } from '../../../../../components/Common/List';
import { useDashboard } from '../../../hooks/useDashboard';

const BrandsSegmentBranch = () => {
  const { storeDetails } = useDashboard();
  const { data: dataSegment, isLoading: isLoadingSegment } =
    useGetBrandsBySegment(
      encodeURIComponent(storeDetails.shopping),
      encodeURIComponent(storeDetails.segment),
      {
        query: {
          enabled: !!storeDetails.shopping && !!storeDetails.segment
        }
      }
    );
  const { data: dataBrandsBranch, isLoading: isLoadingBranch } =
    useGetBrandsByBranch(
      encodeURIComponent(storeDetails.shopping),
      encodeURIComponent(storeDetails.branch),
      {
        query: {
          enabled: !!storeDetails.shopping && !!storeDetails.branch
        }
      }
    );

  const brandsSegmentList = useMemo(() => dataSegment ?? [], [dataSegment]);

  const brandsBranchList = useMemo(
    () => dataBrandsBranch ?? [],
    [dataBrandsBranch]
  );

  return brandsSegmentList.length >= 5 || brandsBranchList.length >= 5 ? (
    <ChartContainer
      title="MARCAS NO SEGMENTO E RAMO"
      height="370px"
      helpText="Listagem de marcas categorizadas no mesmo segmento e ramo"
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        width="100%"
        paddingTop={2.8888888889}
        paddingX={1.7777777778}
        paddingBottom={1.7777777778}
      >
        {brandsSegmentList.length >= 5 && (
          <List
            title={storeDetails.segment}
            legend="Marcas no Segmento"
            isLoading={isLoadingSegment}
            data={brandsSegmentList}
          />
        )}
        {brandsBranchList.length >= 5 && (
          <List
            title={storeDetails.branch}
            legend="Marcas no Ramo"
            isLoading={isLoadingBranch}
            data={brandsBranchList}
          />
        )}
      </Stack>
    </ChartContainer>
  ) : (
    // eslint-disable-next-line
    <></>
  );
};

export { BrandsSegmentBranch };
