import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { BenefitsStore, GetUserbyIdResponseDTO } from '../../model';
import { HTTPService } from '../../../services/shared/axios';

type Store = {
  id: number;
  brand: string;
  luc?: string;
  shopping?: string;
};

type GetUserDTO = {
  id: string;
  name: string;
  email: string;
  role: number;
  stores: Store[];
  benefitsStores: BenefitsStore[];
};

const mapper = (res: AxiosResponse<GetUserbyIdResponseDTO>) => {
  const { data } = res;

  const mappedStores = data.userAccessData?.map(
    (accessData): Store => ({
      id: accessData.id,
      brand: accessData.brand,
      luc: accessData.luc,
      shopping: accessData.shopping ?? ''
    })
  );

  const invalidRoleId = -1;

  const result: GetUserDTO = {
    id: data.id,
    email: data.email,
    name: data.name,
    role: data.usersRoles ? data.usersRoles[0].roleId : invalidRoleId,
    stores: mappedStores ?? [],
    benefitsStores: data.benefitsAccessData ?? []
  };

  return result as unknown as Promise<GetUserDTO>;
};

// eslint-disable-next-line
export const getUserByIdMutator = <T>(
  config: AxiosRequestConfig
): Promise<GetUserDTO> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getUserByIdMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
