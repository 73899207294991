import { format, subDays } from 'date-fns';
import { useReducer } from 'react';
import { DailyPeriodType } from '../context/storeViewDailyContext';

interface DashboardDailyProps {
  period: DailyPeriodType;
}

enum ActionType {
  SET_DAILY_RANGE = 'setPeriod'
}

interface Payload {
  period: DailyPeriodType;
}

interface Action {
  type: ActionType;
  payload: Payload;
}

const currentDate = new Date();
const initialState: DashboardDailyProps = {
  period: {
    since: format(subDays(currentDate, 7), 'yyyy-MM-dd'),
    to: format(currentDate, 'yyyy-MM-dd')
  }
};

const reducerActionsMap = {
  [ActionType.SET_DAILY_RANGE]: (
    state: DashboardDailyProps,
    payload: Payload
  ): DashboardDailyProps => {
    return {
      ...state,
      period: payload.period
    };
  }
};

function reducer(state: DashboardDailyProps, action: Action) {
  return reducerActionsMap[action.type]
    ? reducerActionsMap[action.type](state, action.payload)
    : state;
}

export const useDashboardDaily = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { period } = state;

  const setPeriod = (value: DailyPeriodType) => {
    dispatch({
      type: ActionType.SET_DAILY_RANGE,
      payload: { ...state, period: value }
    });
  };

  return {
    period,
    setPeriod
  };
};
