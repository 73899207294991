import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { GetSegmentSalesComparativeDailyResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';
import {
  ColumnChartData,
  ColumnChartDataValue
} from '../../../common/analytics/ColumnChart';

export type SegmentSalesComparativeDaily = {
  selectedPeriodSales: ColumnChartData[];
};

const mapper = (
  res: AxiosResponse<GetSegmentSalesComparativeDailyResponseDto[]>
) => {
  const { data } = res;
  const reservationAmount: ColumnChartDataValue[] = [];
  const segmentAmount: ColumnChartDataValue[] = [];
  const marketShareAmount: ColumnChartDataValue[] = [];

  data.forEach((item) => {
    const day = Number(item.day);
    const month = Number(item.month);
    const year = Number(item.year);

    reservationAmount.push({
      value: item.sales,
      date: {
        day,
        month,
        year
      }
    });

    segmentAmount.push({
      value: item.salesSegment,
      date: {
        day,
        month,
        year
      }
    });

    marketShareAmount.push({
      value: item.marketShare,
      date: {
        day,
        month,
        year
      }
    });
  });

  const selectedPeriodSales = [
    {
      title: 'Vendas Segmento',
      data: segmentAmount
    },
    {
      title: 'Vendas Marca',
      data: reservationAmount
    },
    {
      title: 'Market Share',
      data: marketShareAmount
    }
  ];

  return {
    selectedPeriodSales
  } as unknown as Promise<SegmentSalesComparativeDaily>;
};

// eslint-disable-next-line
export const getSegmentSalesComparativeDailyMutator = <T>(
  config: AxiosRequestConfig
): Promise<SegmentSalesComparativeDaily> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getSegmentSalesComparativeDailyMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
