import { useReducer } from 'react';
import { zeroPad } from '../../../../../../helpers/typography';
import { ShoppingPeriodType } from '../context/storeViewShoppingContext';

interface DashboardShoppingProps {
  period: ShoppingPeriodType;
}

enum ActionType {
  SET_SHOPPING_RANGE = 'setPeriod'
}

interface Payload {
  period: ShoppingPeriodType;
}

interface Action {
  type: ActionType;
  payload: Payload;
}

const currentDate = new Date();
const initialState: DashboardShoppingProps = {
  period: {
    since: `${currentDate.getFullYear()}-01`,
    to: `${currentDate.getFullYear()}-${zeroPad(currentDate.getMonth() + 1)}`
  }
};

const reducerActionsMap = {
  [ActionType.SET_SHOPPING_RANGE]: (
    state: DashboardShoppingProps,
    payload: Payload
  ): DashboardShoppingProps => {
    return {
      ...state,
      period: payload.period
    };
  }
};

function reducer(state: DashboardShoppingProps, action: Action) {
  return reducerActionsMap[action.type]
    ? reducerActionsMap[action.type](state, action.payload)
    : state;
}

export const useDashboardShopping = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { period } = state;

  const setPeriod = (value: ShoppingPeriodType) => {
    dispatch({
      type: ActionType.SET_SHOPPING_RANGE,
      payload: { ...state, period: value }
    });
  };

  return {
    period,
    setPeriod
  };
};
