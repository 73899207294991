import { useEffect, useMemo, useState } from 'react';
import { useGetSegmentSalesComparativeDaily } from '../../../../../../../api';
import { ColumnChartWithLine } from '../../../../../../../common/analytics/ColumnChartWithLine';
import { useDashboard } from '../../../../../hooks/useDashboard';
import { useDashboardDailyContext } from '../../hooks/useDashboardDailyContext';

const SegmentSales = () => {
  const [keyCount, setKeyCount] = useState(0);
  const { selectedStore } = useDashboard();
  const { period } = useDashboardDailyContext();
  const { data, isLoading } = useGetSegmentSalesComparativeDaily(
    selectedStore,
    {
      'period.since': period.since,
      'period.to': period.to
    },
    {
      query: {
        enabled: !!selectedStore && !!period.since && !!period.to
      }
    }
  );

  const { selectedPeriodSales } = useMemo(
    () => data ?? { selectedPeriodSales: [] },
    [data]
  );

  useEffect(() => {
    setKeyCount((prev) => prev + 1);
  }, [data]);

  return (
    <ColumnChartWithLine
      key={`${keyCount}-selected-period-sales`}
      data={selectedPeriodSales}
      title="VENDAS DIÁRIAS DO SEGMENTO NO PERIODO SELECIONADO"
      isLoading={isLoading}
      stacked
      helpText="Comparativo das vendas totais diárias da marca do período selecionado versus as vendas totais do segmento e sua representatividade (%)."
    />
  );
};

export { SegmentSales };
