/**
 * Generated by orval v6.13.1 🍺
 * Do not edit manually.
 * BACKEND FOR FRONTEND
 * This api is responsible to forward the request to the correct api
 * OpenAPI spec version: 0.0.1
 */
import { useQuery, useInfiniteQuery } from 'react-query';
import type {
  UseQueryOptions,
  UseInfiniteQueryOptions,
  QueryFunction,
  UseQueryResult,
  UseInfiniteQueryResult,
  QueryKey
} from 'react-query';
import type {
  GetStoreDetailsResponseDto,
  Error,
  GetBigNumbersResponseDto,
  GetBigNumbersParams,
  GetSalesBranchResponseDto,
  GetSalesBranchParams,
  GetSegmentSalesComparativeResponseDto,
  GetSegmentSalesComparativeParams,
  GetSegmentSalesResponseDto,
  GetSegmentSalesParams,
  GetSalesPerMeterSquareMonthlyResponseDto,
  GetSalesPerMeterSquareMonthlyParams,
  GetSalesAmountResponseDto,
  GetSalesAmountParams,
  GetFlooringSalesAmountResponseDto,
  GetFlooringSalesAmountParams,
  GetBranchSalesResponseDto,
  GetBranchSalesParams,
  GetSalesAnnualResponseDto,
  GetSalesAnnualParams,
  GetStoreMarketShareResponseDto,
  GetStoreMarketShareParams,
  GetStoreRankResponseDto,
  GetStoreRankParams,
  GetSalesAndMarketShareResponseDto,
  GetSalesAndMarketShareParams,
  GetSalesComparativeResponseDto,
  GetSalesComparativeParams,
  GetMallsResponseDto,
  GetMallStoresResponseDto,
  GetBrandsBySegmentResponseDto,
  GetBrandsByBranchResponseDto,
  GetDailySalesResponseDto,
  GetDailySalesParams,
  GetTotalSalesAndMarketShareResponseDto,
  GetTotalSalesAndMarketShareParams,
  GetSegmentSalesComparativeDailyResponseDto,
  GetSegmentSalesComparativeDailyParams,
  GetSalesBySegmentResponseDto,
  GetSalesBySegmentParams,
  GetBranchSalesDailyResponseDto,
  GetBranchSalesDailyParams,
  GetSQMSalesDailyResponseDto,
  GetSQMSalesDailyParams,
  GetSalesFlooringDailyResponseDto,
  GetSalesFlooringDailyParams,
  GetBigNumbersMallResponseDto,
  ListMallBigNumbersParams,
  GetSalesHighlightsResponseDto,
  ListMallHighLightsSalesParams,
  GetSegmentSalesAnnualMallResponseDto,
  ListMallSegmentAnnualSalesParams,
  GetMallBranchsResponseDto,
  GetSalesBranchsResponseDto,
  ListMallBranchSalesParams,
  GetSegmentsResponseDto,
  GetSalesBySegmentsResponseDto,
  ListMallSegmentSalesParams
} from '../../model';
import getStoreDetailsMutator from '../../mutator/analytics/get-store-details';
import type { ErrorType as GetStoreDetailsErrorType } from '../../mutator/analytics/get-store-details';
import getBigNumbersMutator from '../../mutator/analytics/get-big-numbers';
import type { ErrorType as GetBigNumbersErrorType } from '../../mutator/analytics/get-big-numbers';
import getSalesBranchMutator from '../../mutator/analytics/get-sales-branch';
import type { ErrorType as GetSalesBranchErrorType } from '../../mutator/analytics/get-sales-branch';
import getSegmentSalesComparativeMutator from '../../mutator/custom-client';
import type { ErrorType as GetSegmentSalesComparativeErrorType } from '../../mutator/custom-client';
import getSegmentSalesMutator from '../../mutator/custom-client';
import type { ErrorType as GetSegmentSalesErrorType } from '../../mutator/custom-client';
import getSalesPerMeterSquareMonthlyMutator from '../../mutator/analytics/get-sales-per-meter-square-monthly';
import type { ErrorType as GetSalesPerMeterSquareMonthlyErrorType } from '../../mutator/analytics/get-sales-per-meter-square-monthly';
import getSalesAmountMutator from '../../mutator/analytics/get-sales-amount';
import type { ErrorType as GetSalesAmountErrorType } from '../../mutator/analytics/get-sales-amount';
import getFlooringSalesAmountMutator from '../../mutator/analytics/get-flooring-sales-amount';
import type { ErrorType as GetFlooringSalesAmountErrorType } from '../../mutator/analytics/get-flooring-sales-amount';
import getBranchSalesMutator from '../../mutator/analytics/get-branch-sales';
import type { ErrorType as GetBranchSalesErrorType } from '../../mutator/analytics/get-branch-sales';
import getSalesAnnualMutator from '../../mutator/analytics/get-sales-annual';
import type { ErrorType as GetSalesAnnualErrorType } from '../../mutator/analytics/get-sales-annual';
import getStoreMarketShareMutator from '../../mutator/custom-client';
import type { ErrorType as GetStoreMarketShareErrorType } from '../../mutator/custom-client';
import getStoreRankMutator from '../../mutator/custom-client';
import type { ErrorType as GetStoreRankErrorType } from '../../mutator/custom-client';
import getSalesAndMarketShareMutator from '../../mutator/analytics/get-sales-and-market-share';
import type { ErrorType as GetSalesAndMarketShareErrorType } from '../../mutator/analytics/get-sales-and-market-share';
import getSalesComparativeMutator from '../../mutator/analytics/get-sales-comparative';
import type { ErrorType as GetSalesComparativeErrorType } from '../../mutator/analytics/get-sales-comparative';
import getMallsMutator from '../../mutator/analytics/get-malls';
import type { ErrorType as GetMallsErrorType } from '../../mutator/analytics/get-malls';
import getMallStoresMutator from '../../mutator/analytics/get-mall-stores';
import type { ErrorType as GetMallStoresErrorType } from '../../mutator/analytics/get-mall-stores';
import getBrandsBySegmentMutator from '../../mutator/analytics/get-brands-by-segment';
import type { ErrorType as GetBrandsBySegmentErrorType } from '../../mutator/analytics/get-brands-by-segment';
import getBrandsByBranchMutator from '../../mutator/analytics/get-brands-by-branch';
import type { ErrorType as GetBrandsByBranchErrorType } from '../../mutator/analytics/get-brands-by-branch';
import getDailySalesMutator from '../../mutator/analytics/get-daily-sales';
import type { ErrorType as GetDailySalesErrorType } from '../../mutator/analytics/get-daily-sales';
import getTotalSalesAndMarketShareMutator from '../../mutator/analytics/get-total-sales-and-market-share';
import type { ErrorType as GetTotalSalesAndMarketShareErrorType } from '../../mutator/analytics/get-total-sales-and-market-share';
import getSegmentSalesComparativeDailyMutator from '../../mutator/analytics/get-segment-sales-comparative-daily';
import type { ErrorType as GetSegmentSalesComparativeDailyErrorType } from '../../mutator/analytics/get-segment-sales-comparative-daily';
import getSalesBySegmentMutator from '../../mutator/analytics/get-sales-by-segment';
import type { ErrorType as GetSalesBySegmentErrorType } from '../../mutator/analytics/get-sales-by-segment';
import getBranchSalesDailyMutator from '../../mutator/analytics/get-branch-sales-daily';
import type { ErrorType as GetBranchSalesDailyErrorType } from '../../mutator/analytics/get-branch-sales-daily';
import getSQMSalesDailyMutator from '../../mutator/analytics/get-sqm-sales-daily';
import type { ErrorType as GetSQMSalesDailyErrorType } from '../../mutator/analytics/get-sqm-sales-daily';
import getSalesFlooringDailyMutator from '../../mutator/analytics/get-sales-flooring-daily';
import type { ErrorType as GetSalesFlooringDailyErrorType } from '../../mutator/analytics/get-sales-flooring-daily';
import listMallBigNumbersMutator from '../../mutator/analytics/list-mall-big-numbers';
import type { ErrorType as ListMallBigNumbersErrorType } from '../../mutator/analytics/list-mall-big-numbers';
import listMallHighLightsSalesMutator from '../../mutator/custom-client';
import type { ErrorType as ListMallHighLightsSalesErrorType } from '../../mutator/custom-client';
import listMallSegmentAnnualSalesMutator from '../../mutator/analytics/list-mall-segment-sales-annual';
import type { ErrorType as ListMallSegmentAnnualSalesErrorType } from '../../mutator/analytics/list-mall-segment-sales-annual';
import listMallBranchesMutator from '../../mutator/analytics/list-mall-branches';
import type { ErrorType as ListMallBranchesErrorType } from '../../mutator/analytics/list-mall-branches';
import listMallBranchSalesMutator from '../../mutator/analytics/list-mall-branch-sales';
import type { ErrorType as ListMallBranchSalesErrorType } from '../../mutator/analytics/list-mall-branch-sales';
import listMallSegmentsMutator from '../../mutator/analytics/list-mall-segments';
import type { ErrorType as ListMallSegmentsErrorType } from '../../mutator/analytics/list-mall-segments';
import listMallSegmentSalesMutator from '../../mutator/analytics/list-mall-segment-sales';
import type { ErrorType as ListMallSegmentSalesErrorType } from '../../mutator/analytics/list-mall-segment-sales';

export const getStoreDetails = (
  accessDataKey: string,
  signal?: AbortSignal
) => {
  return getStoreDetailsMutator<GetStoreDetailsResponseDto>({
    url: `/analytics/store/${accessDataKey}`,
    method: 'get',
    signal
  });
};

export const getGetStoreDetailsQueryKey = (accessDataKey: string) =>
  [`/analytics/store/${accessDataKey}`] as const;

export type GetStoreDetailsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStoreDetails>>
>;
export type GetStoreDetailsInfiniteQueryError = GetStoreDetailsErrorType<Error>;

export const useGetStoreDetailsInfinite = <
  TData = Awaited<ReturnType<typeof getStoreDetails>>,
  TError = GetStoreDetailsErrorType<Error>
>(
  accessDataKey: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getStoreDetails>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetStoreDetailsQueryKey(accessDataKey);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStoreDetails>>> = ({
    signal
  }) => getStoreDetails(accessDataKey, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getStoreDetails>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetStoreDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStoreDetails>>
>;
export type GetStoreDetailsQueryError = GetStoreDetailsErrorType<Error>;

export const useGetStoreDetails = <
  TData = Awaited<ReturnType<typeof getStoreDetails>>,
  TError = GetStoreDetailsErrorType<Error>
>(
  accessDataKey: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStoreDetails>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetStoreDetailsQueryKey(accessDataKey);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStoreDetails>>> = ({
    signal
  }) => getStoreDetails(accessDataKey, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getStoreDetails>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getBigNumbers = (
  accessDataKey: string,
  params: GetBigNumbersParams,
  signal?: AbortSignal
) => {
  return getBigNumbersMutator<GetBigNumbersResponseDto>({
    url: `/analytics/store/${accessDataKey}/big-numbers`,
    method: 'get',
    params,
    signal
  });
};

export const getGetBigNumbersQueryKey = (
  accessDataKey: string,
  params: GetBigNumbersParams
) =>
  [
    `/analytics/store/${accessDataKey}/big-numbers`,
    ...(params ? [params] : [])
  ] as const;

export type GetBigNumbersInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBigNumbers>>
>;
export type GetBigNumbersInfiniteQueryError = GetBigNumbersErrorType<Error>;

export const useGetBigNumbersInfinite = <
  TData = Awaited<ReturnType<typeof getBigNumbers>>,
  TError = GetBigNumbersErrorType<Error>
>(
  accessDataKey: string,
  params: GetBigNumbersParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getBigNumbers>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBigNumbersQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBigNumbers>>> = ({
    signal
  }) => getBigNumbers(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getBigNumbers>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetBigNumbersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBigNumbers>>
>;
export type GetBigNumbersQueryError = GetBigNumbersErrorType<Error>;

export const useGetBigNumbers = <
  TData = Awaited<ReturnType<typeof getBigNumbers>>,
  TError = GetBigNumbersErrorType<Error>
>(
  accessDataKey: string,
  params: GetBigNumbersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBigNumbers>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBigNumbersQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBigNumbers>>> = ({
    signal
  }) => getBigNumbers(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getBigNumbers>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getSalesBranch = (
  accessDataKey: string,
  params: GetSalesBranchParams,
  signal?: AbortSignal
) => {
  return getSalesBranchMutator<GetSalesBranchResponseDto>({
    url: `/analytics/store/${accessDataKey}/branch/sales`,
    method: 'get',
    params,
    signal
  });
};

export const getGetSalesBranchQueryKey = (
  accessDataKey: string,
  params: GetSalesBranchParams
) =>
  [
    `/analytics/store/${accessDataKey}/branch/sales`,
    ...(params ? [params] : [])
  ] as const;

export type GetSalesBranchInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalesBranch>>
>;
export type GetSalesBranchInfiniteQueryError = GetSalesBranchErrorType<Error>;

export const useGetSalesBranchInfinite = <
  TData = Awaited<ReturnType<typeof getSalesBranch>>,
  TError = GetSalesBranchErrorType<Error>
>(
  accessDataKey: string,
  params: GetSalesBranchParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getSalesBranch>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSalesBranchQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesBranch>>> = ({
    signal
  }) => getSalesBranch(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getSalesBranch>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetSalesBranchQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalesBranch>>
>;
export type GetSalesBranchQueryError = GetSalesBranchErrorType<Error>;

export const useGetSalesBranch = <
  TData = Awaited<ReturnType<typeof getSalesBranch>>,
  TError = GetSalesBranchErrorType<Error>
>(
  accessDataKey: string,
  params: GetSalesBranchParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSalesBranch>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSalesBranchQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesBranch>>> = ({
    signal
  }) => getSalesBranch(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getSalesBranch>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getSegmentSalesComparative = (
  accessDataKey: string,
  params: GetSegmentSalesComparativeParams,
  signal?: AbortSignal
) => {
  return getSegmentSalesComparativeMutator<
    GetSegmentSalesComparativeResponseDto[]
  >({
    url: `/analytics/store/${accessDataKey}/segment-sales-comparative`,
    method: 'get',
    params,
    signal
  });
};

export const getGetSegmentSalesComparativeQueryKey = (
  accessDataKey: string,
  params: GetSegmentSalesComparativeParams
) =>
  [
    `/analytics/store/${accessDataKey}/segment-sales-comparative`,
    ...(params ? [params] : [])
  ] as const;

export type GetSegmentSalesComparativeInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSegmentSalesComparative>>
>;
export type GetSegmentSalesComparativeInfiniteQueryError =
  GetSegmentSalesComparativeErrorType<Error>;

export const useGetSegmentSalesComparativeInfinite = <
  TData = Awaited<ReturnType<typeof getSegmentSalesComparative>>,
  TError = GetSegmentSalesComparativeErrorType<Error>
>(
  accessDataKey: string,
  params: GetSegmentSalesComparativeParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getSegmentSalesComparative>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetSegmentSalesComparativeQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSegmentSalesComparative>>
  > = ({ signal }) => getSegmentSalesComparative(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getSegmentSalesComparative>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetSegmentSalesComparativeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSegmentSalesComparative>>
>;
export type GetSegmentSalesComparativeQueryError =
  GetSegmentSalesComparativeErrorType<Error>;

export const useGetSegmentSalesComparative = <
  TData = Awaited<ReturnType<typeof getSegmentSalesComparative>>,
  TError = GetSegmentSalesComparativeErrorType<Error>
>(
  accessDataKey: string,
  params: GetSegmentSalesComparativeParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSegmentSalesComparative>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetSegmentSalesComparativeQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSegmentSalesComparative>>
  > = ({ signal }) => getSegmentSalesComparative(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getSegmentSalesComparative>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getSegmentSales = (
  accessDataKey: string,
  params: GetSegmentSalesParams,
  signal?: AbortSignal
) => {
  return getSegmentSalesMutator<GetSegmentSalesResponseDto[]>({
    url: `/analytics/store/${accessDataKey}/segment/sales`,
    method: 'get',
    params,
    signal
  });
};

export const getGetSegmentSalesQueryKey = (
  accessDataKey: string,
  params: GetSegmentSalesParams
) =>
  [
    `/analytics/store/${accessDataKey}/segment/sales`,
    ...(params ? [params] : [])
  ] as const;

export type GetSegmentSalesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSegmentSales>>
>;
export type GetSegmentSalesInfiniteQueryError = GetSegmentSalesErrorType<Error>;

export const useGetSegmentSalesInfinite = <
  TData = Awaited<ReturnType<typeof getSegmentSales>>,
  TError = GetSegmentSalesErrorType<Error>
>(
  accessDataKey: string,
  params: GetSegmentSalesParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getSegmentSales>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSegmentSalesQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSegmentSales>>> = ({
    signal
  }) => getSegmentSales(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getSegmentSales>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetSegmentSalesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSegmentSales>>
>;
export type GetSegmentSalesQueryError = GetSegmentSalesErrorType<Error>;

export const useGetSegmentSales = <
  TData = Awaited<ReturnType<typeof getSegmentSales>>,
  TError = GetSegmentSalesErrorType<Error>
>(
  accessDataKey: string,
  params: GetSegmentSalesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSegmentSales>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSegmentSalesQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSegmentSales>>> = ({
    signal
  }) => getSegmentSales(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getSegmentSales>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getSalesPerMeterSquareMonthly = (
  accessDataKey: string,
  params: GetSalesPerMeterSquareMonthlyParams,
  signal?: AbortSignal
) => {
  return getSalesPerMeterSquareMonthlyMutator<
    GetSalesPerMeterSquareMonthlyResponseDto[]
  >({
    url: `/analytics/store/${accessDataKey}/sales-sqm`,
    method: 'get',
    params,
    signal
  });
};

export const getGetSalesPerMeterSquareMonthlyQueryKey = (
  accessDataKey: string,
  params: GetSalesPerMeterSquareMonthlyParams
) =>
  [
    `/analytics/store/${accessDataKey}/sales-sqm`,
    ...(params ? [params] : [])
  ] as const;

export type GetSalesPerMeterSquareMonthlyInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalesPerMeterSquareMonthly>>
>;
export type GetSalesPerMeterSquareMonthlyInfiniteQueryError =
  GetSalesPerMeterSquareMonthlyErrorType<Error>;

export const useGetSalesPerMeterSquareMonthlyInfinite = <
  TData = Awaited<ReturnType<typeof getSalesPerMeterSquareMonthly>>,
  TError = GetSalesPerMeterSquareMonthlyErrorType<Error>
>(
  accessDataKey: string,
  params: GetSalesPerMeterSquareMonthlyParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getSalesPerMeterSquareMonthly>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetSalesPerMeterSquareMonthlyQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSalesPerMeterSquareMonthly>>
  > = ({ signal }) =>
    getSalesPerMeterSquareMonthly(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getSalesPerMeterSquareMonthly>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetSalesPerMeterSquareMonthlyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalesPerMeterSquareMonthly>>
>;
export type GetSalesPerMeterSquareMonthlyQueryError =
  GetSalesPerMeterSquareMonthlyErrorType<Error>;

export const useGetSalesPerMeterSquareMonthly = <
  TData = Awaited<ReturnType<typeof getSalesPerMeterSquareMonthly>>,
  TError = GetSalesPerMeterSquareMonthlyErrorType<Error>
>(
  accessDataKey: string,
  params: GetSalesPerMeterSquareMonthlyParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSalesPerMeterSquareMonthly>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetSalesPerMeterSquareMonthlyQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSalesPerMeterSquareMonthly>>
  > = ({ signal }) =>
    getSalesPerMeterSquareMonthly(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getSalesPerMeterSquareMonthly>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getSalesAmount = (
  accessDataKey: string,
  params: GetSalesAmountParams,
  signal?: AbortSignal
) => {
  return getSalesAmountMutator<GetSalesAmountResponseDto>({
    url: `/analytics/store/${accessDataKey}/sales-amount/monthly`,
    method: 'get',
    params,
    signal
  });
};

export const getGetSalesAmountQueryKey = (
  accessDataKey: string,
  params: GetSalesAmountParams
) =>
  [
    `/analytics/store/${accessDataKey}/sales-amount/monthly`,
    ...(params ? [params] : [])
  ] as const;

export type GetSalesAmountInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalesAmount>>
>;
export type GetSalesAmountInfiniteQueryError = GetSalesAmountErrorType<Error>;

export const useGetSalesAmountInfinite = <
  TData = Awaited<ReturnType<typeof getSalesAmount>>,
  TError = GetSalesAmountErrorType<Error>
>(
  accessDataKey: string,
  params: GetSalesAmountParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getSalesAmount>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSalesAmountQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesAmount>>> = ({
    signal
  }) => getSalesAmount(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getSalesAmount>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetSalesAmountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalesAmount>>
>;
export type GetSalesAmountQueryError = GetSalesAmountErrorType<Error>;

export const useGetSalesAmount = <
  TData = Awaited<ReturnType<typeof getSalesAmount>>,
  TError = GetSalesAmountErrorType<Error>
>(
  accessDataKey: string,
  params: GetSalesAmountParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSalesAmount>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSalesAmountQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesAmount>>> = ({
    signal
  }) => getSalesAmount(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getSalesAmount>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getFlooringSalesAmount = (
  accessDataKey: string,
  params: GetFlooringSalesAmountParams,
  signal?: AbortSignal
) => {
  return getFlooringSalesAmountMutator<GetFlooringSalesAmountResponseDto>({
    url: `/analytics/store/${accessDataKey}/flooring/sales`,
    method: 'get',
    params,
    signal
  });
};

export const getGetFlooringSalesAmountQueryKey = (
  accessDataKey: string,
  params: GetFlooringSalesAmountParams
) =>
  [
    `/analytics/store/${accessDataKey}/flooring/sales`,
    ...(params ? [params] : [])
  ] as const;

export type GetFlooringSalesAmountInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFlooringSalesAmount>>
>;
export type GetFlooringSalesAmountInfiniteQueryError =
  GetFlooringSalesAmountErrorType<Error>;

export const useGetFlooringSalesAmountInfinite = <
  TData = Awaited<ReturnType<typeof getFlooringSalesAmount>>,
  TError = GetFlooringSalesAmountErrorType<Error>
>(
  accessDataKey: string,
  params: GetFlooringSalesAmountParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getFlooringSalesAmount>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetFlooringSalesAmountQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFlooringSalesAmount>>
  > = ({ signal }) => getFlooringSalesAmount(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getFlooringSalesAmount>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetFlooringSalesAmountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFlooringSalesAmount>>
>;
export type GetFlooringSalesAmountQueryError =
  GetFlooringSalesAmountErrorType<Error>;

export const useGetFlooringSalesAmount = <
  TData = Awaited<ReturnType<typeof getFlooringSalesAmount>>,
  TError = GetFlooringSalesAmountErrorType<Error>
>(
  accessDataKey: string,
  params: GetFlooringSalesAmountParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFlooringSalesAmount>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetFlooringSalesAmountQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFlooringSalesAmount>>
  > = ({ signal }) => getFlooringSalesAmount(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getFlooringSalesAmount>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getBranchSales = (
  accessDataKey: string,
  params: GetBranchSalesParams,
  signal?: AbortSignal
) => {
  return getBranchSalesMutator<GetBranchSalesResponseDto>({
    url: `/analytics/store/${accessDataKey}/branch/segment/sales`,
    method: 'get',
    params,
    signal
  });
};

export const getGetBranchSalesQueryKey = (
  accessDataKey: string,
  params: GetBranchSalesParams
) =>
  [
    `/analytics/store/${accessDataKey}/branch/segment/sales`,
    ...(params ? [params] : [])
  ] as const;

export type GetBranchSalesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBranchSales>>
>;
export type GetBranchSalesInfiniteQueryError = GetBranchSalesErrorType<Error>;

export const useGetBranchSalesInfinite = <
  TData = Awaited<ReturnType<typeof getBranchSales>>,
  TError = GetBranchSalesErrorType<Error>
>(
  accessDataKey: string,
  params: GetBranchSalesParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getBranchSales>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBranchSalesQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBranchSales>>> = ({
    signal
  }) => getBranchSales(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getBranchSales>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetBranchSalesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBranchSales>>
>;
export type GetBranchSalesQueryError = GetBranchSalesErrorType<Error>;

export const useGetBranchSales = <
  TData = Awaited<ReturnType<typeof getBranchSales>>,
  TError = GetBranchSalesErrorType<Error>
>(
  accessDataKey: string,
  params: GetBranchSalesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBranchSales>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBranchSalesQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBranchSales>>> = ({
    signal
  }) => getBranchSales(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getBranchSales>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getSalesAnnual = (
  accessDataKey: string,
  params: GetSalesAnnualParams,
  signal?: AbortSignal
) => {
  return getSalesAnnualMutator<GetSalesAnnualResponseDto[]>({
    url: `/analytics/store/${accessDataKey}/sales-and-market-share/annual`,
    method: 'get',
    params,
    signal
  });
};

export const getGetSalesAnnualQueryKey = (
  accessDataKey: string,
  params: GetSalesAnnualParams
) =>
  [
    `/analytics/store/${accessDataKey}/sales-and-market-share/annual`,
    ...(params ? [params] : [])
  ] as const;

export type GetSalesAnnualInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalesAnnual>>
>;
export type GetSalesAnnualInfiniteQueryError = GetSalesAnnualErrorType<Error>;

export const useGetSalesAnnualInfinite = <
  TData = Awaited<ReturnType<typeof getSalesAnnual>>,
  TError = GetSalesAnnualErrorType<Error>
>(
  accessDataKey: string,
  params: GetSalesAnnualParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getSalesAnnual>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSalesAnnualQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesAnnual>>> = ({
    signal
  }) => getSalesAnnual(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getSalesAnnual>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetSalesAnnualQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalesAnnual>>
>;
export type GetSalesAnnualQueryError = GetSalesAnnualErrorType<Error>;

export const useGetSalesAnnual = <
  TData = Awaited<ReturnType<typeof getSalesAnnual>>,
  TError = GetSalesAnnualErrorType<Error>
>(
  accessDataKey: string,
  params: GetSalesAnnualParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSalesAnnual>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSalesAnnualQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSalesAnnual>>> = ({
    signal
  }) => getSalesAnnual(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getSalesAnnual>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getStoreMarketShare = (
  accessDataKey: string,
  params: GetStoreMarketShareParams,
  signal?: AbortSignal
) => {
  return getStoreMarketShareMutator<GetStoreMarketShareResponseDto>({
    url: `/analytics/store/${accessDataKey}/market-share`,
    method: 'get',
    params,
    signal
  });
};

export const getGetStoreMarketShareQueryKey = (
  accessDataKey: string,
  params: GetStoreMarketShareParams
) =>
  [
    `/analytics/store/${accessDataKey}/market-share`,
    ...(params ? [params] : [])
  ] as const;

export type GetStoreMarketShareInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStoreMarketShare>>
>;
export type GetStoreMarketShareInfiniteQueryError =
  GetStoreMarketShareErrorType<Error>;

export const useGetStoreMarketShareInfinite = <
  TData = Awaited<ReturnType<typeof getStoreMarketShare>>,
  TError = GetStoreMarketShareErrorType<Error>
>(
  accessDataKey: string,
  params: GetStoreMarketShareParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getStoreMarketShare>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetStoreMarketShareQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStoreMarketShare>>
  > = ({ signal }) => getStoreMarketShare(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getStoreMarketShare>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetStoreMarketShareQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStoreMarketShare>>
>;
export type GetStoreMarketShareQueryError = GetStoreMarketShareErrorType<Error>;

export const useGetStoreMarketShare = <
  TData = Awaited<ReturnType<typeof getStoreMarketShare>>,
  TError = GetStoreMarketShareErrorType<Error>
>(
  accessDataKey: string,
  params: GetStoreMarketShareParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStoreMarketShare>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetStoreMarketShareQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStoreMarketShare>>
  > = ({ signal }) => getStoreMarketShare(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getStoreMarketShare>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getStoreRank = (
  accessDataKey: string,
  params: GetStoreRankParams,
  signal?: AbortSignal
) => {
  return getStoreRankMutator<GetStoreRankResponseDto>({
    url: `/analytics/store/${accessDataKey}/rank`,
    method: 'get',
    params,
    signal
  });
};

export const getGetStoreRankQueryKey = (
  accessDataKey: string,
  params: GetStoreRankParams
) =>
  [
    `/analytics/store/${accessDataKey}/rank`,
    ...(params ? [params] : [])
  ] as const;

export type GetStoreRankInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStoreRank>>
>;
export type GetStoreRankInfiniteQueryError = GetStoreRankErrorType<Error>;

export const useGetStoreRankInfinite = <
  TData = Awaited<ReturnType<typeof getStoreRank>>,
  TError = GetStoreRankErrorType<Error>
>(
  accessDataKey: string,
  params: GetStoreRankParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getStoreRank>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetStoreRankQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStoreRank>>> = ({
    signal
  }) => getStoreRank(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getStoreRank>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetStoreRankQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStoreRank>>
>;
export type GetStoreRankQueryError = GetStoreRankErrorType<Error>;

export const useGetStoreRank = <
  TData = Awaited<ReturnType<typeof getStoreRank>>,
  TError = GetStoreRankErrorType<Error>
>(
  accessDataKey: string,
  params: GetStoreRankParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStoreRank>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetStoreRankQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStoreRank>>> = ({
    signal
  }) => getStoreRank(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getStoreRank>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getSalesAndMarketShare = (
  accessDataKey: string,
  params: GetSalesAndMarketShareParams,
  signal?: AbortSignal
) => {
  return getSalesAndMarketShareMutator<GetSalesAndMarketShareResponseDto>({
    url: `/analytics/store/${accessDataKey}/sales-and-market-share`,
    method: 'get',
    params,
    signal
  });
};

export const getGetSalesAndMarketShareQueryKey = (
  accessDataKey: string,
  params: GetSalesAndMarketShareParams
) =>
  [
    `/analytics/store/${accessDataKey}/sales-and-market-share`,
    ...(params ? [params] : [])
  ] as const;

export type GetSalesAndMarketShareInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalesAndMarketShare>>
>;
export type GetSalesAndMarketShareInfiniteQueryError =
  GetSalesAndMarketShareErrorType<Error>;

export const useGetSalesAndMarketShareInfinite = <
  TData = Awaited<ReturnType<typeof getSalesAndMarketShare>>,
  TError = GetSalesAndMarketShareErrorType<Error>
>(
  accessDataKey: string,
  params: GetSalesAndMarketShareParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getSalesAndMarketShare>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetSalesAndMarketShareQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSalesAndMarketShare>>
  > = ({ signal }) => getSalesAndMarketShare(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getSalesAndMarketShare>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetSalesAndMarketShareQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalesAndMarketShare>>
>;
export type GetSalesAndMarketShareQueryError =
  GetSalesAndMarketShareErrorType<Error>;

export const useGetSalesAndMarketShare = <
  TData = Awaited<ReturnType<typeof getSalesAndMarketShare>>,
  TError = GetSalesAndMarketShareErrorType<Error>
>(
  accessDataKey: string,
  params: GetSalesAndMarketShareParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSalesAndMarketShare>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetSalesAndMarketShareQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSalesAndMarketShare>>
  > = ({ signal }) => getSalesAndMarketShare(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getSalesAndMarketShare>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getSalesComparative = (
  accessDataKey: string,
  params: GetSalesComparativeParams,
  signal?: AbortSignal
) => {
  return getSalesComparativeMutator<GetSalesComparativeResponseDto>({
    url: `/analytics/store/${accessDataKey}/sales-comparative`,
    method: 'get',
    params,
    signal
  });
};

export const getGetSalesComparativeQueryKey = (
  accessDataKey: string,
  params: GetSalesComparativeParams
) =>
  [
    `/analytics/store/${accessDataKey}/sales-comparative`,
    ...(params ? [params] : [])
  ] as const;

export type GetSalesComparativeInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalesComparative>>
>;
export type GetSalesComparativeInfiniteQueryError =
  GetSalesComparativeErrorType<Error>;

export const useGetSalesComparativeInfinite = <
  TData = Awaited<ReturnType<typeof getSalesComparative>>,
  TError = GetSalesComparativeErrorType<Error>
>(
  accessDataKey: string,
  params: GetSalesComparativeParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getSalesComparative>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetSalesComparativeQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSalesComparative>>
  > = ({ signal }) => getSalesComparative(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getSalesComparative>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetSalesComparativeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalesComparative>>
>;
export type GetSalesComparativeQueryError = GetSalesComparativeErrorType<Error>;

export const useGetSalesComparative = <
  TData = Awaited<ReturnType<typeof getSalesComparative>>,
  TError = GetSalesComparativeErrorType<Error>
>(
  accessDataKey: string,
  params: GetSalesComparativeParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSalesComparative>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetSalesComparativeQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSalesComparative>>
  > = ({ signal }) => getSalesComparative(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getSalesComparative>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getMalls = (signal?: AbortSignal) => {
  return getMallsMutator<GetMallsResponseDto[]>({
    url: `/analytics/mall`,
    method: 'get',
    signal
  });
};

export const getGetMallsQueryKey = () => [`/analytics/mall`] as const;

export type GetMallsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMalls>>
>;
export type GetMallsInfiniteQueryError = GetMallsErrorType<Error>;

export const useGetMallsInfinite = <
  TData = Awaited<ReturnType<typeof getMalls>>,
  TError = GetMallsErrorType<Error>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getMalls>>,
    TError,
    TData
  >;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMallsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMalls>>> = ({
    signal
  }) => getMalls(signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getMalls>>,
    TError,
    TData
  >({ queryKey, queryFn, retry: 3, ...queryOptions }) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetMallsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMalls>>
>;
export type GetMallsQueryError = GetMallsErrorType<Error>;

export const useGetMalls = <
  TData = Awaited<ReturnType<typeof getMalls>>,
  TError = GetMallsErrorType<Error>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getMalls>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMallsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMalls>>> = ({
    signal
  }) => getMalls(signal);

  const query = useQuery<Awaited<ReturnType<typeof getMalls>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getMallStores = (codShopping: number, signal?: AbortSignal) => {
  return getMallStoresMutator<GetMallStoresResponseDto[]>({
    url: `/analytics/mall/${codShopping}/stores`,
    method: 'get',
    signal
  });
};

export const getGetMallStoresQueryKey = (codShopping: number) =>
  [`/analytics/mall/${codShopping}/stores`] as const;

export type GetMallStoresInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMallStores>>
>;
export type GetMallStoresInfiniteQueryError = GetMallStoresErrorType<Error>;

export const useGetMallStoresInfinite = <
  TData = Awaited<ReturnType<typeof getMallStores>>,
  TError = GetMallStoresErrorType<Error>
>(
  codShopping: number,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getMallStores>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetMallStoresQueryKey(codShopping);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMallStores>>> = ({
    signal
  }) => getMallStores(codShopping, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getMallStores>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!codShopping,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetMallStoresQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMallStores>>
>;
export type GetMallStoresQueryError = GetMallStoresErrorType<Error>;

export const useGetMallStores = <
  TData = Awaited<ReturnType<typeof getMallStores>>,
  TError = GetMallStoresErrorType<Error>
>(
  codShopping: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMallStores>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetMallStoresQueryKey(codShopping);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMallStores>>> = ({
    signal
  }) => getMallStores(codShopping, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getMallStores>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!codShopping,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getBrandsBySegment = (
  mallName: string,
  segment: string,
  signal?: AbortSignal
) => {
  return getBrandsBySegmentMutator<GetBrandsBySegmentResponseDto[]>({
    url: `/analytics/mall/${mallName}/segment/${segment}/brand`,
    method: 'get',
    signal
  });
};

export const getGetBrandsBySegmentQueryKey = (
  mallName: string,
  segment: string
) => [`/analytics/mall/${mallName}/segment/${segment}/brand`] as const;

export type GetBrandsBySegmentInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBrandsBySegment>>
>;
export type GetBrandsBySegmentInfiniteQueryError =
  GetBrandsBySegmentErrorType<Error>;

export const useGetBrandsBySegmentInfinite = <
  TData = Awaited<ReturnType<typeof getBrandsBySegment>>,
  TError = GetBrandsBySegmentErrorType<Error>
>(
  mallName: string,
  segment: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getBrandsBySegment>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBrandsBySegmentQueryKey(mallName, segment);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBrandsBySegment>>
  > = ({ signal }) => getBrandsBySegment(mallName, segment, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getBrandsBySegment>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(mallName && segment),
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetBrandsBySegmentQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBrandsBySegment>>
>;
export type GetBrandsBySegmentQueryError = GetBrandsBySegmentErrorType<Error>;

export const useGetBrandsBySegment = <
  TData = Awaited<ReturnType<typeof getBrandsBySegment>>,
  TError = GetBrandsBySegmentErrorType<Error>
>(
  mallName: string,
  segment: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBrandsBySegment>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBrandsBySegmentQueryKey(mallName, segment);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBrandsBySegment>>
  > = ({ signal }) => getBrandsBySegment(mallName, segment, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getBrandsBySegment>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(mallName && segment),
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getBrandsByBranch = (
  mallName: string,
  branch: string,
  signal?: AbortSignal
) => {
  return getBrandsByBranchMutator<GetBrandsByBranchResponseDto[]>({
    url: `/analytics/mall/${mallName}/branch/${branch}/brand`,
    method: 'get',
    signal
  });
};

export const getGetBrandsByBranchQueryKey = (
  mallName: string,
  branch: string
) => [`/analytics/mall/${mallName}/branch/${branch}/brand`] as const;

export type GetBrandsByBranchInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBrandsByBranch>>
>;
export type GetBrandsByBranchInfiniteQueryError =
  GetBrandsByBranchErrorType<Error>;

export const useGetBrandsByBranchInfinite = <
  TData = Awaited<ReturnType<typeof getBrandsByBranch>>,
  TError = GetBrandsByBranchErrorType<Error>
>(
  mallName: string,
  branch: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getBrandsByBranch>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBrandsByBranchQueryKey(mallName, branch);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBrandsByBranch>>
  > = ({ signal }) => getBrandsByBranch(mallName, branch, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getBrandsByBranch>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(mallName && branch),
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetBrandsByBranchQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBrandsByBranch>>
>;
export type GetBrandsByBranchQueryError = GetBrandsByBranchErrorType<Error>;

export const useGetBrandsByBranch = <
  TData = Awaited<ReturnType<typeof getBrandsByBranch>>,
  TError = GetBrandsByBranchErrorType<Error>
>(
  mallName: string,
  branch: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBrandsByBranch>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBrandsByBranchQueryKey(mallName, branch);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBrandsByBranch>>
  > = ({ signal }) => getBrandsByBranch(mallName, branch, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getBrandsByBranch>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!(mallName && branch),
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getDailySales = (
  accessDataKey: string,
  params: GetDailySalesParams,
  signal?: AbortSignal
) => {
  return getDailySalesMutator<GetDailySalesResponseDto[]>({
    url: `/analytics/store/${accessDataKey}/sales/daily`,
    method: 'get',
    params,
    signal
  });
};

export const getGetDailySalesQueryKey = (
  accessDataKey: string,
  params: GetDailySalesParams
) =>
  [
    `/analytics/store/${accessDataKey}/sales/daily`,
    ...(params ? [params] : [])
  ] as const;

export type GetDailySalesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDailySales>>
>;
export type GetDailySalesInfiniteQueryError = GetDailySalesErrorType<Error>;

export const useGetDailySalesInfinite = <
  TData = Awaited<ReturnType<typeof getDailySales>>,
  TError = GetDailySalesErrorType<Error>
>(
  accessDataKey: string,
  params: GetDailySalesParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getDailySales>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDailySalesQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDailySales>>> = ({
    signal
  }) => getDailySales(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getDailySales>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetDailySalesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDailySales>>
>;
export type GetDailySalesQueryError = GetDailySalesErrorType<Error>;

export const useGetDailySales = <
  TData = Awaited<ReturnType<typeof getDailySales>>,
  TError = GetDailySalesErrorType<Error>
>(
  accessDataKey: string,
  params: GetDailySalesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDailySales>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDailySalesQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDailySales>>> = ({
    signal
  }) => getDailySales(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getDailySales>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getTotalSalesAndMarketShare = (
  accessDataKey: string,
  params: GetTotalSalesAndMarketShareParams,
  signal?: AbortSignal
) => {
  return getTotalSalesAndMarketShareMutator<GetTotalSalesAndMarketShareResponseDto>(
    {
      url: `/analytics/store/${accessDataKey}/big-numbers/daily`,
      method: 'get',
      params,
      signal
    }
  );
};

export const getGetTotalSalesAndMarketShareQueryKey = (
  accessDataKey: string,
  params: GetTotalSalesAndMarketShareParams
) =>
  [
    `/analytics/store/${accessDataKey}/big-numbers/daily`,
    ...(params ? [params] : [])
  ] as const;

export type GetTotalSalesAndMarketShareInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTotalSalesAndMarketShare>>
>;
export type GetTotalSalesAndMarketShareInfiniteQueryError =
  GetTotalSalesAndMarketShareErrorType<Error>;

export const useGetTotalSalesAndMarketShareInfinite = <
  TData = Awaited<ReturnType<typeof getTotalSalesAndMarketShare>>,
  TError = GetTotalSalesAndMarketShareErrorType<Error>
>(
  accessDataKey: string,
  params: GetTotalSalesAndMarketShareParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getTotalSalesAndMarketShare>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetTotalSalesAndMarketShareQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTotalSalesAndMarketShare>>
  > = ({ signal }) =>
    getTotalSalesAndMarketShare(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getTotalSalesAndMarketShare>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetTotalSalesAndMarketShareQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTotalSalesAndMarketShare>>
>;
export type GetTotalSalesAndMarketShareQueryError =
  GetTotalSalesAndMarketShareErrorType<Error>;

export const useGetTotalSalesAndMarketShare = <
  TData = Awaited<ReturnType<typeof getTotalSalesAndMarketShare>>,
  TError = GetTotalSalesAndMarketShareErrorType<Error>
>(
  accessDataKey: string,
  params: GetTotalSalesAndMarketShareParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTotalSalesAndMarketShare>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetTotalSalesAndMarketShareQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTotalSalesAndMarketShare>>
  > = ({ signal }) =>
    getTotalSalesAndMarketShare(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getTotalSalesAndMarketShare>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getSegmentSalesComparativeDaily = (
  accessDataKey: string,
  params: GetSegmentSalesComparativeDailyParams,
  signal?: AbortSignal
) => {
  return getSegmentSalesComparativeDailyMutator<GetSegmentSalesComparativeDailyResponseDto>(
    {
      url: `/analytics/store/${accessDataKey}/segment/sales-comparative/daily`,
      method: 'get',
      params,
      signal
    }
  );
};

export const getGetSegmentSalesComparativeDailyQueryKey = (
  accessDataKey: string,
  params: GetSegmentSalesComparativeDailyParams
) =>
  [
    `/analytics/store/${accessDataKey}/segment/sales-comparative/daily`,
    ...(params ? [params] : [])
  ] as const;

export type GetSegmentSalesComparativeDailyInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSegmentSalesComparativeDaily>>
>;
export type GetSegmentSalesComparativeDailyInfiniteQueryError =
  GetSegmentSalesComparativeDailyErrorType<Error>;

export const useGetSegmentSalesComparativeDailyInfinite = <
  TData = Awaited<ReturnType<typeof getSegmentSalesComparativeDaily>>,
  TError = GetSegmentSalesComparativeDailyErrorType<Error>
>(
  accessDataKey: string,
  params: GetSegmentSalesComparativeDailyParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getSegmentSalesComparativeDaily>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetSegmentSalesComparativeDailyQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSegmentSalesComparativeDaily>>
  > = ({ signal }) =>
    getSegmentSalesComparativeDaily(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getSegmentSalesComparativeDaily>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetSegmentSalesComparativeDailyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSegmentSalesComparativeDaily>>
>;
export type GetSegmentSalesComparativeDailyQueryError =
  GetSegmentSalesComparativeDailyErrorType<Error>;

export const useGetSegmentSalesComparativeDaily = <
  TData = Awaited<ReturnType<typeof getSegmentSalesComparativeDaily>>,
  TError = GetSegmentSalesComparativeDailyErrorType<Error>
>(
  accessDataKey: string,
  params: GetSegmentSalesComparativeDailyParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSegmentSalesComparativeDaily>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetSegmentSalesComparativeDailyQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSegmentSalesComparativeDaily>>
  > = ({ signal }) =>
    getSegmentSalesComparativeDaily(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getSegmentSalesComparativeDaily>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getSalesBySegment = (
  accessDataKey: string,
  params: GetSalesBySegmentParams,
  signal?: AbortSignal
) => {
  return getSalesBySegmentMutator<GetSalesBySegmentResponseDto>({
    url: `/analytics/store/${accessDataKey}/branch/segment/sales/daily`,
    method: 'get',
    params,
    signal
  });
};

export const getGetSalesBySegmentQueryKey = (
  accessDataKey: string,
  params: GetSalesBySegmentParams
) =>
  [
    `/analytics/store/${accessDataKey}/branch/segment/sales/daily`,
    ...(params ? [params] : [])
  ] as const;

export type GetSalesBySegmentInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalesBySegment>>
>;
export type GetSalesBySegmentInfiniteQueryError =
  GetSalesBySegmentErrorType<Error>;

export const useGetSalesBySegmentInfinite = <
  TData = Awaited<ReturnType<typeof getSalesBySegment>>,
  TError = GetSalesBySegmentErrorType<Error>
>(
  accessDataKey: string,
  params: GetSalesBySegmentParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getSalesBySegment>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetSalesBySegmentQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSalesBySegment>>
  > = ({ signal }) => getSalesBySegment(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getSalesBySegment>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetSalesBySegmentQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalesBySegment>>
>;
export type GetSalesBySegmentQueryError = GetSalesBySegmentErrorType<Error>;

export const useGetSalesBySegment = <
  TData = Awaited<ReturnType<typeof getSalesBySegment>>,
  TError = GetSalesBySegmentErrorType<Error>
>(
  accessDataKey: string,
  params: GetSalesBySegmentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSalesBySegment>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetSalesBySegmentQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSalesBySegment>>
  > = ({ signal }) => getSalesBySegment(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getSalesBySegment>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getBranchSalesDaily = (
  accessDataKey: string,
  params: GetBranchSalesDailyParams,
  signal?: AbortSignal
) => {
  return getBranchSalesDailyMutator<GetBranchSalesDailyResponseDto>({
    url: `/analytics/store/${accessDataKey}/branch/sales/daily`,
    method: 'get',
    params,
    signal
  });
};

export const getGetBranchSalesDailyQueryKey = (
  accessDataKey: string,
  params: GetBranchSalesDailyParams
) =>
  [
    `/analytics/store/${accessDataKey}/branch/sales/daily`,
    ...(params ? [params] : [])
  ] as const;

export type GetBranchSalesDailyInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBranchSalesDaily>>
>;
export type GetBranchSalesDailyInfiniteQueryError =
  GetBranchSalesDailyErrorType<Error>;

export const useGetBranchSalesDailyInfinite = <
  TData = Awaited<ReturnType<typeof getBranchSalesDaily>>,
  TError = GetBranchSalesDailyErrorType<Error>
>(
  accessDataKey: string,
  params: GetBranchSalesDailyParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getBranchSalesDaily>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetBranchSalesDailyQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBranchSalesDaily>>
  > = ({ signal }) => getBranchSalesDaily(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getBranchSalesDaily>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetBranchSalesDailyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBranchSalesDaily>>
>;
export type GetBranchSalesDailyQueryError = GetBranchSalesDailyErrorType<Error>;

export const useGetBranchSalesDaily = <
  TData = Awaited<ReturnType<typeof getBranchSalesDaily>>,
  TError = GetBranchSalesDailyErrorType<Error>
>(
  accessDataKey: string,
  params: GetBranchSalesDailyParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBranchSalesDaily>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetBranchSalesDailyQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBranchSalesDaily>>
  > = ({ signal }) => getBranchSalesDaily(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getBranchSalesDaily>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getSQMSalesDaily = (
  accessDataKey: string,
  params: GetSQMSalesDailyParams,
  signal?: AbortSignal
) => {
  return getSQMSalesDailyMutator<GetSQMSalesDailyResponseDto[]>({
    url: `/analytics/store/${accessDataKey}/sales-sqm/daily`,
    method: 'get',
    params,
    signal
  });
};

export const getGetSQMSalesDailyQueryKey = (
  accessDataKey: string,
  params: GetSQMSalesDailyParams
) =>
  [
    `/analytics/store/${accessDataKey}/sales-sqm/daily`,
    ...(params ? [params] : [])
  ] as const;

export type GetSQMSalesDailyInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSQMSalesDaily>>
>;
export type GetSQMSalesDailyInfiniteQueryError =
  GetSQMSalesDailyErrorType<Error>;

export const useGetSQMSalesDailyInfinite = <
  TData = Awaited<ReturnType<typeof getSQMSalesDaily>>,
  TError = GetSQMSalesDailyErrorType<Error>
>(
  accessDataKey: string,
  params: GetSQMSalesDailyParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getSQMSalesDaily>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetSQMSalesDailyQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSQMSalesDaily>>
  > = ({ signal }) => getSQMSalesDaily(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getSQMSalesDaily>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetSQMSalesDailyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSQMSalesDaily>>
>;
export type GetSQMSalesDailyQueryError = GetSQMSalesDailyErrorType<Error>;

export const useGetSQMSalesDaily = <
  TData = Awaited<ReturnType<typeof getSQMSalesDaily>>,
  TError = GetSQMSalesDailyErrorType<Error>
>(
  accessDataKey: string,
  params: GetSQMSalesDailyParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSQMSalesDaily>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetSQMSalesDailyQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSQMSalesDaily>>
  > = ({ signal }) => getSQMSalesDaily(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getSQMSalesDaily>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getSalesFlooringDaily = (
  accessDataKey: string,
  params: GetSalesFlooringDailyParams,
  signal?: AbortSignal
) => {
  return getSalesFlooringDailyMutator<GetSalesFlooringDailyResponseDto>({
    url: `/analytics/store/${accessDataKey}/flooring/sales/daily`,
    method: 'get',
    params,
    signal
  });
};

export const getGetSalesFlooringDailyQueryKey = (
  accessDataKey: string,
  params: GetSalesFlooringDailyParams
) =>
  [
    `/analytics/store/${accessDataKey}/flooring/sales/daily`,
    ...(params ? [params] : [])
  ] as const;

export type GetSalesFlooringDailyInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalesFlooringDaily>>
>;
export type GetSalesFlooringDailyInfiniteQueryError =
  GetSalesFlooringDailyErrorType<Error>;

export const useGetSalesFlooringDailyInfinite = <
  TData = Awaited<ReturnType<typeof getSalesFlooringDaily>>,
  TError = GetSalesFlooringDailyErrorType<Error>
>(
  accessDataKey: string,
  params: GetSalesFlooringDailyParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getSalesFlooringDaily>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetSalesFlooringDailyQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSalesFlooringDaily>>
  > = ({ signal }) => getSalesFlooringDaily(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getSalesFlooringDaily>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetSalesFlooringDailyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalesFlooringDaily>>
>;
export type GetSalesFlooringDailyQueryError =
  GetSalesFlooringDailyErrorType<Error>;

export const useGetSalesFlooringDaily = <
  TData = Awaited<ReturnType<typeof getSalesFlooringDaily>>,
  TError = GetSalesFlooringDailyErrorType<Error>
>(
  accessDataKey: string,
  params: GetSalesFlooringDailyParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSalesFlooringDaily>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetSalesFlooringDailyQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSalesFlooringDaily>>
  > = ({ signal }) => getSalesFlooringDaily(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getSalesFlooringDaily>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const listMallBigNumbers = (
  accessDataKey: string,
  params: ListMallBigNumbersParams,
  signal?: AbortSignal
) => {
  return listMallBigNumbersMutator<GetBigNumbersMallResponseDto>({
    url: `/analytics/store/${accessDataKey}/big-numbers/mall`,
    method: 'get',
    params,
    signal
  });
};

export const getListMallBigNumbersQueryKey = (
  accessDataKey: string,
  params: ListMallBigNumbersParams
) =>
  [
    `/analytics/store/${accessDataKey}/big-numbers/mall`,
    ...(params ? [params] : [])
  ] as const;

export type ListMallBigNumbersInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof listMallBigNumbers>>
>;
export type ListMallBigNumbersInfiniteQueryError =
  ListMallBigNumbersErrorType<Error>;

export const useListMallBigNumbersInfinite = <
  TData = Awaited<ReturnType<typeof listMallBigNumbers>>,
  TError = ListMallBigNumbersErrorType<Error>
>(
  accessDataKey: string,
  params: ListMallBigNumbersParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof listMallBigNumbers>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListMallBigNumbersQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listMallBigNumbers>>
  > = ({ signal }) => listMallBigNumbers(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof listMallBigNumbers>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type ListMallBigNumbersQueryResult = NonNullable<
  Awaited<ReturnType<typeof listMallBigNumbers>>
>;
export type ListMallBigNumbersQueryError = ListMallBigNumbersErrorType<Error>;

export const useListMallBigNumbers = <
  TData = Awaited<ReturnType<typeof listMallBigNumbers>>,
  TError = ListMallBigNumbersErrorType<Error>
>(
  accessDataKey: string,
  params: ListMallBigNumbersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listMallBigNumbers>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListMallBigNumbersQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listMallBigNumbers>>
  > = ({ signal }) => listMallBigNumbers(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof listMallBigNumbers>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const listMallHighLightsSales = (
  accessDataKey: string,
  params: ListMallHighLightsSalesParams,
  signal?: AbortSignal
) => {
  return listMallHighLightsSalesMutator<GetSalesHighlightsResponseDto>({
    url: `/analytics/store/${accessDataKey}/sales-highlights/mall`,
    method: 'get',
    params,
    signal
  });
};

export const getListMallHighLightsSalesQueryKey = (
  accessDataKey: string,
  params: ListMallHighLightsSalesParams
) =>
  [
    `/analytics/store/${accessDataKey}/sales-highlights/mall`,
    ...(params ? [params] : [])
  ] as const;

export type ListMallHighLightsSalesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof listMallHighLightsSales>>
>;
export type ListMallHighLightsSalesInfiniteQueryError =
  ListMallHighLightsSalesErrorType<Error>;

export const useListMallHighLightsSalesInfinite = <
  TData = Awaited<ReturnType<typeof listMallHighLightsSales>>,
  TError = ListMallHighLightsSalesErrorType<Error>
>(
  accessDataKey: string,
  params: ListMallHighLightsSalesParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof listMallHighLightsSales>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListMallHighLightsSalesQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listMallHighLightsSales>>
  > = ({ signal }) => listMallHighLightsSales(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof listMallHighLightsSales>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type ListMallHighLightsSalesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listMallHighLightsSales>>
>;
export type ListMallHighLightsSalesQueryError =
  ListMallHighLightsSalesErrorType<Error>;

export const useListMallHighLightsSales = <
  TData = Awaited<ReturnType<typeof listMallHighLightsSales>>,
  TError = ListMallHighLightsSalesErrorType<Error>
>(
  accessDataKey: string,
  params: ListMallHighLightsSalesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listMallHighLightsSales>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListMallHighLightsSalesQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listMallHighLightsSales>>
  > = ({ signal }) => listMallHighLightsSales(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof listMallHighLightsSales>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const listMallSegmentAnnualSales = (
  accessDataKey: string,
  params: ListMallSegmentAnnualSalesParams,
  signal?: AbortSignal
) => {
  return listMallSegmentAnnualSalesMutator<GetSegmentSalesAnnualMallResponseDto>(
    {
      url: `/analytics/store/${accessDataKey}/segment/sales-annual/mall`,
      method: 'get',
      params,
      signal
    }
  );
};

export const getListMallSegmentAnnualSalesQueryKey = (
  accessDataKey: string,
  params: ListMallSegmentAnnualSalesParams
) =>
  [
    `/analytics/store/${accessDataKey}/segment/sales-annual/mall`,
    ...(params ? [params] : [])
  ] as const;

export type ListMallSegmentAnnualSalesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof listMallSegmentAnnualSales>>
>;
export type ListMallSegmentAnnualSalesInfiniteQueryError =
  ListMallSegmentAnnualSalesErrorType<Error>;

export const useListMallSegmentAnnualSalesInfinite = <
  TData = Awaited<ReturnType<typeof listMallSegmentAnnualSales>>,
  TError = ListMallSegmentAnnualSalesErrorType<Error>
>(
  accessDataKey: string,
  params: ListMallSegmentAnnualSalesParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof listMallSegmentAnnualSales>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListMallSegmentAnnualSalesQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listMallSegmentAnnualSales>>
  > = ({ signal }) => listMallSegmentAnnualSales(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof listMallSegmentAnnualSales>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type ListMallSegmentAnnualSalesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listMallSegmentAnnualSales>>
>;
export type ListMallSegmentAnnualSalesQueryError =
  ListMallSegmentAnnualSalesErrorType<Error>;

export const useListMallSegmentAnnualSales = <
  TData = Awaited<ReturnType<typeof listMallSegmentAnnualSales>>,
  TError = ListMallSegmentAnnualSalesErrorType<Error>
>(
  accessDataKey: string,
  params: ListMallSegmentAnnualSalesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listMallSegmentAnnualSales>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListMallSegmentAnnualSalesQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listMallSegmentAnnualSales>>
  > = ({ signal }) => listMallSegmentAnnualSales(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof listMallSegmentAnnualSales>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const listMallBranches = (
  accessDataKey: string,
  signal?: AbortSignal
) => {
  return listMallBranchesMutator<GetMallBranchsResponseDto[]>({
    url: `/analytics/store/${accessDataKey}/branch/mall`,
    method: 'get',
    signal
  });
};

export const getListMallBranchesQueryKey = (accessDataKey: string) =>
  [`/analytics/store/${accessDataKey}/branch/mall`] as const;

export type ListMallBranchesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof listMallBranches>>
>;
export type ListMallBranchesInfiniteQueryError =
  ListMallBranchesErrorType<Error>;

export const useListMallBranchesInfinite = <
  TData = Awaited<ReturnType<typeof listMallBranches>>,
  TError = ListMallBranchesErrorType<Error>
>(
  accessDataKey: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof listMallBranches>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListMallBranchesQueryKey(accessDataKey);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listMallBranches>>
  > = ({ signal }) => listMallBranches(accessDataKey, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof listMallBranches>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type ListMallBranchesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listMallBranches>>
>;
export type ListMallBranchesQueryError = ListMallBranchesErrorType<Error>;

export const useListMallBranches = <
  TData = Awaited<ReturnType<typeof listMallBranches>>,
  TError = ListMallBranchesErrorType<Error>
>(
  accessDataKey: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listMallBranches>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListMallBranchesQueryKey(accessDataKey);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listMallBranches>>
  > = ({ signal }) => listMallBranches(accessDataKey, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof listMallBranches>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const listMallBranchSales = (
  accessDataKey: string,
  params: ListMallBranchSalesParams,
  signal?: AbortSignal
) => {
  return listMallBranchSalesMutator<GetSalesBranchsResponseDto[]>({
    url: `/analytics/store/${accessDataKey}/branch/sales/mall`,
    method: 'get',
    params,
    signal
  });
};

export const getListMallBranchSalesQueryKey = (
  accessDataKey: string,
  params: ListMallBranchSalesParams
) =>
  [
    `/analytics/store/${accessDataKey}/branch/sales/mall`,
    ...(params ? [params] : [])
  ] as const;

export type ListMallBranchSalesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof listMallBranchSales>>
>;
export type ListMallBranchSalesInfiniteQueryError =
  ListMallBranchSalesErrorType<Error>;

export const useListMallBranchSalesInfinite = <
  TData = Awaited<ReturnType<typeof listMallBranchSales>>,
  TError = ListMallBranchSalesErrorType<Error>
>(
  accessDataKey: string,
  params: ListMallBranchSalesParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof listMallBranchSales>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListMallBranchSalesQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listMallBranchSales>>
  > = ({ signal }) => listMallBranchSales(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof listMallBranchSales>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type ListMallBranchSalesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listMallBranchSales>>
>;
export type ListMallBranchSalesQueryError = ListMallBranchSalesErrorType<Error>;

export const useListMallBranchSales = <
  TData = Awaited<ReturnType<typeof listMallBranchSales>>,
  TError = ListMallBranchSalesErrorType<Error>
>(
  accessDataKey: string,
  params: ListMallBranchSalesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listMallBranchSales>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListMallBranchSalesQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listMallBranchSales>>
  > = ({ signal }) => listMallBranchSales(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof listMallBranchSales>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const listMallSegments = (
  accessDataKey: string,
  signal?: AbortSignal
) => {
  return listMallSegmentsMutator<GetSegmentsResponseDto[]>({
    url: `/analytics/store/${accessDataKey}/segment/mall`,
    method: 'get',
    signal
  });
};

export const getListMallSegmentsQueryKey = (accessDataKey: string) =>
  [`/analytics/store/${accessDataKey}/segment/mall`] as const;

export type ListMallSegmentsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof listMallSegments>>
>;
export type ListMallSegmentsInfiniteQueryError =
  ListMallSegmentsErrorType<Error>;

export const useListMallSegmentsInfinite = <
  TData = Awaited<ReturnType<typeof listMallSegments>>,
  TError = ListMallSegmentsErrorType<Error>
>(
  accessDataKey: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof listMallSegments>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListMallSegmentsQueryKey(accessDataKey);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listMallSegments>>
  > = ({ signal }) => listMallSegments(accessDataKey, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof listMallSegments>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type ListMallSegmentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listMallSegments>>
>;
export type ListMallSegmentsQueryError = ListMallSegmentsErrorType<Error>;

export const useListMallSegments = <
  TData = Awaited<ReturnType<typeof listMallSegments>>,
  TError = ListMallSegmentsErrorType<Error>
>(
  accessDataKey: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listMallSegments>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListMallSegmentsQueryKey(accessDataKey);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listMallSegments>>
  > = ({ signal }) => listMallSegments(accessDataKey, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof listMallSegments>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const listMallSegmentSales = (
  accessDataKey: string,
  params: ListMallSegmentSalesParams,
  signal?: AbortSignal
) => {
  return listMallSegmentSalesMutator<GetSalesBySegmentsResponseDto[]>({
    url: `/analytics/store/${accessDataKey}/segment/sales/mall`,
    method: 'get',
    params,
    signal
  });
};

export const getListMallSegmentSalesQueryKey = (
  accessDataKey: string,
  params: ListMallSegmentSalesParams
) =>
  [
    `/analytics/store/${accessDataKey}/segment/sales/mall`,
    ...(params ? [params] : [])
  ] as const;

export type ListMallSegmentSalesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof listMallSegmentSales>>
>;
export type ListMallSegmentSalesInfiniteQueryError =
  ListMallSegmentSalesErrorType<Error>;

export const useListMallSegmentSalesInfinite = <
  TData = Awaited<ReturnType<typeof listMallSegmentSales>>,
  TError = ListMallSegmentSalesErrorType<Error>
>(
  accessDataKey: string,
  params: ListMallSegmentSalesParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof listMallSegmentSales>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListMallSegmentSalesQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listMallSegmentSales>>
  > = ({ signal }) => listMallSegmentSales(accessDataKey, params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof listMallSegmentSales>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type ListMallSegmentSalesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listMallSegmentSales>>
>;
export type ListMallSegmentSalesQueryError =
  ListMallSegmentSalesErrorType<Error>;

export const useListMallSegmentSales = <
  TData = Awaited<ReturnType<typeof listMallSegmentSales>>,
  TError = ListMallSegmentSalesErrorType<Error>
>(
  accessDataKey: string,
  params: ListMallSegmentSalesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listMallSegmentSales>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListMallSegmentSalesQueryKey(accessDataKey, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listMallSegmentSales>>
  > = ({ signal }) => listMallSegmentSales(accessDataKey, params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof listMallSegmentSales>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!accessDataKey,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
