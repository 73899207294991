import { Stack } from '@mui/material';
import { endOfDay, format, lastDayOfMonth } from 'date-fns';
import { useState } from 'react';
import { DatePicker } from '../../../../../../../components/DatePicker';
import { useDashboardMonthlyContext } from '../../hooks/useDashboardMonthlyContext';

const RangeMonthDatepicker = () => {
  const { setPeriod, period } = useDashboardMonthlyContext();
  const { since, to } = period;
  const lastDayOf = lastDayOfMonth(
    new Date(Number(to.split('-')[0]), Number(to.split('-')[1]) - 1)
  );
  const [selectedPeriod, setSelectedPeriod] = useState([
    new Date(Number(since.split('-')[0]), Number(since.split('-')[1]) - 1),
    endOfDay(lastDayOf)
  ]);

  const handleMonthChange = (months: Date[]) => {
    if (months.length > 1) {
      const initialMonth = months[0];
      const finalMonth = months[1];

      setSelectedPeriod([initialMonth, finalMonth]);
      setPeriod({
        since: format(initialMonth, 'yyyy-MM'),
        to: format(finalMonth, 'yyyy-MM')
      });
    }
  };

  return (
    <Stack direction="row" alignContent="center" alignItems="center" gap={1}>
      <strong>Período</strong>
      <DatePicker
        range
        rangeHover
        onlyMonthPicker
        minDate="2019/01/01"
        value={selectedPeriod}
        onChange={handleMonthChange}
      />
    </Stack>
  );
};

export { RangeMonthDatepicker };
