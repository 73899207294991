import { ApexOptions } from 'apexcharts';
import { themeColors } from '../../../../theme/schemes/PureLightTheme';

export const mapValueLineChartDataType = (
  value: number,
  month: number,
  year: number,
  day?: number
) => ({
  value: value ?? 0,
  date: {
    day,
    month,
    year
  }
});

export const buildChartOptions = ({
  legendOptions,
  colors,
  yaxis,
  tooltip
}: {
  colors: string[];
  yaxis?: ApexYAxis | ApexYAxis[];
  xaxis?: ApexXAxis;
  tooltip?: ApexTooltip;
  legendOptions?: ApexLegend;
}): ApexOptions => ({
  chart: {
    type: 'line'
  },
  colors,
  noData: {
    align: 'center',
    text: 'Dados indisponíveis no momento',
    verticalAlign: 'middle'
  },
  stroke: {
    width: 2
  },
  grid: {
    yaxis: {
      lines: {
        show: true
      }
    },
    strokeDashArray: 5
  },
  yaxis,
  xaxis: {
    labels: {
      style: { fontWeight: 700, colors: themeColors.grayGoose[150] },
      formatter: (value: string) => (value ? value?.split(' ')[0] : value)
    }
  },
  tooltip,
  fill: {
    opacity: 1
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    ...legendOptions
  }
});
