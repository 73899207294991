/**
 * Generated by orval v6.13.1 🍺
 * Do not edit manually.
 * BACKEND FOR FRONTEND
 * This api is responsible to forward the request to the correct api
 * OpenAPI spec version: 0.0.1
 */
import { useQuery, useInfiniteQuery, useMutation } from 'react-query';
import type {
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  UseInfiniteQueryResult,
  QueryKey
} from 'react-query';
import type {
  FindManyCouponsResponseDto,
  ListCouponsParams,
  BenefitsCoupon,
  EditCouponDto,
  ListBenefitsStores200,
  ListBenefitsStoresParams,
  ListBenefitsMalls200,
  ListBenefitsMallsParams
} from '../../model';
import listCouponsMutator from '../../mutator/custom-client';
import type { ErrorType as ListCouponsErrorType } from '../../mutator/custom-client';
import showCouponMutator from '../../mutator/custom-client';
import type { ErrorType as ShowCouponErrorType } from '../../mutator/custom-client';
import editCouponMutator from '../../mutator/custom-client';
import type { ErrorType as EditCouponErrorType } from '../../mutator/custom-client';
import listBenefitsStoresMutator from '../../mutator/custom-client';
import type { ErrorType as ListBenefitsStoresErrorType } from '../../mutator/custom-client';
import listBenefitsMallsMutator from '../../mutator/custom-client';
import type { ErrorType as ListBenefitsMallsErrorType } from '../../mutator/custom-client';

export const listCoupons = (
  params: ListCouponsParams,
  signal?: AbortSignal
) => {
  return listCouponsMutator<FindManyCouponsResponseDto>({
    url: `/benefits/coupons`,
    method: 'get',
    params,
    signal
  });
};

export const getListCouponsQueryKey = (params: ListCouponsParams) =>
  [`/benefits/coupons`, ...(params ? [params] : [])] as const;

export type ListCouponsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCoupons>>
>;
export type ListCouponsInfiniteQueryError = ListCouponsErrorType<unknown>;

export const useListCouponsInfinite = <
  TData = Awaited<ReturnType<typeof listCoupons>>,
  TError = ListCouponsErrorType<unknown>
>(
  params: ListCouponsParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof listCoupons>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCouponsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCoupons>>> = ({
    signal
  }) => listCoupons(params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof listCoupons>>,
    TError,
    TData
  >({ queryKey, queryFn, retry: 3, ...queryOptions }) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type ListCouponsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCoupons>>
>;
export type ListCouponsQueryError = ListCouponsErrorType<unknown>;

export const useListCoupons = <
  TData = Awaited<ReturnType<typeof listCoupons>>,
  TError = ListCouponsErrorType<unknown>
>(
  params: ListCouponsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listCoupons>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListCouponsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCoupons>>> = ({
    signal
  }) => listCoupons(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof listCoupons>>,
    TError,
    TData
  >({ queryKey, queryFn, retry: 3, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const showCoupon = (couponId: number, signal?: AbortSignal) => {
  return showCouponMutator<BenefitsCoupon>({
    url: `/benefits/coupons/${couponId}`,
    method: 'get',
    signal
  });
};

export const getShowCouponQueryKey = (couponId: number) =>
  [`/benefits/coupons/${couponId}`] as const;

export type ShowCouponInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof showCoupon>>
>;
export type ShowCouponInfiniteQueryError = ShowCouponErrorType<unknown>;

export const useShowCouponInfinite = <
  TData = Awaited<ReturnType<typeof showCoupon>>,
  TError = ShowCouponErrorType<unknown>
>(
  couponId: number,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof showCoupon>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getShowCouponQueryKey(couponId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof showCoupon>>> = ({
    signal
  }) => showCoupon(couponId, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof showCoupon>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!couponId,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type ShowCouponQueryResult = NonNullable<
  Awaited<ReturnType<typeof showCoupon>>
>;
export type ShowCouponQueryError = ShowCouponErrorType<unknown>;

export const useShowCoupon = <
  TData = Awaited<ReturnType<typeof showCoupon>>,
  TError = ShowCouponErrorType<unknown>
>(
  couponId: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof showCoupon>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getShowCouponQueryKey(couponId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof showCoupon>>> = ({
    signal
  }) => showCoupon(couponId, signal);

  const query = useQuery<Awaited<ReturnType<typeof showCoupon>>, TError, TData>(
    { queryKey, queryFn, enabled: !!couponId, retry: 3, ...queryOptions }
  ) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const editCoupon = (couponId: number, editCouponDto: EditCouponDto) => {
  return editCouponMutator<BenefitsCoupon>({
    url: `/benefits/coupons/${couponId}`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: editCouponDto
  });
};

export type EditCouponMutationResult = NonNullable<
  Awaited<ReturnType<typeof editCoupon>>
>;
export type EditCouponMutationBody = EditCouponDto;
export type EditCouponMutationError = EditCouponErrorType<unknown>;

export const useEditCoupon = <
  TError = EditCouponErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof editCoupon>>,
    TError,
    { couponId: number; data: EditCouponDto },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof editCoupon>>,
    { couponId: number; data: EditCouponDto }
  > = (props) => {
    const { couponId, data } = props ?? {};

    return editCoupon(couponId, data);
  };

  return useMutation<
    Awaited<ReturnType<typeof editCoupon>>,
    TError,
    { couponId: number; data: EditCouponDto },
    TContext
  >(mutationFn, mutationOptions);
};
export const listBenefitsStores = (
  params: ListBenefitsStoresParams,
  signal?: AbortSignal
) => {
  return listBenefitsStoresMutator<ListBenefitsStores200>({
    url: `/benefits/stores`,
    method: 'get',
    params,
    signal
  });
};

export const getListBenefitsStoresQueryKey = (
  params: ListBenefitsStoresParams
) => [`/benefits/stores`, ...(params ? [params] : [])] as const;

export type ListBenefitsStoresInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof listBenefitsStores>>
>;
export type ListBenefitsStoresInfiniteQueryError =
  ListBenefitsStoresErrorType<unknown>;

export const useListBenefitsStoresInfinite = <
  TData = Awaited<ReturnType<typeof listBenefitsStores>>,
  TError = ListBenefitsStoresErrorType<unknown>
>(
  params: ListBenefitsStoresParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof listBenefitsStores>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListBenefitsStoresQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listBenefitsStores>>
  > = ({ signal }) => listBenefitsStores(params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof listBenefitsStores>>,
    TError,
    TData
  >({ queryKey, queryFn, retry: 3, ...queryOptions }) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type ListBenefitsStoresQueryResult = NonNullable<
  Awaited<ReturnType<typeof listBenefitsStores>>
>;
export type ListBenefitsStoresQueryError = ListBenefitsStoresErrorType<unknown>;

export const useListBenefitsStores = <
  TData = Awaited<ReturnType<typeof listBenefitsStores>>,
  TError = ListBenefitsStoresErrorType<unknown>
>(
  params: ListBenefitsStoresParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listBenefitsStores>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListBenefitsStoresQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listBenefitsStores>>
  > = ({ signal }) => listBenefitsStores(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof listBenefitsStores>>,
    TError,
    TData
  >({ queryKey, queryFn, retry: 3, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const listBenefitsMalls = (
  params: ListBenefitsMallsParams,
  signal?: AbortSignal
) => {
  return listBenefitsMallsMutator<ListBenefitsMalls200>({
    url: `/benefits/malls`,
    method: 'get',
    params,
    signal
  });
};

export const getListBenefitsMallsQueryKey = (params: ListBenefitsMallsParams) =>
  [`/benefits/malls`, ...(params ? [params] : [])] as const;

export type ListBenefitsMallsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof listBenefitsMalls>>
>;
export type ListBenefitsMallsInfiniteQueryError =
  ListBenefitsMallsErrorType<unknown>;

export const useListBenefitsMallsInfinite = <
  TData = Awaited<ReturnType<typeof listBenefitsMalls>>,
  TError = ListBenefitsMallsErrorType<unknown>
>(
  params: ListBenefitsMallsParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof listBenefitsMalls>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListBenefitsMallsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listBenefitsMalls>>
  > = ({ signal }) => listBenefitsMalls(params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof listBenefitsMalls>>,
    TError,
    TData
  >({ queryKey, queryFn, retry: 3, ...queryOptions }) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type ListBenefitsMallsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listBenefitsMalls>>
>;
export type ListBenefitsMallsQueryError = ListBenefitsMallsErrorType<unknown>;

export const useListBenefitsMalls = <
  TData = Awaited<ReturnType<typeof listBenefitsMalls>>,
  TError = ListBenefitsMallsErrorType<unknown>
>(
  params: ListBenefitsMallsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listBenefitsMalls>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListBenefitsMallsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listBenefitsMalls>>
  > = ({ signal }) => listBenefitsMalls(params, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof listBenefitsMalls>>,
    TError,
    TData
  >({ queryKey, queryFn, retry: 3, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};
