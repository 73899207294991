import { createContext, useMemo } from 'react';
import { useDashboardDaily } from '../hooks/useDashboardDaily';

export interface DailyPeriodType {
  since: string;
  to: string;
}

interface StoreViewDailyState {
  period: DailyPeriodType;
  setPeriod: (value: DailyPeriodType) => void;
}

const StoreViewDailyContext = createContext<StoreViewDailyState>({
  period: { since: '', to: '' },
  setPeriod: () => null
});

interface StoreViewDailyProviderProps {
  children: React.ReactNode;
}

export function StoreViewDailyProvider({
  children
}: StoreViewDailyProviderProps) {
  const { period, setPeriod } = useDashboardDaily();

  const providerValue = useMemo(
    () => ({
      period,
      setPeriod
    }),
    [period]
  );

  return (
    <StoreViewDailyContext.Provider value={providerValue}>
      {children}
    </StoreViewDailyContext.Provider>
  );
}

export default StoreViewDailyContext;
