import { AuthState } from '../../contexts/authContext';
import { Action } from './action';

const actionsMap: Record<
  string,
  (state: AuthState, action: Action) => AuthState
> = {
  // eslint-disable-next-line
  ADD_PERMISSIONS: (state: AuthState, action: any): AuthState => {
    const { user } = action.payload;
    const userWithPermissions = { ...user };

    return {
      ...state,
      user: userWithPermissions
    };
  },

  // eslint-disable-next-line
  SIGNIN: (state: AuthState, action: any): AuthState => {
    const { user } = action.payload;

    return {
      ...state,
      isInitialized: true,
      isAuthenticated: true,
      user: { ...user }
    };
  },

  SIGNOUT: (state: AuthState): AuthState => ({
    ...state,
    isInitialized: true,
    isAuthenticated: false,
    user: null
  }),

  // eslint-disable-next-line
  INITIALIZE: (state: AuthState, action: any): AuthState => {
    const { payload } = action;
    const currentUser = payload?.user
      ? { ...payload.user, isLoading: true }
      : null;

    return {
      ...state,
      isInitialized: true,
      user: currentUser
    };
  }
};

export const authReducer = (state: AuthState, action: Action): AuthState => {
  return actionsMap[action.type]
    ? actionsMap[action.type](state, action)
    : state;
};
