/**
 * Generated by orval v6.13.1 🍺
 * Do not edit manually.
 * BACKEND FOR FRONTEND
 * This api is responsible to forward the request to the correct api
 * OpenAPI spec version: 0.0.1
 */
import { useQuery, useInfiniteQuery, useMutation } from 'react-query';
import type {
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  UseInfiniteQueryResult,
  QueryKey
} from 'react-query';
import type {
  GetPermissionsResponseDTO,
  Error,
  GetProfileResponseDTO,
  CreateUserResponseDTO,
  CreateUserDTO,
  ListUsersResponseDTO,
  GetUsersParams,
  UpdateUserDTO,
  GetUserbyIdResponseDTO,
  GetUserbyEmailResponseDTO,
  GetStoreResponseDTO,
  GetBrandResponseDTO,
  CreateAnalyticsAccessResponseDTO,
  CreateUserAccessDataDTO,
  ResetPassword201,
  ResetPasswordDTO
} from '../../model';
import getPermissionsMutator from '../../mutator/custom-client';
import type { ErrorType as GetPermissionsErrorType } from '../../mutator/custom-client';
import getProfilesMutator from '../../mutator/custom-client';
import type { ErrorType as GetProfilesErrorType } from '../../mutator/custom-client';
import createUserMutator from '../../mutator/custom-client';
import type { ErrorType as CreateUserErrorType } from '../../mutator/custom-client';
import getUsersMutator from '../../mutator/authorization/get-users';
import type { ErrorType as GetUsersErrorType } from '../../mutator/authorization/get-users';
import updateUserMutator from '../../mutator/custom-client';
import type { ErrorType as UpdateUserErrorType } from '../../mutator/custom-client';
import deleteUsersMutator from '../../mutator/custom-client';
import type { ErrorType as DeleteUsersErrorType } from '../../mutator/custom-client';
import getUserByIdMutator from '../../mutator/authorization/get-user-by-id';
import type { ErrorType as GetUserByIdErrorType } from '../../mutator/authorization/get-user-by-id';
import getUserDetailsByEmailMutator from '../../mutator/authorization/get-user-details-by-email';
import type { ErrorType as GetUserDetailsByEmailErrorType } from '../../mutator/authorization/get-user-details-by-email';
import getStoresMutator from '../../mutator/custom-client';
import type { ErrorType as GetStoresErrorType } from '../../mutator/custom-client';
import getBrandsMutator from '../../mutator/custom-client';
import type { ErrorType as GetBrandsErrorType } from '../../mutator/custom-client';
import createAnalyticsAccessMutator from '../../mutator/custom-client';
import type { ErrorType as CreateAnalyticsAccessErrorType } from '../../mutator/custom-client';
import deleteAnalyticsAccessMutator from '../../mutator/custom-client';
import type { ErrorType as DeleteAnalyticsAccessErrorType } from '../../mutator/custom-client';
import resetPasswordMutator from '../../mutator/custom-client';
import type { ErrorType as ResetPasswordErrorType } from '../../mutator/custom-client';

export const getPermissions = (signal?: AbortSignal) => {
  return getPermissionsMutator<GetPermissionsResponseDTO>({
    url: `/authorization/permissions`,
    method: 'get',
    signal
  });
};

export const getGetPermissionsQueryKey = () =>
  [`/authorization/permissions`] as const;

export type GetPermissionsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPermissions>>
>;
export type GetPermissionsInfiniteQueryError = GetPermissionsErrorType<Error>;

export const useGetPermissionsInfinite = <
  TData = Awaited<ReturnType<typeof getPermissions>>,
  TError = GetPermissionsErrorType<Error>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getPermissions>>,
    TError,
    TData
  >;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPermissionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPermissions>>> = ({
    signal
  }) => getPermissions(signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getPermissions>>,
    TError,
    TData
  >({ queryKey, queryFn, retry: 3, ...queryOptions }) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetPermissionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPermissions>>
>;
export type GetPermissionsQueryError = GetPermissionsErrorType<Error>;

export const useGetPermissions = <
  TData = Awaited<ReturnType<typeof getPermissions>>,
  TError = GetPermissionsErrorType<Error>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPermissions>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPermissionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPermissions>>> = ({
    signal
  }) => getPermissions(signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getPermissions>>,
    TError,
    TData
  >({ queryKey, queryFn, retry: 3, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getProfiles = (signal?: AbortSignal) => {
  return getProfilesMutator<GetProfileResponseDTO[]>({
    url: `/authorization/profiles`,
    method: 'get',
    signal
  });
};

export const getGetProfilesQueryKey = () =>
  [`/authorization/profiles`] as const;

export type GetProfilesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProfiles>>
>;
export type GetProfilesInfiniteQueryError = GetProfilesErrorType<Error>;

export const useGetProfilesInfinite = <
  TData = Awaited<ReturnType<typeof getProfiles>>,
  TError = GetProfilesErrorType<Error>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getProfiles>>,
    TError,
    TData
  >;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProfilesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProfiles>>> = ({
    signal
  }) => getProfiles(signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getProfiles>>,
    TError,
    TData
  >({ queryKey, queryFn, retry: 3, ...queryOptions }) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetProfilesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProfiles>>
>;
export type GetProfilesQueryError = GetProfilesErrorType<Error>;

export const useGetProfiles = <
  TData = Awaited<ReturnType<typeof getProfiles>>,
  TError = GetProfilesErrorType<Error>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getProfiles>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProfilesQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProfiles>>> = ({
    signal
  }) => getProfiles(signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getProfiles>>,
    TError,
    TData
  >({ queryKey, queryFn, retry: 3, ...queryOptions }) as UseQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const createUser = (createUserDTO: CreateUserDTO) => {
  return createUserMutator<CreateUserResponseDTO>({
    url: `/authorization/users`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: createUserDTO
  });
};

export type CreateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof createUser>>
>;
export type CreateUserMutationBody = CreateUserDTO;
export type CreateUserMutationError = CreateUserErrorType<Error>;

export const useCreateUser = <
  TError = CreateUserErrorType<Error>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUser>>,
    TError,
    { data: CreateUserDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createUser>>,
    { data: CreateUserDTO }
  > = (props) => {
    const { data } = props ?? {};

    return createUser(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof createUser>>,
    TError,
    { data: CreateUserDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const getUsers = (params: GetUsersParams, signal?: AbortSignal) => {
  return getUsersMutator<ListUsersResponseDTO>({
    url: `/authorization/users`,
    method: 'get',
    params,
    signal
  });
};

export const getGetUsersQueryKey = (params: GetUsersParams) =>
  [`/authorization/users`, ...(params ? [params] : [])] as const;

export type GetUsersInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsers>>
>;
export type GetUsersInfiniteQueryError = GetUsersErrorType<Error>;

export const useGetUsersInfinite = <
  TData = Awaited<ReturnType<typeof getUsers>>,
  TError = GetUsersErrorType<Error>
>(
  params: GetUsersParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getUsers>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({
    signal
  }) => getUsers(params, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getUsers>>,
    TError,
    TData
  >({ queryKey, queryFn, retry: 3, ...queryOptions }) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUsers>>
>;
export type GetUsersQueryError = GetUsersErrorType<Error>;

export const useGetUsers = <
  TData = Awaited<ReturnType<typeof getUsers>>,
  TError = GetUsersErrorType<Error>
>(
  params: GetUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUsers>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsers>>> = ({
    signal
  }) => getUsers(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUsers>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const updateUser = (updateUserDTO: UpdateUserDTO) => {
  return updateUserMutator<void>({
    url: `/authorization/users`,
    method: 'patch',
    headers: { 'Content-Type': 'application/json' },
    data: updateUserDTO
  });
};

export type UpdateUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUser>>
>;
export type UpdateUserMutationBody = UpdateUserDTO;
export type UpdateUserMutationError = UpdateUserErrorType<Error>;

export const useUpdateUser = <
  TError = UpdateUserErrorType<Error>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { data: UpdateUserDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUser>>,
    { data: UpdateUserDTO }
  > = (props) => {
    const { data } = props ?? {};

    return updateUser(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof updateUser>>,
    TError,
    { data: UpdateUserDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const deleteUsers = (userId: string) => {
  return deleteUsersMutator<void>({
    url: `/authorization/users/${userId}`,
    method: 'delete'
  });
};

export type DeleteUsersMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUsers>>
>;

export type DeleteUsersMutationError = DeleteUsersErrorType<Error>;

export const useDeleteUsers = <
  TError = DeleteUsersErrorType<Error>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUsers>>,
    TError,
    { userId: string },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUsers>>,
    { userId: string }
  > = (props) => {
    const { userId } = props ?? {};

    return deleteUsers(userId);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteUsers>>,
    TError,
    { userId: string },
    TContext
  >(mutationFn, mutationOptions);
};
export const getUserById = (userId: string, signal?: AbortSignal) => {
  return getUserByIdMutator<GetUserbyIdResponseDTO>({
    url: `/authorization/users/${userId}`,
    method: 'get',
    signal
  });
};

export const getGetUserByIdQueryKey = (userId: string) =>
  [`/authorization/users/${userId}`] as const;

export type GetUserByIdInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserById>>
>;
export type GetUserByIdInfiniteQueryError = GetUserByIdErrorType<Error>;

export const useGetUserByIdInfinite = <
  TData = Awaited<ReturnType<typeof getUserById>>,
  TError = GetUserByIdErrorType<Error>
>(
  userId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getUserById>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserByIdQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserById>>> = ({
    signal
  }) => getUserById(userId, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getUserById>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!userId,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetUserByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserById>>
>;
export type GetUserByIdQueryError = GetUserByIdErrorType<Error>;

export const useGetUserById = <
  TData = Awaited<ReturnType<typeof getUserById>>,
  TError = GetUserByIdErrorType<Error>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserById>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserByIdQueryKey(userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserById>>> = ({
    signal
  }) => getUserById(userId, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getUserById>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!userId,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getUserDetailsByEmail = (email: string, signal?: AbortSignal) => {
  return getUserDetailsByEmailMutator<GetUserbyEmailResponseDTO>({
    url: `/authorization/users-email/${email}`,
    headers: {
      'x-b2b-permissions': window.localStorage.getItem('auth_token') || ''
    },
    method: 'get',
    signal
  });
};

export const getGetUserDetailsByEmailQueryKey = (email: string) =>
  [`/authorization/users-email/${email}`] as const;

export type GetUserDetailsByEmailInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserDetailsByEmail>>
>;
export type GetUserDetailsByEmailInfiniteQueryError =
  GetUserDetailsByEmailErrorType<Error>;

export const useGetUserDetailsByEmailInfinite = <
  TData = Awaited<ReturnType<typeof getUserDetailsByEmail>>,
  TError = GetUserDetailsByEmailErrorType<Error>
>(
  email: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getUserDetailsByEmail>>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUserDetailsByEmailQueryKey(email);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUserDetailsByEmail>>
  > = ({ signal }) => getUserDetailsByEmail(email, signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getUserDetailsByEmail>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!email,
    retry: 3,
    ...queryOptions
  }) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetUserDetailsByEmailQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserDetailsByEmail>>
>;
export type GetUserDetailsByEmailQueryError =
  GetUserDetailsByEmailErrorType<Error>;

export const useGetUserDetailsByEmail = <
  TData = Awaited<ReturnType<typeof getUserDetailsByEmail>>,
  TError = GetUserDetailsByEmailErrorType<Error>
>(
  email: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserDetailsByEmail>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUserDetailsByEmailQueryKey(email);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUserDetailsByEmail>>
  > = ({ signal }) => getUserDetailsByEmail(email, signal);

  const query = useQuery<
    Awaited<ReturnType<typeof getUserDetailsByEmail>>,
    TError,
    TData
  >({
    queryKey,
    queryFn,
    enabled: !!email,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getStores = (signal?: AbortSignal) => {
  return getStoresMutator<GetStoreResponseDTO[]>({
    url: `/authorization/users/stores`,
    method: 'get',
    signal
  });
};

export const getGetStoresQueryKey = () =>
  [`/authorization/users/stores`] as const;

export type GetStoresInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStores>>
>;
export type GetStoresInfiniteQueryError = GetStoresErrorType<Error>;

export const useGetStoresInfinite = <
  TData = Awaited<ReturnType<typeof getStores>>,
  TError = GetStoresErrorType<Error>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getStores>>,
    TError,
    TData
  >;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStoresQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStores>>> = ({
    signal
  }) => getStores(signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getStores>>,
    TError,
    TData
  >({ queryKey, queryFn, retry: 3, ...queryOptions }) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetStoresQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStores>>
>;
export type GetStoresQueryError = GetStoresErrorType<Error>;

export const useGetStores = <
  TData = Awaited<ReturnType<typeof getStores>>,
  TError = GetStoresErrorType<Error>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getStores>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStoresQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStores>>> = ({
    signal
  }) => getStores(signal);

  const query = useQuery<Awaited<ReturnType<typeof getStores>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const getBrands = (signal?: AbortSignal) => {
  return getBrandsMutator<GetBrandResponseDTO[]>({
    url: `/authorization/users/brands`,
    method: 'get',
    signal
  });
};

export const getGetBrandsQueryKey = () =>
  [`/authorization/users/brands`] as const;

export type GetBrandsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBrands>>
>;
export type GetBrandsInfiniteQueryError = GetBrandsErrorType<Error>;

export const useGetBrandsInfinite = <
  TData = Awaited<ReturnType<typeof getBrands>>,
  TError = GetBrandsErrorType<Error>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getBrands>>,
    TError,
    TData
  >;
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBrandsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBrands>>> = ({
    signal
  }) => getBrands(signal);

  const query = useInfiniteQuery<
    Awaited<ReturnType<typeof getBrands>>,
    TError,
    TData
  >({ queryKey, queryFn, retry: 3, ...queryOptions }) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export type GetBrandsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBrands>>
>;
export type GetBrandsQueryError = GetBrandsErrorType<Error>;

export const useGetBrands = <
  TData = Awaited<ReturnType<typeof getBrands>>,
  TError = GetBrandsErrorType<Error>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getBrands>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBrandsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBrands>>> = ({
    signal
  }) => getBrands(signal);

  const query = useQuery<Awaited<ReturnType<typeof getBrands>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 3,
    ...queryOptions
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
};

export const createAnalyticsAccess = (
  createUserAccessDataDTO: CreateUserAccessDataDTO
) => {
  return createAnalyticsAccessMutator<CreateAnalyticsAccessResponseDTO>({
    url: `/authorization/users/analytics-access`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: createUserAccessDataDTO
  });
};

export type CreateAnalyticsAccessMutationResult = NonNullable<
  Awaited<ReturnType<typeof createAnalyticsAccess>>
>;
export type CreateAnalyticsAccessMutationBody = CreateUserAccessDataDTO;
export type CreateAnalyticsAccessMutationError =
  CreateAnalyticsAccessErrorType<Error>;

export const useCreateAnalyticsAccess = <
  TError = CreateAnalyticsAccessErrorType<Error>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createAnalyticsAccess>>,
    TError,
    { data: CreateUserAccessDataDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createAnalyticsAccess>>,
    { data: CreateUserAccessDataDTO }
  > = (props) => {
    const { data } = props ?? {};

    return createAnalyticsAccess(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof createAnalyticsAccess>>,
    TError,
    { data: CreateUserAccessDataDTO },
    TContext
  >(mutationFn, mutationOptions);
};
export const deleteAnalyticsAccess = (analyticsAccessDataId: number) => {
  return deleteAnalyticsAccessMutator<void>({
    url: `/authorization/users/analytics-access/${analyticsAccessDataId}`,
    method: 'delete'
  });
};

export type DeleteAnalyticsAccessMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAnalyticsAccess>>
>;

export type DeleteAnalyticsAccessMutationError =
  DeleteAnalyticsAccessErrorType<Error>;

export const useDeleteAnalyticsAccess = <
  TError = DeleteAnalyticsAccessErrorType<Error>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAnalyticsAccess>>,
    TError,
    { analyticsAccessDataId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAnalyticsAccess>>,
    { analyticsAccessDataId: number }
  > = (props) => {
    const { analyticsAccessDataId } = props ?? {};

    return deleteAnalyticsAccess(analyticsAccessDataId);
  };

  return useMutation<
    Awaited<ReturnType<typeof deleteAnalyticsAccess>>,
    TError,
    { analyticsAccessDataId: number },
    TContext
  >(mutationFn, mutationOptions);
};
export const resetPassword = (resetPasswordDTO: ResetPasswordDTO) => {
  return resetPasswordMutator<void | ResetPassword201>({
    url: `/authorization/users/reset-password`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: resetPasswordDTO
  });
};

export type ResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof resetPassword>>
>;
export type ResetPasswordMutationBody = ResetPasswordDTO;
export type ResetPasswordMutationError = ResetPasswordErrorType<Error>;

export const useResetPassword = <
  TError = ResetPasswordErrorType<Error>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof resetPassword>>,
    TError,
    { data: ResetPasswordDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof resetPassword>>,
    { data: ResetPasswordDTO }
  > = (props) => {
    const { data } = props ?? {};

    return resetPassword(data);
  };

  return useMutation<
    Awaited<ReturnType<typeof resetPassword>>,
    TError,
    { data: ResetPasswordDTO },
    TContext
  >(mutationFn, mutationOptions);
};
