import { useMemo } from 'react';
import { Stack } from '@mui/material';
import { useListMallHighLightsSales } from '../../../../../../api';
import { BigNumber } from '../../../../../../common/analytics/BigNumber';
import { useDashboard } from '../../../../hooks/useDashboard';
import { useDashboardShoppingContext } from '../hooks/useDashboardShoppingContext';

export function SalesHighLights() {
  const { selectedStore } = useDashboard();
  const { period } = useDashboardShoppingContext();
  const { data: salesHighLightsResponse, isLoading } =
    useListMallHighLightsSales(
      selectedStore,
      {
        'period.since': period.since,
        'period.to': period.to
      },
      {
        query: {
          enabled: !!selectedStore && !!period.since && !!period.to
        }
      }
    );

  const storeSalesHighLights = useMemo(
    () => salesHighLightsResponse,
    [salesHighLightsResponse]
  );

  return (
    <Stack gap={1.5} direction="row">
      <BigNumber.Box
        dataTestid="highlight-sales-brand"
        title="Vendas no Período Selecionado"
        contentValue={storeSalesHighLights?.salesBrand ?? 0}
        isLoading={isLoading}
        helpText="Somatório das vendas totais da marca no período de datas selecionado."
      />
      <BigNumber.Box
        dataTestid="highlight-sales-segment"
        title="Vendas no Período Selecionado"
        contentValue={storeSalesHighLights?.salesSegment ?? 0}
        color="orange"
        isLoading={isLoading}
        helpText="Somatório das vendas totais do segmento da marca no período de datas selecionado."
      />
      <BigNumber.Box
        dataTestid="highlight-sales-mall"
        title="Vendas no Período Selecionado"
        contentValue={storeSalesHighLights?.salesMall ?? 0}
        color="blue"
        isLoading={isLoading}
        helpText="Somatório das vendas totais do Shopping no período de datas selecionado."
      />
    </Stack>
  );
}
