import { ReactNode, useRef } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Stack
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { ChartContainer } from '../shared/ChartBase';
import { CheckboxListTitle } from './styles';

type CardCheckboxListProps = {
  title: string;
  isLoading?: boolean;
  children: ReactNode;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
};

function CardCheckboxList({
  title,
  isLoading = false,
  children,
  direction = 'row'
}: CardCheckboxListProps) {
  return (
    <ChartContainer title={title} height="370px">
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={284}
        />
      ) : (
        <Box paddingX={2.4444444444} overflow="scroll">
          <Stack direction={direction}>{children}</Stack>
        </Box>
      )}
    </ChartContainer>
  );
}

export type CheckboxListData = {
  id: string;
  name: string;
  checked: boolean;
};

export type ListCheckboxListOnSelectProps = {
  currentIdSelected: string | number;
  checkboxes: CheckboxListData[];
};

type ListCheckboxListProps = {
  title?: string;
  data?: CheckboxListData[];
  onSelect?: (props: ListCheckboxListOnSelectProps) => void;
};

function ListCheckboxList({ title, data, onSelect }: ListCheckboxListProps) {
  const checkboxRef = useRef<HTMLInputElement[] | null>([]);
  const handleChange = (id: string | number) => {
    const checkboxes = checkboxRef.current!.map((checkboxItem) => {
      return {
        id: checkboxItem.id,
        name: checkboxItem.name,
        checked: checkboxItem.checked
      };
    });
    if (typeof onSelect === 'function')
      onSelect({ currentIdSelected: id, checkboxes });
  };

  return (
    <FormGroup sx={{ marginTop: '13px', flex: 1 }}>
      {title && <CheckboxListTitle>{title}</CheckboxListTitle>}
      {data?.length ? (
        data.map((item, i: number) => (
          <FormControlLabel
            onChange={() => handleChange(item.id)}
            key={uuidv4()}
            control={
              <Checkbox
                checked={
                  checkboxRef.current?.length
                    ? checkboxRef.current[i].checked
                    : item.checked
                }
                name={item.id}
                inputRef={(input) => {
                  checkboxRef.current![i] = input!;
                }}
                sx={{ padding: '6px' }}
              />
            }
            label={item.name}
          />
        ))
      ) : (
        <p style={{ fontSize: 14, textAlign: 'center' }}>
          Dados indisponíveis no momento
        </p>
      )}
    </FormGroup>
  );
}

export const CheckboxList = {
  Card: CardCheckboxList,
  List: ListCheckboxList
};
