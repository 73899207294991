import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { GetMallBranchsResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';
import { CheckboxListData } from '../../../common/analytics/CheckboxList';

const mapper = (res: AxiosResponse<GetMallBranchsResponseDto[]>) => {
  const { data } = res;
  const result: CheckboxListData[] = [];

  data.forEach((item) => {
    result.push({
      id: item.branch,
      name: item.branch,
      checked: false
    });
  });

  return result as unknown as Promise<CheckboxListData[]>;
};

// eslint-disable-next-line
export const listMallBranches = <T>(
  config: AxiosRequestConfig
): Promise<CheckboxListData[]> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default listMallBranches;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
