import { HelpOutline, StorefrontTwoTone } from '@mui/icons-material';
import React from 'react';
import { Container, Header } from './styles';
import { Tooltip } from '../../../../components/Tooltip';

interface ChartHeaderProps {
  title: string;
  helpText?: string;
}

function ChartHeader({ title, helpText }: ChartHeaderProps) {
  return (
    <Header>
      <div>
        <StorefrontTwoTone />
        <p>{title.toLocaleUpperCase()}</p>
      </div>
      {helpText && (
        <Tooltip title={helpText}>
          <HelpOutline fontSize="small" />
        </Tooltip>
      )}
    </Header>
  );
}

interface ChartContainerProps {
  children: React.ReactNode;
  width?: string;
  height?: string;
  title: string;
  helpText?: string;
}

export function ChartContainer({
  title,
  children,
  width = '100%',
  height = '',
  helpText
}: ChartContainerProps) {
  return (
    <Container width={width} height={height}>
      <ChartHeader title={title} helpText={helpText} />
      {children}
    </Container>
  );
}
