import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { GetSalesFlooringDailyResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';
import { DonutChartData } from '../../../common/analytics/DonutChart';

const mapper = (res: AxiosResponse<GetSalesFlooringDailyResponseDto>) => {
  const { data } = res;
  const result = [
    {
      name: 'Marca',
      value: Number(data.salesBrand ? data.salesBrand.toFixed(2) : 0)
    },
    {
      name: 'Piso',
      value: Number(data.salesFlooring ? data.salesFlooring.toFixed(2) : 0)
    }
  ];

  return result as unknown as Promise<DonutChartData[]>;
};

// eslint-disable-next-line
export const getSalesFlooringDailyMutator = <T>(
  config: AxiosRequestConfig
): Promise<DonutChartData[]> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getSalesFlooringDailyMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
