import { format } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';

export const dateFormatter = ({
  day,
  month,
  year
}: {
  day?: number;
  month: number;
  year: number;
}) => {
  const dateStringFormat = day ? 'dd/MM/yyyy' : 'MMM yy';

  const monthAndYear = `${month.toString().padStart(2, '0')}/$DAY/${year}`;

  const date = day
    ? monthAndYear.replace('$DAY', day.toString().padStart(2, '0'))
    : monthAndYear.replace('$DAY', '01');

  return format(new Date(date), dateStringFormat, {
    locale: ptBr
  }).toUpperCase();
};
