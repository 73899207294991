import { Stack, Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { eachMonthOfInterval, eachYearOfInterval } from 'date-fns';
import { useListMallSegmentAnnualSales } from '../../../../../../api';
import {
  CheckboxList,
  ListCheckboxListOnSelectProps
} from '../../../../../../common/analytics/CheckboxList';
import { LineChart } from '../../../../../../common/analytics/LineChart';
import { useDashboard } from '../../../../hooks/useDashboard';
import { ucFirst, zeroPad } from '../../../../../../helpers/typography';

const SalesSegmentAnnual = () => {
  const currentDate = new Date();
  const { selectedStore, storeDetails } = useDashboard();
  const [selectedYears, setSelectedYears] = useState<string[]>([
    String(currentDate.getFullYear())
  ]);
  const [selectedMonths, setSelectedMonths] = useState<string[]>(
    eachMonthOfInterval({
      start: new Date(2019, 0),
      end: new Date(2019, 11)
    })
      .filter((monthItem) => {
        return currentDate.getMonth() >= monthItem.getMonth();
      })
      .map((monthItem) => zeroPad(String(monthItem.getMonth() + 1)))
  );
  const [quantityDataUpdates, setQuantityDataUpdates] = useState(1);

  const { data: mallSegmentSalesData, isLoading: isLoadingMallSegmentSales } =
    useListMallSegmentAnnualSales(
      selectedStore,
      {
        years: selectedYears.join(','),
        months: selectedMonths.join(',')
      },
      {
        query: {
          enabled:
            !!selectedStore && !!selectedYears.length && !!selectedMonths.length
        }
      }
    );

  const salesSegmentAnnualList = useMemo(
    () =>
      selectedYears.length && selectedMonths.length ? mallSegmentSalesData : [],
    [selectedYears, selectedMonths, mallSegmentSalesData]
  );

  useEffect(() => {
    setQuantityDataUpdates((prev) => prev + 1);
  }, [salesSegmentAnnualList]);

  const handleOnSelectYear = ({
    checkboxes
  }: ListCheckboxListOnSelectProps) => {
    setSelectedYears(
      checkboxes
        .filter((checkboxItem) => checkboxItem.checked)
        .map((checkboxItem) => encodeURIComponent(checkboxItem.name))
    );
  };

  const handleOnSelectMonth = ({
    checkboxes
  }: ListCheckboxListOnSelectProps) => {
    setSelectedMonths(
      checkboxes
        .filter((checkboxItem) => checkboxItem.checked)
        .map((checkboxItem) => encodeURIComponent(checkboxItem.name))
    );
  };

  const yearList = eachYearOfInterval({
    start: new Date(2019, 0),
    end: new Date()
  }).map((yearItem) => ({
    id: String(yearItem.getFullYear()),
    name: String(yearItem.getFullYear()),
    checked: !!selectedYears.find(
      (checkedItem) => String(yearItem.getFullYear()) === checkedItem
    )
  }));
  const monthList = eachMonthOfInterval({
    start: new Date(2019, 0),
    end: new Date(2019, 11)
  }).map((yearItem) => ({
    id: zeroPad(String(yearItem.getMonth() + 1)),
    name: ucFirst(yearItem.toLocaleString('pt-BR', { month: 'long' })),
    checked: !!selectedMonths.find(
      (checkedItem) => zeroPad(String(yearItem.getMonth() + 1)) === checkedItem
    )
  }));

  return (
    <Stack direction="row" gap={1} alignContent="space-between">
      <LineChart
        key={quantityDataUpdates}
        title={`VENDAS MENSAIS DO SEGMENTO ${storeDetails.segment} POR ANO`}
        isLoading={isLoadingMallSegmentSales}
        dataSerie={salesSegmentAnnualList}
        legendOptions={{ show: false }}
        helpText="Somatório das vendas totais do segmento da marca por mês segmentada por ano."
      />
      <Box width={687}>
        <CheckboxList.Card title="PERÍODO DE VENDAS">
          <CheckboxList.List
            data={yearList}
            title="Ano"
            onSelect={handleOnSelectYear}
          />
          <CheckboxList.List
            data={monthList}
            title="Mês"
            onSelect={handleOnSelectMonth}
          />
        </CheckboxList.Card>
      </Box>
    </Stack>
  );
};

export { SalesSegmentAnnual };
