import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { GetMallStoresResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';

export type MallStores = {
  brand: string;
  luc: string;
};

const mapper = (res: AxiosResponse<GetMallStoresResponseDto[]>) => {
  const { data } = res;

  return data.map((item) => ({
    brand: item.brandName,
    luc: item.lucCode
  })) as unknown as Promise<MallStores[]>;
};

// eslint-disable-next-line
export const getMallStoresMutator = <T>(
  config: AxiosRequestConfig
): Promise<MallStores[]> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getMallStoresMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
