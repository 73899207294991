import { INTERNAL_EMAILS_DOMAINS } from '../constants/auth';
import { ROLES } from '../constants/roles';
import useAuth from '../hooks/useAuth';
import { User } from '../model/user';

function userHasPermission(
  allowedRoles: string[],
  user?: User | null
): boolean {
  const { user: authUser } = useAuth();
  const selectedUser = user ?? authUser;
  const isUserAdmin = !!selectedUser?.roles?.find(
    (role) => role === ROLES.Admin.slug
  );

  if (isUserAdmin) return true;

  return !!selectedUser?.roles?.find((role) => allowedRoles?.includes(role));
}

export function isInternalUser(email: string): boolean {
  const [, emailDomain] = email.split('@');
  return INTERNAL_EMAILS_DOMAINS.includes(emailDomain);
}

export default { userHasPermission, isInternalUser };
