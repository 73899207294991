import { QueryFunctionContext } from 'react-query';
import {
  useGetSegmentSalesByDayPeriodQueryKey,
  useGetSegmentSalesByMonthPeriodQueryKey
} from '../../../api/analytics/hooks/use-segment-sales';
import {
  GetSegmentSalesByDayPeriodResponse,
  GetSegmentSalesByDayPeriodParams,
  GetSegmentSalesByMonthPeriodResponse,
  GetSegmentSalesByMonthPeriodParams
} from '../../../api/analytics/requests/segment-sales.request';
import HttpIntegrationService from '../http-service';

class SegmentSalesIntegrationService extends HttpIntegrationService {
  async getSegmentStoreSalesByMonthPeriod({
    queryKey
  }: QueryFunctionContext): Promise<GetSegmentSalesByMonthPeriodResponse> {
    const [, query] = queryKey as ReturnType<
      typeof useGetSegmentSalesByMonthPeriodQueryKey
    >;
    const segmentSalesByMonthPeriodParams =
      query as GetSegmentSalesByMonthPeriodParams;

    return this.httpService.get(
      `/analytics/store/${encodeURIComponent(
        segmentSalesByMonthPeriodParams.accessDataKey
      )}/segment/sales`,
      {
        params: {
          'period.since': segmentSalesByMonthPeriodParams.since,
          'period.to': segmentSalesByMonthPeriodParams.to
        }
      }
    );
  }

  async getDailySegmentStoreSalesByDayPeriod({
    queryKey
  }: QueryFunctionContext): Promise<GetSegmentSalesByDayPeriodResponse> {
    const [, query] = queryKey as ReturnType<
      typeof useGetSegmentSalesByDayPeriodQueryKey
    >;
    const segmentSalesByDayPeriodParams =
      query as GetSegmentSalesByDayPeriodParams;

    return this.httpService.get(
      `/analytics/store/${encodeURIComponent(
        segmentSalesByDayPeriodParams.accessDataKey
      )}/segment/sales-comparative/daily`,
      {
        params: {
          'period.since': segmentSalesByDayPeriodParams.since,
          'period.to': segmentSalesByDayPeriodParams.to
        }
      }
    );
  }
}

export default new SegmentSalesIntegrationService();
