import { ApexOptions } from 'apexcharts';
import { formatMoneyToBRL } from '../../../../helpers/money';
import { formatPercentage } from '../../../../helpers/percentage';

import { themeColors } from '../../../../theme/schemes/PureLightTheme';

export const buildChartsConfigs = (
  colors: string[],
  options?: { stacked?: boolean; yaxisMin?: number; yaxisMax?: number }
): ApexOptions => {
  const { yaxisMin = 0, yaxisMax = 0 } = options!;

  return {
    chart: {
      type: 'line',
      stacked: options?.stacked ?? false
    },
    noData: {
      align: 'center',
      text: 'Dados indisponíveis no momento',
      verticalAlign: 'middle'
    },
    colors,
    stroke: {
      width: [0, 0, 2]
    },
    markers: {
      hover: {
        sizeOffset: 2
      },
      shape: 'circle',
      size: 5,
      strokeWidth: 0
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: [themeColors.gray2]
      },
      background: {
        enabled: true,
        foreColor: themeColors.secondary,
        borderRadius: 3,
        padding: 5,
        opacity: 1,
        dropShadow: {}
      },
      formatter: (val: string | number) => formatPercentage(val),
      enabledOnSeries: [2],
      offsetX: -20
    },
    grid: {
      yaxis: {
        lines: {
          show: true
        }
      },
      strokeDashArray: 5
    },
    yaxis: [
      {
        min: yaxisMin,
        max: yaxisMax,
        labels: {
          style: {
            fontWeight: 700,
            colors: themeColors.grayGoose[150]
          },
          formatter: (val: string | number) => {
            const result = Number(val) / 1000;
            if (result >= 1000) {
              return `R$ ${parseInt(String(Number(val) / 1000000), 10)} MM`;
            }
            return `R$ ${parseInt(String(Number(val) / 1000), 10)} M`;
          }
        }
      },
      {
        min: yaxisMin,
        max: yaxisMax,
        seriesName: 'Período Selecionado',
        show: false,
        labels: {
          formatter: (val: string | number) => {
            const result = Number(val) / 1000;
            if (result >= 1000) {
              return `R$ ${parseInt(String(Number(val) / 1000000), 10)} MM`;
            }
            return `R$ ${parseInt(String(Number(val) / 1000), 10)} M`;
          }
        }
      },
      {
        show: false,
        seriesName: 'Variação %',
        title: {
          text: undefined
        }
      }
    ],
    xaxis: {
      labels: {
        style: {
          fontWeight: 700,
          colors: themeColors.grayGoose[150]
        }
      }
    },
    tooltip: {
      y: [
        {
          formatter: (val: string | number) => {
            return formatMoneyToBRL(Number(val));
          }
        },
        {
          formatter: (val: string | number) => {
            return formatMoneyToBRL(Number(val));
          }
        },
        {
          formatter: (val: string | number) => formatPercentage(val)
        }
      ]
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: -10
    }
  };
};
