import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { GetBranchSalesResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';
import { DonutChartData } from '../../../common/analytics/DonutChart';

const mapper = (res: AxiosResponse<GetBranchSalesResponseDto>) => {
  const { data } = res;
  const result = data.segments.map((item) => ({
    name: item.name,
    value: Number(item.sales.toFixed(2))
  }));

  return result as unknown as Promise<DonutChartData[]>;
};

// eslint-disable-next-line
export const getBranchSalesMutator = <T>(
  config: AxiosRequestConfig
): Promise<DonutChartData[]> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getBranchSalesMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
