import { styled } from '@mui/material';

export const LoadingContainer = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;

  p {
    font-weight: bold;
  }
`;
