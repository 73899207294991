import { useTheme } from '@mui/material';
import { isWeekend } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useGetDailySales } from '../../../../../../../api';
import { ColumnChart } from '../../../../../../../common/analytics/ColumnChart';
import { useDashboard } from '../../../../../hooks/useDashboard';
import { useDashboardDailyContext } from '../../hooks/useDashboardDailyContext';

const StoreSales = () => {
  const theme = useTheme();
  const { selectedStore } = useDashboard();
  const { period } = useDashboardDailyContext();
  const [keyCount, setKeyCount] = useState(0);

  const { data: getDailySalesData, isLoading } = useGetDailySales(
    selectedStore,
    {
      'period.since': period.since,
      'period.to': period.to
    },
    {
      query: {
        enabled: !!period.since && !!period.to && !!selectedStore
      }
    }
  );

  const colors = useMemo(() => {
    const items: string[] = [];
    if (!getDailySalesData?.length) {
      return [theme.colors.dashboard.darkBlue];
    }

    getDailySalesData[0].data.forEach((item) => {
      const itemDate = item.date;

      items.push(
        isWeekend(new Date(itemDate.year, itemDate.month - 1, itemDate.day))
          ? theme.colors.dashboard.lightBlue
          : theme.colors.dashboard.darkBlue
      );
    });

    return items;
  }, [getDailySalesData]);

  const storeSalesList = useMemo(
    () => getDailySalesData ?? [],
    [getDailySalesData]
  );

  useEffect(() => {
    setKeyCount((prev) => prev + 1);
  }, [storeSalesList]);

  return (
    <ColumnChart
      key={keyCount}
      title="VENDAS DIÁRIAS COM DESTAQUE NOS FINAIS DE SEMANA"
      data={storeSalesList}
      isLoading={isLoading}
      colors={colors}
      helpText="Vendas totais diárias da marca no período de datas selecionado com ênfase nos finais de semana."
    />
  );
};

export { StoreSales };
