import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { GetSalesPerMeterSquareMonthlyResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';
import {
  LineChartData,
  LineChartDataValue
} from '../../../common/analytics/LineChart';
import { mapValueLineChartDataType } from '../../../common/analytics/LineChart/config';

type SquareMeterSalesComparative = {
  month: string;
  brand: number;
  brandM2: number;
  segment: number;
  segmentM2: number;
  branch: number;
  branchM2: number;
  mall: number;
  mallM2: number;
};

type GetSalesPerMeterSquareMonthly = {
  squareMeterSales: LineChartData[];
  squareMeterSalesComparative: SquareMeterSalesComparative[];
};

const mapper = (
  res: AxiosResponse<GetSalesPerMeterSquareMonthlyResponseDto[]>
) => {
  const { data } = res;
  const salesBrand: LineChartDataValue[] = [];
  const salesBranch: LineChartDataValue[] = [];
  const salesSegment: LineChartDataValue[] = [];
  const salesCompative: SquareMeterSalesComparative[] = [];

  data.forEach((item) => {
    const monthData = Number(item.month);
    const yearData = Number(item.year);

    salesBrand.push(
      mapValueLineChartDataType(item.brand.sales, monthData, yearData)
    );
    salesBranch.push(
      mapValueLineChartDataType(item.branch.sales, monthData, yearData)
    );
    salesSegment.push(
      mapValueLineChartDataType(item.segment.sales, monthData, yearData)
    );

    const formattedMonth = format(
      new Date(yearData, monthData - 1, 1),
      'LLL yyyy',
      { locale: ptBR }
    );

    salesCompative.push({
      month: formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1),
      brand: item.brand.sales,
      brandM2: item.brand.salesSqm,
      branch: item.branch.sales,
      branchM2: item.branch.salesSqm,
      segment: item.segment.sales,
      segmentM2: item.segment.salesSqm,
      mall: item.mall.sales,
      mallM2: item.mall.salesSqm
    });
  });

  const formattedDataSquareMeterSales: GetSalesPerMeterSquareMonthly['squareMeterSales'] =
    [
      {
        name: 'Vendas/m²',
        data: salesBrand
      },
      {
        name: 'Vendas/m² Segmento',
        data: salesSegment
      },
      {
        name: 'Vendas/m² Ramo',
        data: salesBranch
      }
    ];

  return {
    squareMeterSales: formattedDataSquareMeterSales,
    squareMeterSalesComparative: salesCompative
  } as unknown as Promise<GetSalesPerMeterSquareMonthly>;
};

// eslint-disable-next-line
export const getSalesPerMeterSquareMonthlyMutator = <T>(
  config: AxiosRequestConfig
): Promise<GetSalesPerMeterSquareMonthly> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getSalesPerMeterSquareMonthlyMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
