import { Box, Skeleton, useTheme } from '@mui/material';
import { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { formatMoneyToBRL } from '../../../helpers/money';
import { themeColors } from '../../../theme/schemes/PureLightTheme';
import { dateFormatter } from '../helpers/date-formatter';
import { ChartContainer } from '../shared/ChartBase';
import { buildChartOptions } from './config';

export interface LineChartData {
  name: string;
  data: {
    value: number;
    date: {
      day?: number;
      month: number;
      year: number;
    };
  }[];
}

export type LineChartDataValue = LineChartData['data'][0];

interface LineChartProps {
  title: string;
  dataSerie?: LineChartData[];
  isLoading?: boolean;
}

const buildDataSerie = (params: LineChartData[]): ApexAxisChartSeries => {
  return params.map((param) => ({
    name: param.name,
    data: param.data
      ? param.data.map((data) => ({
          y: data.value,
          x: dateFormatter({
            day: data.date.day,
            month: data.date.month,
            year: data.date.year
          })
        }))
      : []
  }));
};

export function LineChart({
  title,
  dataSerie,
  isLoading = false,
  helpText,
  yFormatter = (val: string | number) => {
    const result = Number(val) / 1000;
    if (result >= 1000) {
      return `R$ ${Number(val) / 1000000} MM`;
    }
    return `R$ ${Number(val) / 1000} M`;
  },
  tooltip = {
    y: {
      formatter: (val: string | number) => formatMoneyToBRL(Number(val))
    }
  },
  legendOptions
}: LineChartProps & {
  yFormatter?: (val: number, opts?: any) => string | string[];
  tooltip?: ApexTooltip;
  legendOptions?: ApexLegend;
  helpText?: string;
}) {
  const theme = useTheme();

  const opts = useMemo(
    () =>
      buildChartOptions({
        legendOptions,
        colors: Object.values(theme.colors.dashboard),
        yaxis: {
          labels: {
            style: {
              fontWeight: 700,
              colors: themeColors.grayGoose[150]
            },
            formatter: yFormatter
          }
        },
        tooltip
      }),
    [theme.colors.dashboard]
  );

  const data = useMemo(
    () => (dataSerie ? buildDataSerie(dataSerie) : []),
    [isLoading]
  );

  return (
    <ChartContainer title={title} height="370px" helpText={helpText}>
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={284}
        />
      ) : (
        <Box paddingTop={1.6666666667}>
          <Chart
            series={data}
            type="line"
            options={opts}
            width="100%"
            height={284}
          />
        </Box>
      )}
    </ChartContainer>
  );
}
