import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { GetBrandsByBranchResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';

type BrandsByBranch = string;

const mapper = (res: AxiosResponse<GetBrandsByBranchResponseDto[]>) => {
  const { data } = res;
  const result = data.map((item) => item.name);

  return result as unknown as Promise<BrandsByBranch[]>;
};

// eslint-disable-next-line
export const getBrandsByBranchMutator = <T>(
  config: AxiosRequestConfig
): Promise<BrandsByBranch[]> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getBrandsByBranchMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
