import { Paper, styled } from '@mui/material';
import { themeColors } from '../../../theme/schemes/PureLightTheme';

export const CardContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 8px;
  font-weight: bold;
  padding: 1.5rem 1rem;
  width: 100%;
  border: 1px solid ${themeColors.grayGoose[100]};
`;

export const Header = styled('header')`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

export const Title = styled('p')`
  font-size: 0.875rem;
  line-height: 1.3;
  color: ${(props) => props.theme.palette.common.black};
`;

const CONTENT_COLORS_MAP = {
  blue: 'info',
  green: 'success',
  orange: 'warning'
} as const;

interface ContentProps {
  color: keyof typeof CONTENT_COLORS_MAP;
}

export const Content = styled('p')<ContentProps>(({ theme, color }) => ({
  fontSize: '1.5rem',
  lineHeight: 1.3,
  color: theme.palette[CONTENT_COLORS_MAP[color]].main
}));

export const BoxContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem 1rem;
  border: solid 1px ${themeColors.grayGoose[100]};
  border-radius: 8px;
  font-weight: bold;
  width: 100%;
`;

export const BoxContent = styled('p')<ContentProps>(({ theme, color }) => ({
  fontSize: '1.25rem',
  lineHeight: 1.3,
  color: theme.palette[CONTENT_COLORS_MAP[color]].main
}));
