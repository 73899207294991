import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { GetSalesBranchsResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';
import {
  LineChartData,
  LineChartDataValue
} from '../../../common/analytics/LineChart';

const mapper = (res: AxiosResponse<GetSalesBranchsResponseDto[]>) => {
  const { data } = res;
  const lineChart: LineChartData[] = [];
  let lineChartItem: LineChartDataValue[] = [];
  let branch: string;

  const filteredPeriod = data.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.branch === value.branch)
  );

  filteredPeriod.forEach((filteredPeriodItem) => {
    const selectedPeriod = data.filter(
      (itemSalesMonth) => itemSalesMonth.branch === filteredPeriodItem.branch
    );

    selectedPeriod.forEach((selectedMonthItem) => {
      const month = Number(selectedMonthItem.month);
      const year = Number(selectedMonthItem.year);
      branch = selectedMonthItem.branch;

      lineChartItem.push({
        value: selectedMonthItem.sales,
        date: {
          month,
          year
        }
      });
    });

    lineChart.push({
      name: branch,
      data: lineChartItem
    });

    lineChartItem = [];
    branch = '';
  });

  return lineChart as unknown as Promise<LineChartData[]>;
};

// eslint-disable-next-line
export const listMallBranchSales = <T>(
  config: AxiosRequestConfig
): Promise<LineChartData[]> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));
  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default listMallBranchSales;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
