import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { GetTotalSalesAndMarketShareResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';

type TotalSalesAndMarketShare = {
  marketShareSegment: number;
  marketShareFlooring: number;
  salesSelected: number;
};

const mapper = (res: AxiosResponse<GetTotalSalesAndMarketShareResponseDto>) => {
  const { data } = res;
  const result = {
    marketShareSegment: data.marketShareSegment,
    marketShareFlooring: data.marketShareFlooring,
    salesSelected: data.salesSelected
  };

  return result as unknown as Promise<TotalSalesAndMarketShare>;
};

// eslint-disable-next-line
export const getTotalSalesAndMarketShareMutator = <T>(
  config: AxiosRequestConfig
): Promise<TotalSalesAndMarketShare> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getTotalSalesAndMarketShareMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
