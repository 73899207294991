import { styled, TableCell, TableHead } from '@mui/material';

export const StyledTableHead = styled(TableHead)`
  background: ${(props) => props.theme.palette.background.default};
`;

export const StyledTableCell = styled(TableCell)`
  color: ${(props) => props.theme.palette.common.black};
  text-transform: capitalize;
  padding: 16px;
  line-height: initial;
`;

export const StyledTableCellContent = styled('span')`
  display: block;
  margin: -16px;
  padding: 16px;
  border-right: 1px solid #f2f5f9;
`;
