import { createContext, useMemo } from 'react';
import { useDashboardMonthly } from '../hooks/useDashboardMonthly';

export interface MonthPeriodType {
  since: string;
  to: string;
}

interface StoreViewMonthlyState {
  period: MonthPeriodType;
  setPeriod: (value: MonthPeriodType) => void;
}

const StoreViewMonthlyContext = createContext<StoreViewMonthlyState>({
  period: { since: '', to: '' },
  setPeriod: () => null
});

interface StoreViewMonthlyProviderProps {
  children: React.ReactNode;
}

export function StoreViewMonthlyProvider({
  children
}: StoreViewMonthlyProviderProps) {
  const { period, setPeriod } = useDashboardMonthly();

  const providerValue = useMemo(
    () => ({
      period,
      setPeriod
    }),
    [period]
  );

  return (
    <StoreViewMonthlyContext.Provider value={providerValue}>
      {children}
    </StoreViewMonthlyContext.Provider>
  );
}

export default StoreViewMonthlyContext;
