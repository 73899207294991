import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { GetSalesBranchResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';
import { DonutChartData } from '../../../common/analytics/DonutChart';

const mapper = (res: AxiosResponse<GetSalesBranchResponseDto>) => {
  const { data } = res;

  return [
    {
      name: 'Marca',
      value: Number(data.salesBrand ? data.salesBrand.toFixed(2) : 0)
    },
    {
      name: 'Ramo',
      value: Number(data.salesBranch ? data.salesBranch.toFixed(2) : 0)
    }
  ] as unknown as Promise<DonutChartData[]>;
};

// eslint-disable-next-line
export const getSalesBranchMutator = <T>(
  config: AxiosRequestConfig
): Promise<DonutChartData[]> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getSalesBranchMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
