import {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useReducer
} from 'react';
import { useMsal } from '@azure/msal-react';
import { AccountInfo } from '@azure/msal-browser';
import { useNavigate } from 'react-router-dom';
import { Unsubscribe } from 'firebase/auth';
import AuthService from '../services/firebase';
import { User } from '../model/user';
import { HTTPService } from '../services/shared/axios';
import { Routes } from '../routes/Routes';
import { authReducer } from '../reducers/auth/reducer';
import {
  addPermissionsAction,
  initializeAction,
  signInAction,
  signOutAction
} from '../reducers/auth/action';
import { useGetPermissions } from '../api';
import { envs } from '../configs/envs';

export enum AuthMethod {
  FIREBASE = 'firebase',
  AZURE_AD = 'azureAd'
}

interface SignInParams {
  email: string;
  password?: string;
  authMethod: AuthMethod;
}

export interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

const AUTH_METHOD_KEY = 'authMethod';

interface AuthContextValue extends AuthState {
  signOut: () => Promise<void>;
  signIn: (props: SignInParams) => Promise<boolean | void>;
  recoverPassword: (email: string) => Promise<void>;
  verifyEmailExists: (email: string) => Promise<boolean>;
  resetPassword: (code: string, newPassword: string) => Promise<void>;
  checkPasswordCode: (code: string) => Promise<boolean>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const initialState: AuthState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null
};

const AuthContext = createContext<AuthContextValue>({
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  signOut: () => Promise.resolve(),
  signIn: () => Promise.resolve(false),
  recoverPassword: () => Promise.resolve(),
  verifyEmailExists: () => Promise.resolve(false),
  resetPassword: () => Promise.resolve(),
  checkPasswordCode: () => Promise.resolve(false)
});

export function AuthProvider(props: AuthProviderProps) {
  const { children } = props;
  const [state, dispatch] = useReducer(authReducer, initialState);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const whiteList: Routes[] = [
    Routes.SignIn,
    Routes.RecoverPassword,
    Routes.ResetPassword
  ];

  useGetPermissions({
    query: {
      enabled: state.isAuthenticated,
      onSuccess: async (payload) => {
        window.localStorage.setItem('auth_token', payload.token);
        dispatch(await addPermissionsAction(state.user as User, payload.token));
      }
    }
  });

  const setEmailInLocalStorage = (email: string): void => {
    window.localStorage.setItem('userEmail', email);
  };

  const getAuthMethodFromLocalStorage = (): string => {
    const authMethod = window.localStorage.getItem(AUTH_METHOD_KEY);
    return authMethod ?? '';
  };

  const setAuthMethodFromLocalStorage = (authMethod: AuthMethod): void => {
    window.localStorage.setItem(AUTH_METHOD_KEY, authMethod);
  };

  const removeAuthMethodFromLocalStorage = (): void => {
    window.localStorage.removeItem(AUTH_METHOD_KEY);
  };

  const azureAdSignIn = async () => {
    setAuthMethodFromLocalStorage(AuthMethod.AZURE_AD);

    await instance.loginRedirect();
  };

  const azureAdSignOut = async () => {
    await instance.logout({
      postLogoutRedirectUri: `${envs.get('REACT_APP_WEBSITE_URL')}/sign-in`
    });
  };

  const firebaseSignIn = async (
    email: string,
    password: string
  ): Promise<void> => {
    setAuthMethodFromLocalStorage(AuthMethod.FIREBASE);

    const userCredentials = await AuthService.signIn(email, password);

    if (userCredentials) {
      const user: User = {
        id: userCredentials.uid,
        displayName: userCredentials.providerData[0].displayName ?? '',
        email: userCredentials.providerData[0].email ?? '',
        photoURL: userCredentials.providerData[0].photoURL ?? ''
      };

      userCredentials.getIdToken().then(async (authToken) => {
        HTTPService.defaults.headers.common.authorization = `Bearer ${authToken}`;
        dispatch(await signInAction(user));
      });
    }
  };

  // eslint-disable-next-line consistent-return
  const signIn = async ({
    email,
    password,
    authMethod
  }: SignInParams): Promise<boolean | void> => {
    setEmailInLocalStorage(email);

    if (authMethod === AuthMethod.FIREBASE) {
      await firebaseSignIn(email, password!);
    }

    if (authMethod === AuthMethod.AZURE_AD) {
      await azureAdSignIn();
    }

    return true;
  };

  const signOut = async (): Promise<void> => {
    const currentPathName = window.location.pathname;
    const authMethod = getAuthMethodFromLocalStorage();

    if (whiteList.includes(currentPathName as Routes)) {
      if (state.isAuthenticated) {
        navigate(Routes.Home);
      }

      dispatch(initializeAction());
      return;
    }

    dispatch(signOutAction());
    removeAuthMethodFromLocalStorage();

    if (authMethod === AuthMethod.FIREBASE) {
      await AuthService.signOut();
    }

    if (authMethod === AuthMethod.AZURE_AD) {
      await azureAdSignOut();
    }

    if (!whiteList.includes(currentPathName as Routes)) {
      navigate(Routes.SignIn);
    }
  };

  const getAccessTokenAzureAd = (currentAccounts: AccountInfo) => {
    const currentPathName = window.location.pathname;

    instance
      .acquireTokenSilent({
        scopes: [
          'openid',
          'profile',
          'email',
          `api://${process.env.REACT_APP_AZURE_AD_INTEGRATION_API_ID}/Integration`
        ],
        account: currentAccounts,
        forceRefresh: false
      })
      .then(async (token) => {
        const user: User = {
          id: currentAccounts.homeAccountId ?? '',
          displayName: currentAccounts.name ?? '',
          email: currentAccounts.username ?? '',
          photoURL: ''
        };

        HTTPService.defaults.headers.common.authorization = `Bearer ${token.accessToken}`;

        if (!state.isAuthenticated) {
          dispatch(await signInAction(user));
          if (currentPathName === Routes.SignIn) {
            navigate(Routes.Home);
          }
        } else if (!state.isInitialized) {
          dispatch(initializeAction(user));
          if (currentPathName === Routes.SignIn) {
            navigate(Routes.Home);
          }
        }
      })
      .catch(() => {
        instance
          .acquireTokenRedirect({
            scopes: [
              'openid',
              'profile',
              'email',
              `api://${process.env.REACT_APP_AZURE_AD_INTEGRATION_API_ID}/Integration`
            ],
            account: currentAccounts
          })
          .catch(() => {
            signOut();
          });
      });
  };

  const handleFirebaseAuth = async () => {
    let authObserverUnsubscribe: Unsubscribe = () => undefined;
    const currentPathName = window.location.pathname;
    try {
      authObserverUnsubscribe = AuthService.onAuthStateChanged(async (user) => {
        if (user) {
          const authToken = await user.getIdToken();
          HTTPService.defaults.headers.common.authorization = `Bearer ${authToken}`;

          const userData = new User({
            id: user?.uid,
            email: user?.email,
            photoURL: user?.photoURL,
            displayName: user?.displayName
          });

          if (!state.isAuthenticated) {
            dispatch(await signInAction(userData));
          }
          if (!state.isInitialized) {
            dispatch(initializeAction(userData));

            if (currentPathName === Routes.SignIn) {
              navigate(Routes.Home);
            }
          }
        } else {
          signOut();
        }
      });
    } catch (error) {
      signOut();
    }

    // eslint-disable-next-line consistent-return
    return authObserverUnsubscribe();
  };

  const handleAzureAdAuth = () => {
    instance
      .handleRedirectPromise()
      .then((result) => {
        const isRequestToSignIn = result !== null;
        if (isRequestToSignIn) {
          const { account } = result;

          if (account) {
            getAccessTokenAzureAd(account);
          }
        } else {
          const currentAccounts = instance.getAllAccounts();

          const noAccountLoggedIn =
            !currentAccounts || currentAccounts.length < 1;

          const hasAccountLoggedIn = currentAccounts.length === 1;

          if (noAccountLoggedIn) {
            signOut();
          } else if (hasAccountLoggedIn) {
            getAccessTokenAzureAd(currentAccounts[0]);
          }
        }
      })
      .catch(() => {
        signOut();
      });
  };

  const recoverPassword = async (email: string): Promise<void> => {
    await AuthService.recoverPassword(email);
  };

  const verifyEmailExists = async (email: string): Promise<boolean> => {
    const signInMethods = await AuthService.fetchSignInMethods(email);
    return signInMethods.includes('password');
  };

  const checkPasswordCode = async (email: string): Promise<boolean> => {
    return AuthService.checkPasswordCode(email);
  };

  const resetPassword = async (
    code: string,
    newPassword: string
  ): Promise<void> => {
    await AuthService.updatePassword(code, newPassword);
  };

  useEffect(() => {
    const authMethod = getAuthMethodFromLocalStorage();

    if (!authMethod) {
      dispatch(signOutAction());
      return;
    }

    if (authMethod === AuthMethod.FIREBASE) {
      handleFirebaseAuth();
    }

    if (authMethod === AuthMethod.AZURE_AD) {
      handleAzureAdAuth();
    }
  }, []);

  const providerValue = useMemo(
    () => ({
      ...state,
      signOut,
      signIn,
      recoverPassword,
      verifyEmailExists,
      resetPassword,
      checkPasswordCode
    }),
    [state]
  );

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
