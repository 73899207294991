import axios, { Axios } from 'axios';

const HTTPServiceFactory = (): Axios => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BFF_HOST,
    timeout: 30000,
    headers: {
      'BrMalls-Subscription-Key-Backend': String(
        process.env.REACT_APP_BFF_API_KEY
      ),
      'Content-Type': 'application/json'
    }
  });

  return instance;
};

export const HTTPService = HTTPServiceFactory();
