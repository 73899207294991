import { useEffect, useState } from 'react';
import { HelpOutline } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { formatMoneyToBRL } from '../../../helpers/money';
import { formatPercentage } from '../../../helpers/percentage';
import {
  BoxContainer,
  BoxContent,
  CardContainer,
  Content,
  Header,
  Title
} from './styles';
import { Tooltip } from '../../../components/Tooltip';

type BigNumberProps = {
  title: string;
  contentValue?: number;
  color?: 'blue' | 'green' | 'orange';
  // eslint-disable-next-line
  isRanking?: boolean;
  isPercentage?: boolean;
  isLoading?: boolean;
  dataTestid?: string;
  helpText?: string;
};

function CardBigNumber({
  title,
  contentValue = 0,
  color = 'green',
  isPercentage = false,
  isRanking = false,
  isLoading = false,
  dataTestid = '',
  helpText = ''
}: BigNumberProps) {
  const [content, setContent] = useState('');

  useEffect(() => {
    if (isPercentage) {
      setContent(formatPercentage(contentValue));
    } else if (isRanking) {
      setContent(`#${contentValue}`);
    } else {
      setContent(formatMoneyToBRL(contentValue));
    }
  }, [contentValue]);

  return (
    <CardContainer>
      <Header>
        <Title>{title}</Title>
        {helpText && (
          <Tooltip title={helpText} arrow>
            <HelpOutline fontSize="small" />
          </Tooltip>
        )}
      </Header>
      <Content color={color} data-testid={dataTestid}>
        {isLoading ? (
          <Skeleton variant="rectangular" animation="wave" width="100%" />
        ) : (
          content
        )}
      </Content>
    </CardContainer>
  );
}

function BoxBigNumber({
  title,
  contentValue = 0,
  color = 'green',
  isPercentage = false,
  isRanking = false,
  isLoading = false,
  dataTestid = '',
  helpText = ''
}: BigNumberProps) {
  const [content, setContent] = useState('');

  useEffect(() => {
    if (isPercentage) {
      setContent(formatPercentage(contentValue));
    } else if (isRanking) {
      setContent(`#${contentValue}`);
    } else {
      setContent(formatMoneyToBRL(contentValue));
    }
  }, [contentValue]);

  return (
    <BoxContainer>
      <Header>
        <p>{title}</p>
        {helpText && (
          <Tooltip title={helpText} arrow>
            <HelpOutline fontSize="small" />
          </Tooltip>
        )}
      </Header>
      <BoxContent color={color} data-testid={dataTestid}>
        {isLoading ? (
          <Skeleton variant="rectangular" animation="wave" width="100%" />
        ) : (
          content
        )}
      </BoxContent>
    </BoxContainer>
  );
}

export const BigNumber = {
  Card: CardBigNumber,
  Box: BoxBigNumber
};
