import { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { Grid, Skeleton, useTheme } from '@mui/material';
import { builChartOptions } from './config';
import { ChartContainer } from '../shared/ChartBase';
import { themeColors } from '../../../theme/schemes/PureLightTheme';
import { formatMoneyToBRL } from '../../../helpers/money';

const sizeDict = {
  small: {
    containerWidth: '346px',
    containerHeight: '370px',
    chartWidth: 346
  },
  large: {
    containerWidth: '692px',
    containerHeight: '749px',
    chartWidth: 692
  }
};

export type DonutChartData = {
  name: string;
  value: number;
};

interface DonutChartProps {
  title: string;
  data: DonutChartData[];
  size?: keyof typeof sizeDict;
  monoColor?: {
    enable: boolean;
    graphColor: keyof typeof themeColors.dashboard;
  };
  isLoading?: boolean;
  helpText?: string;
}

export function DonutChart({
  title,
  data,
  monoColor = { enable: false, graphColor: 'blue' },
  size = 'small',
  isLoading = false,
  helpText
}: DonutChartProps) {
  const theme = useTheme();

  const labels = useMemo(() => data.map((record) => record.name), [data]);
  const dataSerie = useMemo(() => data.map((record) => record.value), [data]);

  const opts = useMemo(
    () =>
      builChartOptions({
        colors: Object.values(theme.colors.dashboard),
        labels,
        monoColor,
        tooltip: {
          enabled: true,
          y: {
            formatter: (val: number) => formatMoneyToBRL(Number(val))
          }
        }
      }),
    [Object.values(theme.colors.dashboard), labels]
  );

  const sizes = sizeDict[size];

  return (
    <ChartContainer
      height={sizes.containerHeight}
      title={title}
      helpText={helpText}
    >
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={284}
        />
      ) : (
        <Grid
          container
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Chart
              series={dataSerie}
              type="donut"
              options={opts}
              width={sizes.chartWidth}
            />
          </Grid>
        </Grid>
      )}
    </ChartContainer>
  );
}
