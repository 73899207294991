import { Stack } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { DatePicker } from '../../../../../../../components/DatePicker';
import { useDashboardDailyContext } from '../../hooks/useDashboardDailyContext';

const RangeDatepicker = () => {
  const { setPeriod, period } = useDashboardDailyContext();
  const [selectedPeriod, setSelectedPeriod] = useState([
    new Date(period.since),
    new Date(period.to)
  ]);

  const handleDayChange = (periodSelected: Date[]) => {
    if (periodSelected.length > 1) {
      const initialPeriod = periodSelected[0];
      const finalPeriod = periodSelected[1];

      setSelectedPeriod([initialPeriod, finalPeriod]);
      setPeriod({
        since: format(initialPeriod, 'yyyy-MM-dd'),
        to: format(finalPeriod, 'yyyy-MM-dd')
      });
    }
  };

  return (
    <Stack direction="row" alignContent="center" alignItems="center" gap={1}>
      <strong>Período</strong>
      <DatePicker
        range
        rangeHover
        format="DD/MM/YYYY"
        minDate="2019/01/01"
        value={selectedPeriod}
        onChange={handleDayChange}
      />
    </Stack>
  );
};

export { RangeDatepicker };
