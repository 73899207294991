import { Stack } from '@mui/material';
import { Dashboard } from '../../..';
import { Loading } from '../../../../../components/Loading';
import { useDashboard } from '../../../hooks/useDashboard';
import { BranchSales } from './components/BranchSales';
import { OverviewBigNumbers } from './components/OverviewBigNumbers';
import { RangeDatepicker } from './components/RangeDatepicker';
import { SalesHighLights } from './components/SalesHighLights';
import { SalesSegment } from './components/SalesSegment';
import { SalesSegmentAnnual } from './components/SalesSegmentAnnual';
import { StoreViewShoppingProvider } from './context/storeViewShoppingContext';

export function StoreViewShopping() {
  const { selectedStore, isLoading, storeDetails } = useDashboard();

  if (isLoading) return <Loading />;

  return (
    <>
      <Dashboard
        items={[
          `Marca ${storeDetails?.brand ?? ''}`,
          `Segmento ${storeDetails?.segment ?? ''}`,
          `Shopping ${storeDetails?.shopping ?? ''}`
        ]}
      />

      <Stack direction="column" gap={2}>
        {selectedStore.length ? (
          <>
            <OverviewBigNumbers />
            <StoreViewShoppingProvider>
              <RangeDatepicker />
              <SalesHighLights />
              <BranchSales />
              <SalesSegment />
            </StoreViewShoppingProvider>
            <SalesSegmentAnnual />
          </>
        ) : (
          <Stack justifyContent="center" alignItems="center" marginTop={6}>
            <h2>Selecione uma loja para continuar</h2>
          </Stack>
        )}
      </Stack>
    </>
  );
}
