import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { GetMallsResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';

export type Malls = {
  id: number;
  name: string;
};

const mapper = (res: AxiosResponse<GetMallsResponseDto[]>) => {
  const { data } = res;

  return data.map((item) => ({
    id: item.mallCode,
    name: item.mallName
  })) as unknown as Promise<Malls[]>;
};

// eslint-disable-next-line
export const getMallsMutator = <T>(
  config: AxiosRequestConfig
): Promise<Malls[]> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getMallsMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
