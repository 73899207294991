import { Paper, styled } from '@mui/material';

export const ChartContainer = styled(Paper)`
  width: 100%;
  display: flex;
  flex-direction: column;

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    width: 100%;
    padding: 1rem 0 0 1rem;

    p {
      line-height: 2;
    }

    div {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
`;

export const InfoSection = styled('section')`
  display: flex;
  gap: 0.5rem;

  > div {
    width: 100%;
    border-bottom: 3px solid ${(props) => props.theme.colors.success.main};

    &.info-section-brand {
      width: 50%;
    }

    &.info-section-segment {
      border-color: ${(props) => props.theme.colors.warning.main};
    }

    &.info-section-flooring {
      border-color: ${(props) => props.theme.colors.info.main};
    }
  }

  p {
    margin: 1rem 0 1rem 0;
    font-weight: bold;
  }
`;

export const ChartContent = styled('div')`
  display: flex;
  padding: 0.5rem 1rem 1rem 1rem;
  gap: 0.5rem;
  width: 100%;
`;
