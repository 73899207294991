import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { HTTPService } from '../../../services/shared/axios';
import {
  LineChartData,
  LineChartDataValue
} from '../../../common/analytics/LineChart';
import { GetSQMSalesDailyResponseDto } from '../../model';

export type SquareMeterSalesComparative = {
  day: string;
  month: string;
  brand: number;
  brandM2: number;
  segment: number;
  segmentM2: number;
  branch: number;
  branchM2: number;
  mall: number;
  mallM2: number;
};

type GetSQMSalesDaily = {
  squareMeterSales: LineChartData[];
  squareMeterSalesComparative: SquareMeterSalesComparative[];
};

const mapValueLineChartDataType = (
  value: number,
  month: number,
  year: number,
  day?: number
) => ({
  value: value ?? 0,
  date: {
    day,
    month,
    year
  }
});

const mapper = (res: AxiosResponse<GetSQMSalesDailyResponseDto[]>) => {
  const { data } = res;
  const salesBrand: LineChartDataValue[] = [];
  const salesBranch: LineChartDataValue[] = [];
  const salesSegment: LineChartDataValue[] = [];
  const salesCompative: SquareMeterSalesComparative[] = [];

  data?.forEach((item) => {
    const dayData = Number(item.day);
    const monthData = Number(item.month);
    const yearData = Number(item.year);

    salesBrand.push(
      mapValueLineChartDataType(item.brand.sales, monthData, yearData, dayData)
    );
    salesBranch.push(
      mapValueLineChartDataType(item.branch.sales, monthData, yearData, dayData)
    );
    salesSegment.push(
      mapValueLineChartDataType(
        item.segment.sales,
        monthData,
        yearData,
        dayData
      )
    );

    const formattedDay = format(
      new Date(yearData, monthData - 1, dayData),
      'dd/MM',
      {
        locale: ptBR
      }
    );

    salesCompative.push({
      day: String(dayData),
      month: formattedDay.charAt(0).toUpperCase() + formattedDay.slice(1),
      brand: item.brand.sales,
      brandM2: item.brand.salesSqm,
      branch: item.branch.sales,
      branchM2: item.branch.salesSqm,
      segment: item.segment.sales,
      segmentM2: item.segment.salesSqm,
      mall: item.shopping.sales,
      mallM2: item.shopping.salesSqm
    });
  });

  const formattedDataSquareMeterSales: GetSQMSalesDaily['squareMeterSales'] = [
    {
      name: 'Vendas/m²',
      data: salesBrand
    },
    {
      name: 'Vendas/m² Segmento',
      data: salesSegment
    },
    {
      name: 'Vendas/m² Ramo',
      data: salesBranch
    }
  ];

  return {
    squareMeterSales: formattedDataSquareMeterSales,
    squareMeterSalesComparative: salesCompative
  } as unknown as Promise<GetSQMSalesDaily>;
};

// eslint-disable-next-line
export const getSQMSalesDailyMutator = <T>(
  config: AxiosRequestConfig
): Promise<GetSQMSalesDaily> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getSQMSalesDailyMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
