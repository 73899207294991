import { Stack, Box } from '@mui/material';
import { useMemo, useState } from 'react';
import {
  useListMallBranches,
  useListMallBranchSales
} from '../../../../../../api';

import {
  CheckboxList,
  ListCheckboxListOnSelectProps
} from '../../../../../../common/analytics/CheckboxList';
import { LineChart } from '../../../../../../common/analytics/LineChart';
import { useDashboard } from '../../../../hooks/useDashboard';
import { useDashboardShoppingContext } from '../hooks/useDashboardShoppingContext';

const BranchSales = () => {
  const { selectedStore } = useDashboard();
  const { period } = useDashboardShoppingContext();
  const [branches, setBranches] = useState<string[]>([]);
  const [quantityDataUpdates, setQuantityDataUpdates] = useState(1);
  const { data: branchMallData, isLoading: isLoadingSegmentMall } =
    useListMallBranches(selectedStore, {
      query: {
        enabled: !!selectedStore
      }
    });
  const { data: mallBranchSalesData, isLoading: isLoadingMallBranchSales } =
    useListMallBranchSales(
      selectedStore,
      {
        'period.since': period.since,
        'period.to': period.to,
        branchs: branches.join(',')
      },
      {
        query: {
          enabled:
            !!selectedStore &&
            !!period.since &&
            !!period.to &&
            !!branches.length
        }
      }
    );

  const branchMallList = useMemo(() => branchMallData, [branchMallData]);
  const salesBranchList = useMemo(() => {
    setQuantityDataUpdates((prev) => prev + 1);
    return branches.length ? mallBranchSalesData : [];
  }, [branches, mallBranchSalesData]);

  const handleOnSelectBranch = ({
    checkboxes
  }: ListCheckboxListOnSelectProps) => {
    setBranches(
      checkboxes
        .filter((checkboxItem) => checkboxItem.checked)
        .map((checkboxItem) => encodeURIComponent(checkboxItem.name))
    );
  };

  return (
    <Stack direction="row" gap={1} alignContent="space-between">
      <LineChart
        key={quantityDataUpdates}
        title="VENDAS POR RAMO NO PERÍODO SELECIONADO"
        isLoading={isLoadingMallBranchSales}
        dataSerie={salesBranchList}
        helpText="Somatório das vendas totais dos ramos (categorias) das marcas do Shopping no período de datas selecionado."
      />
      <Box width={687}>
        <CheckboxList.Card title="RAMOS" isLoading={isLoadingSegmentMall}>
          <CheckboxList.List
            onSelect={handleOnSelectBranch}
            data={branchMallList}
          />
        </CheckboxList.Card>
      </Box>
    </Stack>
  );
};

export { BranchSales };
