/**
 * Generated by orval v6.13.1 🍺
 * Do not edit manually.
 * BACKEND FOR FRONTEND
 * This api is responsible to forward the request to the correct api
 * OpenAPI spec version: 0.0.1
 */

export type BenefitTypeOfExclusivity =
  (typeof BenefitTypeOfExclusivity)[keyof typeof BenefitTypeOfExclusivity];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BenefitTypeOfExclusivity = {
  EXCLUSIVE: 'EXCLUSIVE',
  LOCKABLE: 'LOCKABLE',
  ALL: 'ALL'
} as const;
