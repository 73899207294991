import {
  Pagination,
  Paper,
  Table as MuiTable,
  TableContainer
} from '@mui/material';
import { ReactNode } from 'react';
import {
  HeaderGroup,
  Row,
  TableBodyPropGetter,
  TableBodyProps as ReactTableTableBodyProps,
  TablePropGetter,
  TableProps as ReactTableProps
} from 'react-table';
import { DEFAULT_PAGE_LIMIT } from '../../../../constants/datatable';
import { Body } from '../Body';
import { Footer } from '../Footer';
import { Header } from '../Header';

export type TableRootProps<T extends object> = {
  children: ReactNode;
  getTableProps: (propGetter?: TablePropGetter<T>) => ReactTableProps;
  onChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
  totalRecords?: number;
  page?: number;
  showPagination?: boolean;
};

function TableRoot<T extends object>({
  getTableProps,
  children,
  onChange,
  page = 1,
  totalRecords,
  showPagination = true
}: TableRootProps<T>) {
  const paginationCount = totalRecords
    ? Math.floor(Math.ceil(totalRecords / DEFAULT_PAGE_LIMIT))
    : 0;

  return (
    <Paper>
      <TableContainer {...getTableProps()}>
        <MuiTable stickyHeader>{children}</MuiTable>
        {showPagination && (
          <Pagination
            color="primary"
            onChange={onChange}
            page={page}
            count={paginationCount}
            shape="rounded"
            size="large"
            sx={{
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              margin: '10px 0px'
            }}
          />
        )}
      </TableContainer>
    </Paper>
  );
}

export interface TableHeaderProps<T extends object> {
  headerGroups: HeaderGroup<T>[];
}

function TableHeader<T extends object>({ headerGroups }: TableHeaderProps<T>) {
  return <Header headerGroups={headerGroups} />;
}

export interface TableBodyProps<T extends object> {
  getTableBodyProps: (
    propGetter?: TableBodyPropGetter<T>
  ) => ReactTableTableBodyProps;
  rows: Row<T>[];
  prepareRow: (row: Row<T>) => void;
  isLoading: boolean;
  colSpan?: number;
}

function TableBody<T extends object>({
  rows,
  getTableBodyProps,
  prepareRow,
  isLoading,
  colSpan = 0
}: TableBodyProps<T>) {
  return (
    <Body
      getTableBodyProps={getTableBodyProps}
      rows={rows}
      colSpan={colSpan}
      prepareRow={prepareRow}
      isLoading={isLoading}
    />
  );
}

export interface TableFooterProps<T extends object> {
  footerGroups: HeaderGroup<T>[];
}

function TableFooter<T extends object>({ footerGroups }: TableFooterProps<T>) {
  return <Footer footerGroups={footerGroups} />;
}

export const Table = {
  Root: TableRoot,
  Header: TableHeader,
  Body: TableBody,
  Footer: TableFooter
};
