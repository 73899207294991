export const ucFirst = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const zeroPad = (value: number | string, places = 2) =>
  String(value).padStart(places, '0');

export const containsSpecialChars = (password: string): boolean => {
  const specialChar = /[`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/g;
  return specialChar.test(password);
};

export const containsUppercase = (password: string): boolean => {
  return /[A-Z]/.test(password);
};

export const containsLowercase = (password: string): boolean => {
  return /[a-z]/.test(password);
};

export const containsNumber = (password: string): boolean => {
  return /[\d]/.test(password);
};

export const greaterThanFourteenChars = (password: string): boolean => {
  return password.length >= 14;
};

export const stringMatches = (string1: string, string2: string): boolean =>
  string1 === string2;

export const validatePasswordStrength = (
  password: string | undefined
): boolean => {
  if (!password) return false;
  const hasUppercase = containsUppercase(password);
  const hasLowercase = containsLowercase(password);
  const hasNumber = containsNumber(password);
  const isGreaterThanFourteen = greaterThanFourteenChars(password);
  const hasSpecialChar = containsSpecialChars(password);
  if (
    hasUppercase &&
    hasLowercase &&
    hasNumber &&
    isGreaterThanFourteen &&
    hasSpecialChar
  ) {
    return true;
  }
  return false;
};

export default {
  ucFirst,
  zeroPad,
  validatePasswordStrength,
  containsSpecialChars,
  containsUppercase,
  containsLowercase,
  containsNumber,
  stringMatches,
  greaterThanFourteenChars
};
