/**
 * Generated by orval v6.13.1 🍺
 * Do not edit manually.
 * BACKEND FOR FRONTEND
 * This api is responsible to forward the request to the correct api
 * OpenAPI spec version: 0.0.1
 */

export type BenefitChannels =
  (typeof BenefitChannels)[keyof typeof BenefitChannels];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BenefitChannels = {
  MALL: 'MALL',
  MARKETPLACE: 'MARKETPLACE',
  PARKING: 'PARKING',
  RAFFLE: 'RAFFLE',
  PERSONAL_SHOPPER: 'PERSONAL_SHOPPER',
  EXTERNAL_CODE: 'EXTERNAL_CODE',
  FIXED_CODE: 'FIXED_CODE'
} as const;
