import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { AuthProvider } from './contexts/authContext';
import { AzureAdProvider } from './contexts/azureAdContext';
import { DashboardProvider } from './pages/Dashboard/context/dashboardContext';
import { AppRoutes } from './routes';
import { GlobalStyles } from './styles';
import ThemeProvider from './theme/ThemeProvider';
import withAppInsights from './telemetry';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyles />
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <AzureAdProvider>
            <AuthProvider>
              <ThemeProvider>
                <DashboardProvider>
                  <AppRoutes />
                </DashboardProvider>
              </ThemeProvider>
            </AuthProvider>
          </AzureAdProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}
export default withAppInsights(App);
