import { createContext, useMemo, useState } from 'react';
import { useGetStores } from '../../../api';
import useAuth from '../../../hooks/useAuth';

export type StoreDetailsType = {
  flooring: string;
  branch: string;
  segment: string;
  shopping: string;
  brand: string;
};

const defaultStoreDetails = {
  flooring: '',
  branch: '',
  segment: '',
  shopping: '',
  brand: ''
};

interface DashboardState {
  isLoading: boolean;
  handleSelectStore: (value: string) => void;
  handleSelectShopping: (value: string) => void;
  selectedStore: string;
  selectedShopping?: number;
  stores: {
    id: string;
    store: string;
  }[];
  storeDetails: StoreDetailsType;
  setStoreDetails: (value: StoreDetailsType) => void;
}

const DashboardContext = createContext<DashboardState>({
  isLoading: true,
  handleSelectStore: () => null,
  handleSelectShopping: () => null,
  selectedStore: '',
  selectedShopping: undefined,
  stores: [],
  storeDetails: defaultStoreDetails,
  setStoreDetails: () => null
});

interface DashboardProviderProps {
  children: React.ReactNode;
}

export function DashboardProvider({ children }: DashboardProviderProps) {
  const auth = useAuth();
  const { user } = auth;

  const { data: stores, isLoading } = useGetStores({
    query: {
      enabled: !!user && !user.isLoading
    }
  });
  const [selectedStore, setSelectedStore] = useState<string>('');
  const [selectedShopping, setSelectedShopping] = useState<number>();
  const [storeDetails, setStoreDetails] =
    useState<StoreDetailsType>(defaultStoreDetails);

  const handleSelectStore = (value: string) => {
    setSelectedStore(value);
  };

  const handleSelectShopping = (value: string) => {
    setSelectedShopping(Number.parseInt(value, 10));
  };

  const providerValue = useMemo(
    () => ({
      handleSelectStore,
      selectedStore,
      handleSelectShopping,
      selectedShopping,
      stores: stores ?? [],
      isLoading,
      storeDetails,
      setStoreDetails
    }),
    [selectedStore, stores, isLoading, selectedShopping, storeDetails]
  );

  return (
    <DashboardContext.Provider value={providerValue}>
      {children}
    </DashboardContext.Provider>
  );
}

export default DashboardContext;
