import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { GetSegmentSalesAnnualMallResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';
import {
  LineChartData,
  LineChartDataValue
} from '../../../common/analytics/LineChart';

const mapper = (res: AxiosResponse<GetSegmentSalesAnnualMallResponseDto[]>) => {
  const { data } = res;
  const lineChart: LineChartData[] = [];
  let lineChartItem: LineChartDataValue[] = [];

  const uniqueYears = data.reduce(
    (
      acc: GetSegmentSalesAnnualMallResponseDto[],
      current: GetSegmentSalesAnnualMallResponseDto
    ) => {
      const x = acc.find((item) => item.year === current.year);
      if (!x) {
        return acc.concat([current]);
      }
      return acc;
    },
    []
  );

  uniqueYears.forEach((filteredPeriodItem) => {
    const { year } = filteredPeriodItem;

    const maxtMonth = data.reduce(
      (prev, current) =>
        Number(current.month) > prev ? Number(current.month) : prev,
      0
    );
    const minMonth = data.reduce(
      (prev, current) =>
        Number(current.month) < prev ? Number(current.month) : prev,
      maxtMonth
    );

    for (let month = minMonth; month <= maxtMonth; month += 1) {
      const selectedPeriod = data.find(
        (itemSalesMonth) =>
          itemSalesMonth.year === year && Number(itemSalesMonth.month) === month
      );

      lineChartItem.push({
        value: selectedPeriod ? selectedPeriod.sales : 0,
        date: {
          month,
          year: Number(year)
        }
      });
    }

    lineChart.push({
      name: String(year),
      data: lineChartItem
    });

    lineChartItem = [];
  });

  return lineChart as unknown as Promise<LineChartData[]>;
};

// eslint-disable-next-line
export const listSalesSegmentAnnual = <T>(
  config: AxiosRequestConfig
): Promise<LineChartData[]> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));
  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default listSalesSegmentAnnual;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
