import { Paper, styled } from '@mui/material';
import { themeColors } from '../../../../theme/schemes/PureLightTheme';

interface ContainerProps {
  width: string;
  height: string;
}

export const Container = styled(Paper)<ContainerProps>(({ width, height }) => ({
  width,
  height,
  display: 'flex',
  flexDirection: 'column'
}));

export const Header = styled('header')`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  height: 70px;
  width: 100%;
  border-bottom: 1px solid ${themeColors.grayGoose[100]};

  div {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;
