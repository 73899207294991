import { CircularProgress } from '@mui/material';
import { LoadingContainer } from './styles';

interface LoadingProps {
  loadingDescription?: string;
}

export function Loading({ loadingDescription }: LoadingProps) {
  return (
    <LoadingContainer>
      <>
        <CircularProgress />
        {loadingDescription && <p>{loadingDescription}</p>}
      </>
    </LoadingContainer>
  );
}

Loading.defaultProps = {
  loadingDescription: ''
};
