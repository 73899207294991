import { useTheme } from '@mui/material';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { SquareMeterSalesComparativeByDayPeriodType } from '../../../../../../../api/analytics/mappers/square-meter-sales.mapper';
import { StyledTableCellContent } from '../../../../../../../components/Common/DataTable/Header/styles';
import { formatMoneyToBRL } from '../../../../../../../helpers/money';

export const datatableHeader =
  (): Column<SquareMeterSalesComparativeByDayPeriodType>[] => {
    const theme = useTheme();

    return useMemo(
      () => [
        {
          id: 'month',
          width: 'auto',
          accessor: 'month',
          Header: 'Dia',
          Footer: 'Total'
        },
        {
          id: 'brand',
          accessor: 'brand',
          Header: () => (
            <StyledTableCellContent
              style={{
                backgroundColor: theme.colors.success.lighter
              }}
            >
              Vendas
            </StyledTableCellContent>
          ),
          Cell: (props) => formatMoneyToBRL(props.value) as any,
          Footer: (props) => {
            const total = useMemo(
              () =>
                props.rows.reduce((sum, row) => {
                  return row.values.brand + sum;
                }, 0),
              [props.rows]
            );

            return formatMoneyToBRL(total) as any;
          }
        },
        {
          id: 'brandM2',
          accessor: 'brandM2',
          Header: () => (
            <StyledTableCellContent
              style={{
                backgroundColor: theme.colors.success.lighter
              }}
            >
              Vendas/m²
            </StyledTableCellContent>
          ),
          Cell: (props) => formatMoneyToBRL(props.value) as any,
          Footer: (props) => {
            const total = useMemo(
              () =>
                props.rows.reduce((sum, row) => {
                  return row.values.brandM2 + sum;
                }, 0),
              [props.rows]
            );

            return formatMoneyToBRL(total) as any;
          }
        },
        {
          id: 'segment',
          accessor: 'segment',
          Header: () => (
            <StyledTableCellContent
              style={{
                backgroundColor: theme.colors.warning.lighter
              }}
            >
              Vendas Segmento
            </StyledTableCellContent>
          ),
          Cell: (props) => formatMoneyToBRL(props.value) as any,
          Footer: (props) => {
            const total = useMemo(
              () =>
                props.rows.reduce((sum, row) => {
                  return row.values.segment + sum;
                }, 0),
              [props.rows]
            );

            return formatMoneyToBRL(total) as any;
          }
        },
        {
          id: 'segmentM2',
          accessor: 'segmentM2',
          Header: () => (
            <StyledTableCellContent
              style={{
                backgroundColor: theme.colors.warning.lighter
              }}
            >
              Vendas/m²
            </StyledTableCellContent>
          ),
          Cell: (props) => formatMoneyToBRL(props.value) as any,
          Footer: (props) => {
            const total = useMemo(
              () =>
                props.rows.reduce((sum, row) => {
                  return row.values.segmentM2 + sum;
                }, 0),
              [props.rows]
            );

            return formatMoneyToBRL(total) as any;
          }
        },
        {
          id: 'branch',
          accessor: 'branch',
          Header: () => (
            <StyledTableCellContent
              style={{
                backgroundColor: theme.colors.warning.lighter
              }}
            >
              Vendas Ramo
            </StyledTableCellContent>
          ),
          Cell: (props) => formatMoneyToBRL(props.value) as any,
          Footer: (props) => {
            const total = useMemo(
              () =>
                props.rows.reduce((sum, row) => {
                  return row.values.branch + sum;
                }, 0),
              [props.rows]
            );

            return formatMoneyToBRL(total) as any;
          }
        },
        {
          id: 'branchM2',
          accessor: 'branchM2',
          Header: () => (
            <StyledTableCellContent
              style={{
                backgroundColor: theme.colors.warning.lighter
              }}
            >
              Vendas/m²
            </StyledTableCellContent>
          ),
          Cell: (props) => formatMoneyToBRL(props.value) as any,
          Footer: (props) => {
            const total = useMemo(
              () =>
                props.rows.reduce((sum, row) => {
                  return row.values.branchM2 + sum;
                }, 0),
              [props.rows]
            );

            return formatMoneyToBRL(total) as any;
          }
        },
        {
          id: 'mall',
          accessor: 'mall',
          Header: () => (
            <StyledTableCellContent
              style={{
                backgroundColor: theme.colors.info.lighter
              }}
            >
              Vendas Shopping
            </StyledTableCellContent>
          ),
          Cell: (props) => formatMoneyToBRL(props.value) as any,
          Footer: (props) => {
            const total = useMemo(
              () =>
                props.rows.reduce((sum, row) => {
                  return row.values.mall + sum;
                }, 0),
              [props.rows]
            );

            return formatMoneyToBRL(total) as any;
          }
        },
        {
          id: 'mallM2',
          accessor: 'mallM2',
          Header: () => (
            <StyledTableCellContent
              style={{
                backgroundColor: theme.colors.info.lighter
              }}
            >
              Vendas/m²
            </StyledTableCellContent>
          ),
          Cell: (props) => formatMoneyToBRL(props.value) as any,
          Footer: (props) => {
            const total = useMemo(
              () =>
                props.rows.reduce((sum, row) => {
                  return row.values.mallM2 + sum;
                }, 0),
              [props.rows]
            );

            return formatMoneyToBRL(total) as any;
          }
        }
      ],
      []
    );
  };
