import { styled, TableCell, tableCellClasses } from '@mui/material';

export const StyledTableCell = styled(TableCell)`
  border-right: 1px solid ${(props) => props.theme.palette.background.default};
  &.${tableCellClasses.head} {
    height: auto;
  }

  &.${tableCellClasses.body} {
    height: auto;
    font-size: 14;
  }
`;

export const ItemsNotFoundContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 70px;

  h1 {
    min-width: 450px;
    font-size: 1.5rem;
  }

  img {
    margin-bottom: 2rem;
  }
`;
