import { lighten } from '@mui/material';
import { ApexOptions } from 'apexcharts';
import { formatMoneyToBRL } from '../../../../helpers/money';
import { formatPercentage } from '../../../../helpers/percentage';
import { themeColors } from '../../../../theme/schemes/PureLightTheme';

const formatterCurrencyLabel = (totalValue: number): string => {
  const totalDividedByMillion = totalValue / 1000000;

  const isLessThatThousand = totalDividedByMillion < 0.001;
  const isMillion = totalDividedByMillion >= 1;

  if (isLessThatThousand) {
    return formatMoneyToBRL(totalValue);
  }

  const currencyUnit = isMillion ? 'MM' : 'M';
  const divider = isMillion ? 1000000 : 1000;
  const total = totalValue / divider;

  const numberFormatted = Number.isInteger(total) ? total : total.toFixed(3);

  return `R$ ${numberFormatted} ${currencyUnit}`;
};

export const builChartOptions = ({
  colors,
  labels,
  monoColor,
  tooltip
}: {
  tooltip?: {
    enabled?: boolean;
    y?: ApexTooltipY | ApexTooltipY[];
  };
  colors: string[];
  labels: string[];
  monoColor: {
    enable: boolean;
    graphColor: keyof typeof themeColors.dashboard;
  };
}): ApexOptions => {
  const graphColor: string[] = monoColor.enable
    ? [
        themeColors.dashboard[monoColor.graphColor],
        lighten(themeColors.dashboard[monoColor.graphColor], 0.3)
      ]
    : colors;

  return {
    chart: {
      type: 'donut'
    },
    noData: {
      align: 'center',
      text: 'Dados indisponíveis no momento',
      verticalAlign: 'middle'
    },
    colors: graphColor,
    labels,
    dataLabels: {
      enabled: false
    },
    tooltip,
    legend: {
      show: true,
      position: 'bottom',
      height: 50,
      formatter: (
        legendName: string,
        opts?: {
          seriesIndex: number;
          w: { globals: { seriesPercent: Array<number[]> } };
        }
      ) => {
        if (!opts) {
          return legendName;
        }

        const [percentege] = opts.w.globals.seriesPercent[opts.seriesIndex];

        return `${legendName} - ${formatPercentage(percentege / 100)}`;
      }
    },
    plotOptions: {
      pie: {
        customScale: 1.1,
        expandOnClick: false,
        donut: {
          size: !monoColor.enable ? '80%' : '50%',
          background: 'transparent',
          labels: {
            show: !monoColor.enable,
            total: {
              show: true,
              label: '',
              formatter: (val: { globals: { series: number[] } }) => {
                if (val.globals.series.length <= 0) {
                  return '';
                }

                const totalValue = val.globals.series.reduce(
                  (acc, current) => acc + current,
                  0
                );

                return formatterCurrencyLabel(totalValue);
              }
            },
            name: {
              show: true,
              offsetY: -20
            },
            value: {
              show: true,
              fontSize: '14px',
              fontFamily: 'Inter',
              fontWeight: 700,
              color: '#000C57',
              offsetY: -10,
              formatter: (val: number | string) => {
                return formatterCurrencyLabel(Number(val));
              }
            }
          }
        }
      }
    }
  };
};
