import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { GetBigNumbersMallResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';

type MallBigNumbers = {
  brandSales: number;
  brandSss: number;
  segmentSales: number;
  segmentSss: number;
  mallSales: number;
  mallSss: number;
};

const mapper = (res: AxiosResponse<GetBigNumbersMallResponseDto>) => {
  const { data } = res;
  const result = {
    brandSales: data.salesAmount.brand,
    brandSss: data.sss.brand,
    segmentSales: data.salesAmount.segment,
    segmentSss: data.sss.segment,
    mallSales: data.salesAmount.mall,
    mallSss: data.sss.mall
  };

  return result as unknown as Promise<MallBigNumbers>;
};

// eslint-disable-next-line
export const listMallBigNumbers = <T>(
  config: AxiosRequestConfig
): Promise<MallBigNumbers> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default listMallBigNumbers;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
