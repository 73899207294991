import { Stack } from '@mui/material';
import { Loading } from '../../../../../components/Loading';
import { useDashboard } from '../../../hooks/useDashboard';
import { OverviewBigNumbers } from './components/OverviewBigNumbers';
import { RangeMonthDatepicker } from './components/RangeMonthDatepicker';
import { SalesAndMarketShare } from './components/SalesAndMarketShare';
import { SegmentSales } from './components/SegmentSales';
import { SquareMeterSales } from './components/SquareMeterSales';
import { StoreSales } from './components/StoreSales';
import { StoreSalesYear } from './components/StoreSalesYear';
import { FlooringSales } from './components/FlooringSales';
import { StoreViewMonthlyProvider } from './context/storeViewMonthlyContext';
import { BranchSales } from './components/BranchSales';
import { BranchSegmentSales } from './components/BranchSegmentSales';
import { BrandsSegmentBranch } from '../BrandsSegmentBranch';
import { Dashboard } from '../../..';

export function StoreViewMonthly() {
  const { selectedStore, isLoading, storeDetails } = useDashboard();

  if (isLoading) return <Loading />;

  return (
    <>
      <Dashboard
        items={[
          `Marca ${storeDetails?.brand ?? ''}`,
          `Segmento ${storeDetails?.segment ?? ''}`,
          `Piso ${storeDetails?.flooring ?? ''}`
        ]}
      />
      <Stack direction="column" gap={2}>
        {selectedStore.length ? (
          <>
            <OverviewBigNumbers />
            <StoreViewMonthlyProvider>
              <RangeMonthDatepicker />
              <SalesAndMarketShare />
              <StoreSales />
              <Stack direction="row" gap={2}>
                <BranchSegmentSales />
                <BranchSales />
                <FlooringSales />
              </Stack>
              <SegmentSales />
              <SquareMeterSales />
              <StoreSalesYear />
            </StoreViewMonthlyProvider>
            <BrandsSegmentBranch />
          </>
        ) : (
          <Stack justifyContent="center" alignItems="center" marginTop={6}>
            <h2>Selecione uma loja para continuar</h2>
          </Stack>
        )}
      </Stack>
    </>
  );
}
