import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import { InsertChartTwoTone } from '@mui/icons-material';
import { format, subMonths } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import { BigNumber } from '../../../../../../common/analytics/BigNumber';
import {
  ChartContainer,
  ChartContent
} from '../../Monthly/components/OverviewBigNumbers/styles';
import { useDashboard } from '../../../../hooks/useDashboard';
import { useListMallBigNumbers } from '../../../../../../api';

const getFormattedMalls = (now: Date) => {
  const currentDate = now;
  const currentMallFormatted = format(currentDate, 'MMMM/yyyy', {
    locale: ptBr
  }).toLocaleUpperCase();
  const previousMall = subMonths(currentDate, 1);
  const previousMallFormatted = format(previousMall, 'MMMM/yyyy', {
    locale: ptBr
  }).toLocaleUpperCase();

  return {
    current: currentMallFormatted,
    previous: previousMallFormatted
  };
};

interface BigNumbersGroupProps {
  children: React.ReactNode;
  title: string;
}

function BigNumbersGroup({ children, title }: BigNumbersGroupProps) {
  return (
    <ChartContainer>
      <header>
        <div>
          <InsertChartTwoTone />
          <p>{title}</p>
        </div>
      </header>
      <ChartContent>{children}</ChartContent>
    </ChartContainer>
  );
}

export function OverviewBigNumbers() {
  const { selectedStore } = useDashboard();
  const currentDate = new Date();
  const previousDate = subMonths(currentDate, 1);

  const { data: currentMallBigNumbersData, isLoading: isLoadingCurrentMall } =
    useListMallBigNumbers(
      selectedStore,
      {
        'period.year': currentDate.getFullYear(),
        'period.month': currentDate.getMonth() + 1
      },
      {
        query: {
          enabled: !!selectedStore
        }
      }
    );

  const { data: previousMallBigNumbersData, isLoading: isLoadingPreviousMall } =
    useListMallBigNumbers(
      selectedStore,
      {
        'period.year': previousDate.getFullYear(),
        'period.month': previousDate.getMonth() + 1
      },
      {
        query: {
          enabled: !!selectedStore
        }
      }
    );

  const currentMallBigNumbers = useMemo(
    () => currentMallBigNumbersData,
    [currentMallBigNumbersData]
  );

  const previousMallBigNumbers = useMemo(
    () => previousMallBigNumbersData,
    [previousMallBigNumbersData]
  );

  const formattedMallTitles = getFormattedMalls(new Date());
  const currentMallTitle = `MÊS ATUAL: ${formattedMallTitles.current}`;
  const previousMallTitle = `MÊS ANTERIOR: ${formattedMallTitles.previous}`;

  return (
    <>
      <Stack>
        <BigNumbersGroup title={currentMallTitle}>
          <BigNumber.Card
            dataTestid="current-bignumber-brand-sales"
            title="Vendas"
            contentValue={currentMallBigNumbers?.brandSales}
            color="green"
            isLoading={isLoadingCurrentMall}
            helpText="Somatório das vendas totais da marca do mês vigente."
          />
          <BigNumber.Card
            dataTestid="current-bignumber-brand-sss"
            title="% Evolução  vendas"
            contentValue={currentMallBigNumbers?.brandSss}
            isPercentage
            color="green"
            isLoading={isLoadingCurrentMall}
            helpText="Variação (%) das vendas totais do Shopping (ano atual versus ano anterior) do mês vigente."
          />
          <BigNumber.Card
            dataTestid="current-bignumber-segment-sales"
            title="Vendas"
            contentValue={currentMallBigNumbers?.segmentSales}
            color="orange"
            isLoading={isLoadingCurrentMall}
            helpText="Somatório das vendas totais do segmento da marca do mês vigente."
          />
          <BigNumber.Card
            dataTestid="current-bignumber-segment-sss"
            title="% Evolução  vendas"
            contentValue={currentMallBigNumbers?.segmentSss}
            isPercentage
            color="orange"
            isLoading={isLoadingCurrentMall}
            helpText="Variação (%) das vendas totais do segmento da marca (ano atual versus ano anterior) do mês vigente."
          />
          <BigNumber.Card
            dataTestid="current-bignumber-mall-sales"
            title="Vendas"
            contentValue={currentMallBigNumbers?.mallSales}
            color="blue"
            isLoading={isLoadingCurrentMall}
            helpText="Variação (%) das vendas totais do Shopping (ano atual versus ano anterior) do mês vigente."
          />
          <BigNumber.Card
            dataTestid="current-bignumber-mall-sss"
            title="% Evolução  vendas"
            contentValue={currentMallBigNumbers?.mallSss}
            isPercentage
            color="blue"
            isLoading={isLoadingCurrentMall}
            helpText="Variação (%) das vendas totais do Shopping (ano atual versus ano anterior) do mês vigente."
          />
        </BigNumbersGroup>
      </Stack>
      <Stack>
        <BigNumbersGroup title={previousMallTitle}>
          <BigNumber.Card
            dataTestid="previous-bignumber-brand-sales"
            title="Vendas"
            contentValue={previousMallBigNumbers?.brandSales}
            color="green"
            isLoading={isLoadingPreviousMall}
            helpText="Somatório das vendas totais da marca do mês anterior."
          />
          <BigNumber.Card
            dataTestid="previous-bignumber-brand-sss"
            title="% Evolução  vendas"
            contentValue={previousMallBigNumbers?.brandSss}
            isPercentage
            color="green"
            isLoading={isLoadingPreviousMall}
            helpText="Variação (%) das vendas totais da marca (ano atual versus ano anterior) do mês anterior."
          />
          <BigNumber.Card
            dataTestid="previous-bignumber-segment-sales"
            title="Vendas"
            contentValue={previousMallBigNumbers?.segmentSales}
            color="orange"
            isLoading={isLoadingPreviousMall}
            helpText="Somatório das vendas totais do segmento da marca do mês anterior."
          />
          <BigNumber.Card
            dataTestid="previous-bignumber-segment-sss"
            title="% Evolução  vendas"
            contentValue={previousMallBigNumbers?.segmentSss}
            isPercentage
            color="orange"
            isLoading={isLoadingPreviousMall}
            helpText="Variação (%) das vendas totais do segmento da marca (ano atual versus ano anterior) do mês anterior."
          />
          <BigNumber.Card
            dataTestid="previous-bignumber-mall-sales"
            title="Vendas"
            contentValue={previousMallBigNumbers?.mallSales}
            color="blue"
            isLoading={isLoadingPreviousMall}
            helpText="Somatório das vendas totais do Shopping do mês anterior."
          />
          <BigNumber.Card
            dataTestid="previous-bignumber-mall-sss"
            title="% Evolução  vendas"
            contentValue={previousMallBigNumbers?.mallSss}
            isPercentage
            color="blue"
            isLoading={isLoadingPreviousMall}
            helpText="Variação (%) das vendas totais do Shopping (ano atual versus ano anterior) do mês anterior."
          />
        </BigNumbersGroup>
      </Stack>
    </>
  );
}
