/**
 * Generated by orval v6.13.1 🍺
 * Do not edit manually.
 * BACKEND FOR FRONTEND
 * This api is responsible to forward the request to the correct api
 * OpenAPI spec version: 0.0.1
 */

export type CouponStatus = (typeof CouponStatus)[keyof typeof CouponStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CouponStatus = {
  USABLE: 'USABLE',
  PROCESSING: 'PROCESSING',
  BURNED: 'BURNED',
  BURNED_SHOW: 'BURNED_SHOW',
  BURNED_MANUALLY: 'BURNED_MANUALLY'
} as const;
