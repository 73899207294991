import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { GetSalesComparativeResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';
import {
  ColumnChartData,
  ColumnChartDataValue
} from '../../../common/analytics/ColumnChart';

const mapper = (res: AxiosResponse<GetSalesComparativeResponseDto[]>) => {
  const { data } = res;
  const salesCurrent: ColumnChartDataValue[] = [];
  const salesLastYear: ColumnChartDataValue[] = [];
  const sss: ColumnChartDataValue[] = [];

  data?.forEach((item) => {
    const month = Number(item.month);
    const year = Number(item.year);

    salesCurrent.push({
      value: item.salesCurrent ?? 0,
      date: {
        month,
        year
      }
    });

    salesLastYear.push({
      value: item.salesLastYear ?? 0,
      date: {
        month,
        year
      }
    });

    sss.push({
      value: item.sss ?? 0,
      date: {
        month,
        year
      }
    });
  });

  const formattedData = [
    {
      title: 'Período Selecionado Ano Anterior',
      data: salesLastYear
    },
    {
      title: 'Período Selecionado',
      data: salesCurrent
    },
    {
      title: 'Variação %',
      data: sss
    }
  ];

  return formattedData as unknown as Promise<ColumnChartData[]>;
};

// eslint-disable-next-line
export const getSalesComparativeMutator = <T>(
  config: AxiosRequestConfig
): Promise<ColumnChartData[]> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getSalesComparativeMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
