import { TableRow } from '@mui/material';
import { HeaderGroup } from 'react-table';
import { StyledTableCell, StyledTableHead } from './styles';

interface HeaderProps<T extends object> {
  headerGroups: HeaderGroup<T>[];
}

const Header = <T extends object>({ headerGroups }: HeaderProps<T>) => (
  <StyledTableHead>
    {headerGroups.map((headerGroup) => (
      <TableRow {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column) => (
          <StyledTableCell {...column.getHeaderProps()} key={column.id}>
            {column.render('Header')}
          </StyledTableCell>
        ))}
      </TableRow>
    ))}
  </StyledTableHead>
);

export { Header };
