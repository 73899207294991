/**
 * Generated by orval v6.13.1 🍺
 * Do not edit manually.
 * BACKEND FOR FRONTEND
 * This api is responsible to forward the request to the correct api
 * OpenAPI spec version: 0.0.1
 */

export type BenefitUserType =
  (typeof BenefitUserType)[keyof typeof BenefitUserType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BenefitUserType = {
  ALL: 'ALL',
  CLIENTS_ONLY: 'CLIENTS_ONLY',
  STOREKEEPERS_ONLY: 'STOREKEEPERS_ONLY'
} as const;
