import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { GetDailySalesResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';
import {
  ColumnChartData,
  ColumnChartDataValue
} from '../../../common/analytics/ColumnChart';

const mapper = (res: AxiosResponse<GetDailySalesResponseDto[]>) => {
  const { data: storeSales } = res;
  const dataResult: ColumnChartData[] = [];
  let dataValueResult: ColumnChartDataValue[] = [];

  const filteredPeriod = storeSales.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.year === value.year)
  );

  filteredPeriod.forEach((filteredPeriodItem) => {
    const selectedPeriod = storeSales.filter(
      (itemSalesMonth) =>
        Number(itemSalesMonth.year) === Number(filteredPeriodItem.year)
    );

    selectedPeriod.forEach((selectedMonthItem) => {
      const day = Number(selectedMonthItem.day);
      const month = Number(selectedMonthItem.month);
      const year = Number(selectedMonthItem.year);

      dataValueResult.push({
        value: selectedMonthItem.sales,
        date: {
          day,
          month,
          year
        }
      });
    });

    dataResult.push({
      title: filteredPeriodItem.year,
      data: dataValueResult
    });

    dataValueResult = [];
  });

  return dataResult as unknown as Promise<ColumnChartData[]>;
};

// eslint-disable-next-line
export const getDailySalesMutator = <T>(
  config: AxiosRequestConfig
): Promise<ColumnChartData[]> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getDailySalesMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
