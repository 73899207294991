import {
  Tooltip as MuiTooltip,
  TooltipProps,
  tooltipClasses,
  styled
} from '@mui/material';

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip
    {...props}
    classes={{ popper: className }}
    componentsProps={{ arrow: { style: { color: '#E8F1FF' } } }}
  />
))(({ theme }) => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#E8F1FF',
      color: theme.palette.common.black,
      textAlign: 'justify',
      boxShadow:
        '0px 9px 16px rgba(159,162,191,.18), 0px 2px 2px rgba(159,162,191,0.32)',
      fontSize: 11
    }
  };
});

export { Tooltip };
