import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { GetBranchSalesDailyResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';
import { DonutChartData } from '../../../common/analytics/DonutChart';

const mapper = (res: AxiosResponse<GetBranchSalesDailyResponseDto[]>) => {
  const { data } = res;
  const result = data.length
    ? data.map((item) => [
        {
          name: 'Marca',
          value: Number(item.sales ? item.sales.toFixed(2) : 0)
        },
        {
          name: 'Ramo',
          value: Number(item.branchSales ? item.branchSales.toFixed(2) : 0)
        }
      ])
    : [];

  return result as unknown as Promise<DonutChartData[]>;
};

// eslint-disable-next-line
export const getBranchSalesDailyMutator = <T>(
  config: AxiosRequestConfig
): Promise<DonutChartData[]> => {
  const params = new Map(Object.entries(config.params));

  params.forEach((value, key) => {
    params.set(key, encodeURIComponent(String(value)));
  });

  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getBranchSalesDailyMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
