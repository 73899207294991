import { Box, ListItemText, Skeleton } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import {
  ListContent,
  ListLegend,
  StyledDivider,
  StyledList,
  StyledListItem
} from './styles';

type ListProps = {
  title: string;
  legend: string;
  isLoading: boolean;
  data: string[];
};

const List = ({ title, legend, isLoading, data }: ListProps) => {
  return isLoading ? (
    <Skeleton
      variant="rectangular"
      animation="wave"
      width="100%"
      height={253}
    />
  ) : (
    <ListContent>
      <ListLegend>{legend}</ListLegend>
      <StyledList>
        <StyledListItem>{title}</StyledListItem>
        <StyledDivider />
        <Box height={204} overflow="scroll">
          {data.map((item) => (
            <StyledListItem key={uuidv4()}>
              <ListItemText primary={item} />
            </StyledListItem>
          ))}
        </Box>
      </StyledList>
    </ListContent>
  );
};

export { List };
