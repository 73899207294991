import { AxiosResponse } from 'axios';
import { QueryFunctionContext } from 'react-query';
import { IntegrationServiceResponse } from '../../../services/bff/integration';
import SegmentSalesIntegrationService from '../../../services/bff/analytics/segment-sales.integration';
import {
  GetSegmentSalesByMonthPeriodType,
  mapSegmentSalesByDayPeriodType,
  mapSegmentSalesByMonthPeriodType,
  GetSegmentSalesByDayPeriodType
} from '../mappers/segment-sales.mapper';

export type GetSegmentSalesByMonthPeriodParams = {
  accessDataKey: string;
  since: string;
  to: string;
};

type GetSegmentSalesDataResponseByMonthPeriod = {
  marketShare: number;
  month: string;
  sales: number;
  salesSegment: number;
  year: string;
};

export type GetSegmentSalesByMonthPeriodResponse = IntegrationServiceResponse<
  GetSegmentSalesDataResponseByMonthPeriod[]
>;

export const getSegmentSalesByMonthPeriod = async (
  queryFunctionContext: QueryFunctionContext
): Promise<AxiosResponse<GetSegmentSalesByMonthPeriodType[]>> => {
  return SegmentSalesIntegrationService.getSegmentStoreSalesByMonthPeriod(
    queryFunctionContext
  ).then((res) => {
    const formattedData = mapSegmentSalesByMonthPeriodType(res);
    return {
      ...res,
      data: formattedData
    } as AxiosResponse<GetSegmentSalesByMonthPeriodType[]>;
  });
};

export type GetSegmentSalesByDayPeriodParams = {
  accessDataKey: string;
  since: string;
  to: string;
};

export type GetSegmentSalesByDayPeriodDataResponse = {
  day: string;
  month: string;
  year: string;
  sales: number;
  salesSegment: number;
  marketShare: number;
};

export type GetSegmentSalesByDayPeriodResponse = IntegrationServiceResponse<
  GetSegmentSalesByDayPeriodDataResponse[]
>;

export const getSegmentSalesByDayPeriod = async (
  queryFunctionContext: QueryFunctionContext
): Promise<AxiosResponse<GetSegmentSalesByDayPeriodType>> => {
  return SegmentSalesIntegrationService.getDailySegmentStoreSalesByDayPeriod(
    queryFunctionContext
  ).then((res) => {
    const formattedData = mapSegmentSalesByDayPeriodType(res.data!);
    return {
      ...res,
      data: formattedData
    } as AxiosResponse<GetSegmentSalesByDayPeriodType>;
  });
};
