import { useEffect, useMemo, useState } from 'react';
import { useGetSalesComparative } from '../../../../../../../api';
import { ColumnChartWithLine } from '../../../../../../../common/analytics/ColumnChartWithLine';
import { useDashboard } from '../../../../../hooks/useDashboard';
import { useDashboardMonthlyContext } from '../../hooks/useDashboardMonthlyContext';

const StoreSales = () => {
  const { period } = useDashboardMonthlyContext();
  const { selectedStore } = useDashboard();
  const [keyCount, setKeyCount] = useState(0);

  const { data, isLoading, isFetching } = useGetSalesComparative(
    selectedStore,
    {
      'period.since': period.since,
      'period.to': period.to
    },
    {
      query: {
        enabled: !!selectedStore && !!period.since && !!period.since
      }
    }
  );

  const monthlyStoreSales = useMemo(() => data ?? [], [isFetching]);

  useEffect(() => {
    setKeyCount((prev) => prev + 1);
  }, [monthlyStoreSales]);

  return (
    <ColumnChartWithLine
      key={keyCount}
      title="VENDAS MENSAIS DA LOJA"
      data={monthlyStoreSales}
      isLoading={isLoading}
      helpText="Comparativo das vendas totais da marca em cada mês do período selecionado (ano vigente versus ano anterior) e sua variação (%)."
    />
  );
};

export { StoreSales };
