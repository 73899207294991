import { TableRow } from '@mui/material';
import { HeaderGroup } from 'react-table';
import { StyledTableCell, StyledTableFooter } from './styles';

interface FooterProps<T extends object> {
  footerGroups: HeaderGroup<T>[];
}

const Footer = <T extends object>({ footerGroups }: FooterProps<T>) => (
  <StyledTableFooter>
    {footerGroups.map((footerGroup) => (
      <TableRow {...footerGroup.getFooterGroupProps()}>
        {footerGroup.headers.map((column) => (
          <StyledTableCell
            {...column.getFooterProps({
              style: {
                width: column.width,
                minWidth: column.minWidth,
                maxWidth: column.maxWidth
              }
            })}
            key={column.id}
            sx={{ fontWeight: 'bolder' }}
          >
            {column.render('Footer')}
          </StyledTableCell>
        ))}
      </TableRow>
    ))}
  </StyledTableFooter>
);

export { Footer };
