import { useMemo } from 'react';
import { useGetSalesBySegment } from '../../../../../../../api';
import { DonutChart } from '../../../../../../../common/analytics/DonutChart';
import { useDashboard } from '../../../../../hooks/useDashboard';
import { useDashboardDailyContext } from '../../hooks/useDashboardDailyContext';

const BranchSegmentSales = () => {
  const { selectedStore } = useDashboard();
  const { period } = useDashboardDailyContext();
  const { storeDetails } = useDashboard();

  const { data, isLoading } = useGetSalesBySegment(
    selectedStore,
    {
      'period.since': period.since,
      'period.to': period.to
    },
    {
      query: {
        enabled: !!selectedStore && !!period.since && !!period.to
      }
    }
  );

  const branchSegmentSalesList = useMemo(() => data ?? [], [data]);

  return (
    <DonutChart
      data={branchSegmentSalesList}
      title={`VENDAS POR SEGMENTO DO RAMO ${storeDetails.branch}`}
      isLoading={isLoading}
      helpText="Somatório das vendas por segmento do ramo da marca no período de datas selecionado"
    />
  );
};

export { BranchSegmentSales };
