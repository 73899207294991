import { GlobalStyles as GlobalStylesMui } from '@mui/material';

export function GlobalStyles() {
  return (
    <GlobalStylesMui
      styles={{
        '.form-modal-divider': {
          marginTop: '16px',
          marginBottom: '21px'
        },
        '.form-modal-root': {
          paddingBottom: '32px !important'
        },
        '.form-modal-title': {
          paddingTop: '32px !important',
          paddingBottom: '16px !important'
        },
        ':root': {
          '--blue-brmalls': '#063793',
          '--sub-title': '#6e759f'
        },

        '*': {
          margin: 0,
          padding: 0,
          outline: 0,
          boxSizing: 'border-box'
        },
        body: {
          background: '#f0f2f5',
          WebkitFontSmoothing: 'antialiased'
        },
        '#root': {
          minHeight: '100vh',
          height: '100vh'
        },
        button: {
          cursor: 'pointer'
        },
        'body, input, button': {
          font: "16px 'Roboto', sans-serif"
        }
      }}
    />
  );
}
