import { useMemo } from 'react';
import { useGetSalesBranch } from '../../../../../../../api';
import { GetBranchSalesByMonthPeriodType } from '../../../../../../../api/analytics/mappers/branch-sales.mapper';
import { DonutChart } from '../../../../../../../common/analytics/DonutChart';
import { useDashboard } from '../../../../../hooks/useDashboard';
import { useDashboardMonthlyContext } from '../../hooks/useDashboardMonthlyContext';

const BranchSales = () => {
  const { selectedStore, storeDetails } = useDashboard();
  const { period } = useDashboardMonthlyContext();

  const { data, isLoading } = useGetSalesBranch(
    selectedStore,
    {
      'period.since': period.since,
      'period.to': period.to
    },
    {
      query: {
        enabled: !!selectedStore && !!period.since && !!period.to
      }
    }
  );

  const branchSalesList = useMemo(
    () => (data ?? []) as GetBranchSalesByMonthPeriodType[],
    [data]
  );

  return (
    <DonutChart
      data={branchSalesList}
      title={`VENDAS NO RAMO ${storeDetails.branch}`}
      monoColor={{ enable: true, graphColor: 'blue' }}
      isLoading={isLoading}
      helpText="Representatividade (%) das vendas totais da marca em comparativo com as vendas totais das marcas categorizadas no mesmo ramo no período de datas selecionado."
    />
  );
};

export { BranchSales };
