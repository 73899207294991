import { styled, TableCell, TableFooter, TableHead } from '@mui/material';

export const StyledTableHead = styled(TableHead)`
  background: ${(props) => props.theme.palette.background.default};
`;

export const StyledTableFooter = styled(TableFooter)`
  background: ${(props) => props.theme.palette.background.default};
`;

export const StyledTableCell = styled(TableCell)`
  color: ${(props) => props.theme.palette.common.black};
`;
