import { useTable } from 'react-table';
import { SquareMeterSalesComparativeByDayPeriodType } from '../../../../../../../api/analytics/mappers/square-meter-sales.mapper';
import { Table } from '../../../../../../../common/analytics/Table';
import { datatableHeader } from './datatable.header';

type ComparativeSalesSquareMeterProps = {
  isLoading?: boolean;
  data: SquareMeterSalesComparativeByDayPeriodType[];
};

const ComparativeSalesSquareMeter = ({
  isLoading = false,
  data
}: ComparativeSalesSquareMeterProps) => {
  const columns = datatableHeader();

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    rows,
    headerGroups,
    footerGroups
  } = useTable({
    columns,
    data
  });

  return (
    <Table
      title="COMPARATIVO VENDAS POR M² DIÁRIA"
      helpText="Comparativo das vendas totais diárias por m² da marca, segmento, ramo e do Shopping do período selecionado."
      getTableBodyProps={getTableBodyProps}
      getTableProps={() => ({
        ...getTableProps(),
        style: { maxHeight: 440 }
      })}
      headerGroups={headerGroups}
      footerGroups={footerGroups}
      isLoading={isLoading}
      prepareRow={prepareRow}
      rows={rows}
    />
  );
};

export { ComparativeSalesSquareMeter };
