import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ReactElement } from 'react';
import { envs } from '../configs/envs';

const AZURE_AD_CLIENT_ID = envs.get('REACT_APP_AZURE_AD_CLIENT_ID');
const AZURE_AD_TENANT_ID = envs.get('REACT_APP_AZURE_AD_TENANT_ID');
const AZURE_AD_REDIRECT_URI = envs.get('REACT_APP_AZURE_AD_REDIRECT_URI');

const configuration: Configuration = {
  auth: {
    clientId: AZURE_AD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${AZURE_AD_TENANT_ID}`,
    redirectUri: `${AZURE_AD_REDIRECT_URI}`
  }
};

const pca = new PublicClientApplication(configuration);

interface AzureAdProviderProps {
  children: ReactElement;
}

export function AzureAdProvider({ children }: AzureAdProviderProps) {
  return <MsalProvider instance={pca}>{children}</MsalProvider>;
}
