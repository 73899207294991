import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { differenceInYears, subYears } from 'date-fns';
import { GetSalesAnnualResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';
import {
  LineChartData,
  LineChartDataValue
} from '../../../common/analytics/LineChart';

export type SalesAnnual = {
  storeSalesYear: LineChartData[];
  storeMarketShareYear: LineChartData[];
};

const mapper = (res: AxiosResponse<GetSalesAnnualResponseDto[]>) => {
  const { data, config } = res;

  const formattedDataSales: SalesAnnual['storeSalesYear'] = [];
  const formattedDataMarketShare: SalesAnnual['storeMarketShareYear'] = [];
  const years: number[] = [];

  const diffYear = differenceInYears(
    new Date(config.params['period.to'], 1, 1),
    new Date(config.params['period.since'], 1, 1)
  );

  let salesYear: LineChartDataValue[] = [];
  let marketShareYear: LineChartDataValue[] = [];
  let prevYear = new Date();

  for (let i = 0; i <= diffYear; i += 1) {
    years.push(prevYear.getFullYear());
    prevYear = subYears(new Date(prevYear), 1);
  }

  years.reverse().forEach((itemYear) => {
    for (let month = 1; month <= 12; month += 1) {
      const selectedMonth = data.find(
        (itemSalesMonth) =>
          Number(itemSalesMonth.month) === month &&
          Number(itemSalesMonth.year) === itemYear
      );
      const year = Number(itemYear);

      salesYear.push({
        value: selectedMonth?.sales ?? 0,
        date: {
          month,
          year
        }
      });
      marketShareYear.push({
        value: selectedMonth?.marketShare ?? 0,
        date: {
          month,
          year
        }
      });
    }

    if (salesYear.length) {
      formattedDataSales.push({
        name: String(itemYear),
        data: salesYear
      });
    }
    if (marketShareYear.length) {
      formattedDataMarketShare.push({
        name: String(itemYear),
        data: marketShareYear
      });
    }

    salesYear = [];
    marketShareYear = [];
  });

  return {
    storeSalesYear: formattedDataSales,
    storeMarketShareYear: formattedDataMarketShare
  } as unknown as Promise<SalesAnnual>;
};

// eslint-disable-next-line
export const getSalesAnnualMutator = <T>(
  config: AxiosRequestConfig
): Promise<SalesAnnual> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getSalesAnnualMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
