import { useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';
import { useGetStoreDetails } from '../../api';
import { Loading } from '../../components/Loading';
import { DashboardHeader } from './components/DashboardHeader';
import { InfoSection } from './components/StoreView/Monthly/components/OverviewBigNumbers/styles';
import { useDashboard } from './hooks/useDashboard';

type DashboardProps = {
  items: string[];
};

export function Dashboard({ items }: DashboardProps) {
  const colors = [
    'info-section-brand',
    'info-section-segment',
    'info-section-flooring'
  ];
  const { selectedStore, isLoading, setStoreDetails } = useDashboard();
  const { data: storeDetailsResponse } = useGetStoreDetails(selectedStore);

  const storeDetails = useMemo(() => {
    return storeDetailsResponse;
  }, [storeDetailsResponse]);

  useEffect(() => {
    setStoreDetails({
      flooring: storeDetails?.flooring ?? '',
      branch: storeDetails?.branch ?? '',
      segment: storeDetails?.segment ?? '',
      shopping: storeDetails?.mallName ?? '',
      brand: storeDetails?.brandName ?? ''
    });
  }, [storeDetails]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <DashboardHeader
        title="Visão loja"
        subtitle="Este é o Dashboard com Métricas de Performance de sua Loja:"
      />
      <Container
        sx={{
          margin: 0,
          maxWidth: '100% !important',
          padding: '0px !important',
          marginBottom: '2rem'
        }}
      >
        <InfoSection>
          {items.map((item, i) => (
            <div key={item} className={colors[i]}>
              <p>{item}</p>
            </div>
          ))}
        </InfoSection>
      </Container>
      <Outlet />
    </>
  );
}
