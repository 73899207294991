import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosResponse } from 'axios';
import {
  GetSegmentSalesByDayPeriodParams,
  getSegmentSalesByDayPeriod,
  getSegmentSalesByMonthPeriod,
  GetSegmentSalesByMonthPeriodParams
} from '../requests/segment-sales.request';
import { DEFAULT_RETRY_REQUEST } from '../../../constants/request';
import {
  GetSegmentSalesByMonthPeriodType,
  GetSegmentSalesByDayPeriodType
} from '../mappers/segment-sales.mapper';

export const useGetSegmentSalesByMonthPeriodQueryKey = (
  segmentSalesByMonthPeriodParams: GetSegmentSalesByMonthPeriodParams
) => ['useGetSegmentSalesByMonthPeriod', segmentSalesByMonthPeriodParams];

export const useGetSegmentSalesByMonthPeriod = (
  segmentSalesByMonthPeriodParams: GetSegmentSalesByMonthPeriodParams,
  options?: UseQueryOptions<AxiosResponse<GetSegmentSalesByMonthPeriodType[]>>
) =>
  useQuery<AxiosResponse<GetSegmentSalesByMonthPeriodType[]>>(
    useGetSegmentSalesByMonthPeriodQueryKey(segmentSalesByMonthPeriodParams),
    getSegmentSalesByMonthPeriod,
    {
      ...options,
      enabled:
        !!segmentSalesByMonthPeriodParams.accessDataKey &&
        !!segmentSalesByMonthPeriodParams.since &&
        !!segmentSalesByMonthPeriodParams.to,
      retry: DEFAULT_RETRY_REQUEST
    }
  );

export const useGetSegmentSalesByDayPeriodQueryKey = (
  segmentSalesByDayPeriodParams: GetSegmentSalesByDayPeriodParams
) => ['useGetSegmentSalesByDayPeriod', segmentSalesByDayPeriodParams];

export const useGetSegmentSalesByDayPeriod = (
  segmentSalesByDayPeriodParams: GetSegmentSalesByDayPeriodParams,
  options?: UseQueryOptions<AxiosResponse<GetSegmentSalesByDayPeriodType>>
) =>
  useQuery<AxiosResponse<GetSegmentSalesByDayPeriodType>>(
    useGetSegmentSalesByDayPeriodQueryKey(segmentSalesByDayPeriodParams),
    getSegmentSalesByDayPeriod,
    {
      ...options,
      enabled:
        !!segmentSalesByDayPeriodParams.accessDataKey &&
        !!segmentSalesByDayPeriodParams.since &&
        !!segmentSalesByDayPeriodParams.to,
      retry: DEFAULT_RETRY_REQUEST
    }
  );
