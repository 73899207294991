import { createContext, useMemo } from 'react';
import { useDashboardShopping } from '../hooks/useDashboardShopping';

export interface ShoppingPeriodType {
  since: string;
  to: string;
}

interface StoreViewShoppingState {
  period: ShoppingPeriodType;
  setPeriod: (value: ShoppingPeriodType) => void;
}

const StoreViewShoppingContext = createContext<StoreViewShoppingState>({
  period: { since: '', to: '' },
  setPeriod: () => null
});

interface StoreViewShoppingProviderProps {
  children: React.ReactNode;
}

export function StoreViewShoppingProvider({
  children
}: StoreViewShoppingProviderProps) {
  const { period, setPeriod } = useDashboardShopping();

  const providerValue = useMemo(
    () => ({
      period,
      setPeriod
    }),
    [period]
  );

  return (
    <StoreViewShoppingContext.Provider value={providerValue}>
      {children}
    </StoreViewShoppingContext.Provider>
  );
}

export default StoreViewShoppingContext;
