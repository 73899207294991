import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { ROLES } from '../../constants/roles';
import useAuth from '../../hooks/useAuth';
import { Routes } from '../../routes/Routes';
import { Loading } from '../Loading';

const RequireAuth = ({ allowedRoles }: { allowedRoles: string[] }) => {
  const { user } = useAuth();
  const location = useLocation();

  const isResourceAuthorized = user?.roles?.find(
    (role) => role === ROLES.Admin.slug || allowedRoles?.includes(role)
  );

  if (user?.isLoading) {
    return <Loading />;
  }

  if (!user) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  if (!isResourceAuthorized)
    return (
      <Navigate to={Routes.Unauthorized} state={{ from: location }} replace />
    );

  return <Outlet />;
};

export default RequireAuth;
