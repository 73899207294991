export enum Routes {
  Home = '/',
  Unauthorized = '/401',
  SignIn = '/sign-in',
  RecoverPassword = '/recuperar-senha',
  ResetPassword = '/redefinir-senha',
  Dashboard = '/dashboard',
  DashboardStore = '/dashboard/store',
  DashboardStoreMonthly = '/dashboard/store/mensal',
  DashboardStoreDaily = '/dashboard/store/diaria',
  DashboardStoreMall = '/dashboard/store/shopping',
  Catalog = '/catalogo',
  ProjectsCurated = '/catalogo/projetos',
  CatalogCurated = '/catalogo/curadoria',
  ProjectItemsFromCurated = '/catalogo/projetos/:projectId/items',
  Management = '/configuracoes',
  UserManagement = '/configuracoes/users',
  Benefits = '/benefits',
  Coupons = 'coupons',
  Coupon = 'coupon'
}
