import { LineChartData } from '../../../common/analytics/LineChart/index';
import {
  ColumnChartData,
  ColumnChartDataValue
} from '../../../common/analytics/ColumnChartWithLine';
import {
  GetSegmentSalesByMonthPeriodResponse,
  GetSegmentSalesByDayPeriodDataResponse
} from '../requests/segment-sales.request';

export type GetSegmentSalesByMonthPeriodType = ColumnChartData;
export type GetSegmentSalesByMonthPeriodDataType = ColumnChartDataValue;

export const mapSegmentSalesByMonthPeriodType = (
  segmentSalesByMonthPeriod: GetSegmentSalesByMonthPeriodResponse
): GetSegmentSalesByMonthPeriodType[] => {
  const salesAmount: GetSegmentSalesByMonthPeriodDataType[] = [];
  const salesAmountSegment: GetSegmentSalesByMonthPeriodDataType[] = [];
  const marketShare: GetSegmentSalesByMonthPeriodDataType[] = [];

  segmentSalesByMonthPeriod.data?.forEach((item) => {
    const month = Number(item.month);
    const year = Number(item.year);

    salesAmount.push({
      value: item.sales,
      date: {
        month,
        year
      }
    });

    salesAmountSegment.push({
      value: item.salesSegment,
      date: {
        month,
        year
      }
    });

    marketShare.push({
      value: item.marketShare,
      date: {
        month,
        year
      }
    });
  });

  const formattedData = [
    {
      title: 'Vendas Segmento',
      data: salesAmountSegment
    },
    {
      title: 'Vendas Marca',
      data: salesAmount
    },
    {
      title: 'Market Share',
      data: marketShare
    }
  ];

  return formattedData;
};

export type GetSegmentSalesByDayPeriodType = {
  selectedPeriodSales: ColumnChartData[];
  segmentSales: LineChartData[];
};

export type GetSegmentSalesByDayPeriodDataType = ColumnChartDataValue;

export const mapSegmentSalesByDayPeriodType = (
  segmentSalesByDayPeriod: GetSegmentSalesByDayPeriodDataResponse[]
): GetSegmentSalesByDayPeriodType => {
  const reservationAmount: GetSegmentSalesByDayPeriodDataType[] = [];
  const segmentAmount: GetSegmentSalesByDayPeriodDataType[] = [];
  const marketShareAmount: GetSegmentSalesByDayPeriodDataType[] = [];

  segmentSalesByDayPeriod.forEach((item) => {
    const day = Number(item.day);
    const month = Number(item.month);
    const year = Number(item.year);

    reservationAmount.push({
      value: item.sales,
      date: {
        day,
        month,
        year
      }
    });

    segmentAmount.push({
      value: item.salesSegment,
      date: {
        day,
        month,
        year
      }
    });

    marketShareAmount.push({
      value: item.marketShare,
      date: {
        day,
        month,
        year
      }
    });
  });

  const selectedPeriodSales = [
    {
      title: 'Reserva',
      data: reservationAmount
    },
    {
      title: 'Segmento',
      data: segmentAmount
    },
    {
      title: 'Market Share',
      data: marketShareAmount
    }
  ];

  const segmentSales = [
    {
      name: 'Reserva',
      data: reservationAmount
    },
    {
      name: 'Segmento',
      data: segmentAmount
    }
  ];

  return {
    selectedPeriodSales,
    segmentSales
  } as unknown as GetSegmentSalesByDayPeriodType;
};
