import { subYears } from 'date-fns/esm';
import { useMemo } from 'react';
import { useGetSalesAnnual } from '../../../../../../../api';
import { LineChart } from '../../../../../../../common/analytics/LineChart';
import { formatPercentage } from '../../../../../../../helpers/percentage';
import { useDashboard } from '../../../../../hooks/useDashboard';

const StoreSalesYear = () => {
  const { selectedStore } = useDashboard();
  const currentDate = new Date();
  const since = subYears(currentDate, 3).getFullYear();
  const to = currentDate.getFullYear();

  const { data, isLoading } = useGetSalesAnnual(
    selectedStore,
    {
      'period.since': since,
      'period.to': to
    },
    {
      query: {
        enabled: !!selectedStore
      }
    }
  );

  const { storeSalesYear, storeMarketShareYear } = useMemo(
    () => data ?? { storeSalesYear: [], storeMarketShareYear: [] },
    [data]
  );

  return (
    <>
      <LineChart
        title="VENDAS MENSAIS DA LOJA POR ANO"
        dataSerie={storeSalesYear}
        isLoading={isLoading}
        helpText="Somatório das vendas totais da marca por mês segmentada por ano."
      />
      <LineChart
        title="MARKET SHARE LOJA POR ANO"
        helpText="Representatividade (%) das vendas totais da marca em comparativo com as vendas totais do seu segmento por mês segmentada por ano."
        dataSerie={storeMarketShareYear}
        isLoading={isLoading}
        yFormatter={(val: number) => formatPercentage(val)}
        tooltip={{
          y: {
            formatter: (val: number) => formatPercentage(val)
          }
        }}
      />
    </>
  );
};

export { StoreSalesYear };
