import { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { Skeleton, useTheme } from '@mui/material';
import { dateFormatter } from '../helpers/date-formatter';
import { buildChartsConfigs } from './config';
import { ChartContainer } from '../shared/ChartBase';

export interface ColumnChartData {
  title: string;
  data: {
    value: number;
    date: {
      day?: number;
      month: number;
      year: number;
    };
  }[];
}

export type ColumnChartDataValue = ColumnChartData['data'][0];

type ColumnChartProps = {
  title: string;
  data: ColumnChartData[];
  isLoading?: boolean;
  stacked?: boolean;
  helpText?: string;
};

type DateType = {
  day?: number;
  month: number;
  year: number;
};

const buildChartColumnDataSerie = (
  dataSources: ColumnChartData[]
): ApexAxisChartSeries => {
  return dataSources.map((dataSource, i, originalArray) => ({
    name: dataSource.title,
    data: dataSource.data
      ? dataSource.data.map((data) => {
          const formattedDate: DateType = {
            month: data.date.month,
            year: data.date.year
          };

          if (data.date.day) {
            formattedDate.day = data.date.day;
          }

          return {
            y: data.value,
            x: dateFormatter(formattedDate)
          };
        })
      : [],
    type: i < originalArray.length - 1 ? 'column' : 'line'
  }));
};

export function ColumnChartWithLine({
  title,
  data,
  isLoading = false,
  stacked = false,
  helpText
}: ColumnChartProps) {
  const theme = useTheme();

  const yaxisMax = useMemo(() => {
    if (stacked) {
      return data[0]?.data
        .map((it, ii) => {
          return it.value + data[1].data[ii].value;
        })
        .reduce((prev, current) => (prev > current ? prev : current), 0);
    }

    return data
      .slice(0, -1)
      .map((item) =>
        item.data?.reduce(
          (prevItem, currentItem) =>
            prevItem > currentItem.value ? prevItem : currentItem.value,
          0
        )
      )
      .reduce((prev, current) => (prev > current ? prev : current), 0);
  }, [data]);

  const opts = useMemo(
    () =>
      buildChartsConfigs(
        [
          theme.colors.warning.main,
          theme.colors.success.main,
          theme.palette.common.black
        ],
        { stacked, yaxisMax }
      ),
    [
      theme.colors.success.main,
      theme.colors.warning.main,
      theme.palette.common.black
    ]
  );

  const dataSerie = useMemo(() => buildChartColumnDataSerie(data), [isLoading]);

  return (
    <ChartContainer title={title} height="370px" helpText={helpText}>
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={284}
        />
      ) : (
        <Chart
          options={opts}
          series={dataSerie}
          type="line"
          width="100%"
          height={284}
        />
      )}
    </ChartContainer>
  );
}
