/**
 * Generated by orval v6.13.1 🍺
 * Do not edit manually.
 * BACKEND FOR FRONTEND
 * This api is responsible to forward the request to the correct api
 * OpenAPI spec version: 0.0.1
 */

export type BenefitTypes = (typeof BenefitTypes)[keyof typeof BenefitTypes];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BenefitTypes = {
  RAFFLE: 'RAFFLE',
  EXPERIENCE: 'EXPERIENCE',
  FREEBIE: 'FREEBIE',
  DISCOUNT: 'DISCOUNT',
  BUYANDWIN: 'BUYANDWIN',
  SERVICES: 'SERVICES'
} as const;
