import { useEffect, useMemo, useState } from 'react';
import { useGetSQMSalesDaily } from '../../../../../../../api';
import { LineChart } from '../../../../../../../common/analytics/LineChart';
import { useDashboard } from '../../../../../hooks/useDashboard';
import { useDashboardDailyContext } from '../../hooks/useDashboardDailyContext';
import { ComparativeSalesSquareMeter } from './ComparativeSalesSquareMeter';

const SquareMeterSales = () => {
  const [keyCount, setKeyCount] = useState(0);
  const { period } = useDashboardDailyContext();
  const { selectedStore } = useDashboard();

  const { data, isLoading } = useGetSQMSalesDaily(
    selectedStore,
    {
      'period.since': period.since,
      'period.to': period.to
    },
    {
      query: {
        enabled: !!selectedStore && !!period.since && !!period.to
      }
    }
  );

  const {
    squareMeterSales: squareMeterSalesList,
    squareMeterSalesComparative: squareMeterSalesComparativeList
  } = useMemo(
    () => data ?? { squareMeterSales: [], squareMeterSalesComparative: [] },
    [data]
  );

  useEffect(() => {
    setKeyCount((prev) => prev + 1);
  }, [data]);

  return (
    <>
      <LineChart
        key={keyCount}
        title="VENDAS POR M²"
        dataSerie={squareMeterSalesList}
        isLoading={isLoading}
        helpText="Vendas totais diárias da marca, segmento e ramo por m² em cada mês do período selecionado."
      />
      <ComparativeSalesSquareMeter
        isLoading={isLoading}
        data={squareMeterSalesComparativeList}
      />
    </>
  );
};

export { SquareMeterSales };
