const envsValues: Record<string, string | undefined> = {
  REACT_APP_BFF_HOST: process.env.REACT_APP_BFF_HOST,
  REACT_APP_WEBSITE_URL: process.env.REACT_APP_WEBSITE_URL,
  REACT_APP_INSIGHTS_INSTRUMENTATIONKEY:
    process.env.REACT_APP_INSIGHTS_INSTRUMENTATIONKEY,
  REACT_APP_FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET:
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  REACT_APP_AZURE_AD_CLIENT_ID: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
  REACT_APP_AZURE_AD_TENANT_ID: process.env.REACT_APP_AZURE_AD_TENANT_ID,
  REACT_APP_AZURE_AD_REDIRECT_URI: process.env.REACT_APP_AZURE_AD_REDIRECT_URI
};

export const envs = {
  get(envName: string): string {
    return envsValues[envName] || '';
  }
};
