import { initializeApp } from 'firebase/app';
import {
  sendSignInLinkToEmail,
  Auth,
  getAuth,
  signInWithEmailLink,
  signOut as firebaseSignOut,
  UserCredential,
  isSignInWithEmailLink,
  onIdTokenChanged,
  NextOrObserver,
  User,
  Unsubscribe,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
  confirmPasswordReset,
  verifyPasswordResetCode
} from 'firebase/auth';
import { envs } from '../../configs/envs';

interface SendSignInEmailDTO {
  email: string;
  continueURL?: string;
}

interface SignInDTO {
  email: string;
  signInEmailLink: string;
}

class FirebaseAuthService {
  private readonly auth: Auth;

  private readonly callBackHost: string;

  constructor() {
    this.callBackHost = `${envs.get('REACT_APP_WEBSITE_URL')}/`;

    const firebaseConfig = {
      apiKey: envs.get('REACT_APP_FIREBASE_API_KEY'),
      authDomain: envs.get('REACT_APP_FIREBASE_AUTH_DOMAIN'),
      projectId: envs.get('REACT_APP_FIREBASE_PROJECT_ID'),
      storageBucket: envs.get('REACT_APP_FIREBASE_STORAGE_BUCKET'),
      messagingSenderId: envs.get('REACT_APP_FIREBASE_MESSAGING_SENDER_ID'),
      appId: envs.get('REACT_APP_FIREBASE_APP_ID')
    };
    const app = initializeApp(firebaseConfig);
    this.auth = getAuth(app);
  }

  async sendSignInEmailLink({
    email,
    continueURL
  }: SendSignInEmailDTO): Promise<void> {
    const url = continueURL || '/';

    const actionCodeSettings = {
      url: `${this.callBackHost}${url}`,
      handleCodeInApp: true
    };

    await sendSignInLinkToEmail(this.auth, email, actionCodeSettings);
  }

  isSignInWithEmailLink(signInURL: string): boolean {
    const isSignInLink = isSignInWithEmailLink(this.auth, signInURL);

    return isSignInLink;
  }

  async signInWithEmailLink(data: SignInDTO): Promise<UserCredential> {
    const { email, signInEmailLink } = data;
    const userCredentials = await signInWithEmailLink(
      this.auth,
      email,
      signInEmailLink
    );

    return userCredentials;
  }

  async signOut(): Promise<void> {
    await firebaseSignOut(this.auth);
  }

  onAuthStateChanged(callback: NextOrObserver<User>): Unsubscribe {
    return onIdTokenChanged(this.auth, callback);
  }

  async signIn(email: string, password: string): Promise<User | void> {
    const result = await signInWithEmailAndPassword(
      this.auth,
      email,
      password
    ).then((userCredential) => {
      return userCredential.user;
    });

    return result;
  }

  async recoverPassword(email: string): Promise<void> {
    await sendPasswordResetEmail(this.auth, email);
  }

  async fetchSignInMethods(email: string): Promise<string[]> {
    return fetchSignInMethodsForEmail(this.auth, email);
  }

  async updatePassword(code: string, newPassword: string): Promise<void> {
    await confirmPasswordReset(this.auth, code, newPassword);
  }

  async checkPasswordCode(code: string): Promise<boolean> {
    const correctCode = await verifyPasswordResetCode(this.auth, code)
      .then(() => true)
      .catch(() => false);

    return correctCode;
  }
}

export default new FirebaseAuthService();
