import Chart from 'react-apexcharts';
import { Skeleton, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { ChartContainer } from '../shared/ChartBase';
import { dateFormatter } from '../helpers/date-formatter';
import { buildChartConfigs } from './config';

export interface ColumnChartData {
  title: string;
  data: {
    value: number;
    date: {
      day?: number;
      month: number;
      year: number;
    };
  }[];
}

export type ColumnChartDataValue = ColumnChartData['data'][0];

interface ColumnChartProps {
  title: string;
  stacked?: boolean;
  data: ColumnChartData[];
  isLoading?: boolean;
  colors?: string[];
  helpText?: string;
}

const buildDataSerie = (
  dataSources: ColumnChartData[]
): ApexAxisChartSeries => {
  return dataSources.map((dataSource) => ({
    name: dataSource.title,
    data: dataSource.data.map((data) => {
      return {
        y: data.value,
        x: dateFormatter({
          day: data.date.day,
          month: data.date.month,
          year: data.date.year
        })
      };
    }),
    type: 'column'
  }));
};

const calcColumnWidth = (dataLength: number, stacked: boolean) => {
  if (stacked || dataLength <= 0) {
    return '20%';
  }

  if (dataLength >= 5) {
    return '90%';
  }

  return `${dataLength * 20}%`;
};

export function ColumnChart({
  title,
  data,
  stacked = false,
  isLoading = false,
  colors,
  helpText
}: ColumnChartProps) {
  const theme = useTheme();
  const barColors = colors?.length ? colors : [theme.colors.dashboard.darkBlue];
  const opts = useMemo(
    () =>
      buildChartConfigs({
        colors: barColors,
        stacked,
        columnWidth: calcColumnWidth(data.length, stacked)
      }),
    [theme.colors.dashboard, stacked, data]
  );

  const dataSerie = useMemo(() => buildDataSerie(data), [isLoading]);

  return (
    <ChartContainer title={title} height="370px" helpText={helpText}>
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          width="100%"
          height={284}
        />
      ) : (
        <Chart
          options={opts}
          series={dataSerie}
          type="bar"
          width="100%"
          height={284}
        />
      )}
    </ChartContainer>
  );
}
