import { useMemo } from 'react';
import { format, subMonths } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import { InsertChartTwoTone } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { useGetBigNumbers } from '../../../../../../../api';
import { BigNumber } from '../../../../../../../common/analytics/BigNumber';
import { useDashboard } from '../../../../../hooks/useDashboard';
import { ChartContainer, ChartContent } from './styles';

const getFormattedMonths = (now: Date) => {
  const currentDate = now;
  const previousMonth = subMonths(currentDate, 1);
  const previousMonthFormatted = format(previousMonth, 'MMMM/yyyy', {
    locale: ptBr
  }).toLocaleUpperCase();
  const currentMonthFormatted = format(currentDate, 'MMMM/yyyy', {
    locale: ptBr
  }).toLocaleUpperCase();

  return {
    current: currentMonthFormatted,
    previous: previousMonthFormatted
  };
};

interface BigNumbersGroupProps {
  children: React.ReactNode;
  title: string;
}

function BigNumbersGroup({ children, title }: BigNumbersGroupProps) {
  return (
    <ChartContainer>
      <header>
        <div>
          <InsertChartTwoTone />
          <p>{title}</p>
        </div>
      </header>
      <ChartContent>{children}</ChartContent>
    </ChartContainer>
  );
}

export function OverviewBigNumbers() {
  const { selectedStore } = useDashboard();

  const currentDate = new Date();
  const previousDate = subMonths(currentDate, 1);

  const {
    data: previousMonthBigNumbersData,
    isLoading: isLoadingPreviousMonth
  } = useGetBigNumbers(
    selectedStore,
    {
      'period.year': previousDate.getFullYear(),
      'period.month': previousDate.getMonth() + 1
    },
    {
      query: {
        enabled:
          !!selectedStore &&
          !!previousDate.getFullYear() &&
          !!(previousDate.getMonth() + 1)
      }
    }
  );
  const { data: currentMonthBigNumbersData, isLoading: isLoadingCurrentMonth } =
    useGetBigNumbers(
      selectedStore,
      {
        'period.year': currentDate.getFullYear(),
        'period.month': currentDate.getMonth() + 1
      },
      {
        query: {
          enabled:
            !!selectedStore &&
            !!currentDate.getFullYear() &&
            !!(currentDate.getMonth() + 1)
        }
      }
    );

  const previousMonthBigNumbers = useMemo(
    () => previousMonthBigNumbersData,
    [previousMonthBigNumbersData]
  );
  const currentMonthBigNumbers = useMemo(
    () => currentMonthBigNumbersData,
    [currentMonthBigNumbersData]
  );

  const formattedMonthTitles = getFormattedMonths(new Date());
  const previousMonthTitle = `MÊS ANTERIOR: ${formattedMonthTitles.previous}`;
  const currentMonthTitle = `MÊS ATUAL: ${formattedMonthTitles.current}`;

  return (
    <>
      <Stack direction="row">
        <BigNumbersGroup title={currentMonthTitle}>
          <BigNumber.Card
            title="Vendas"
            contentValue={currentMonthBigNumbers?.salesAmount}
            color="green"
            isLoading={isLoadingCurrentMonth}
            helpText="Somatório das vendas totais da marca no mês vigente."
          />
          <BigNumber.Card
            title="Market Share Segmento"
            contentValue={currentMonthBigNumbers?.marketShareBySegment}
            isPercentage
            color="orange"
            isLoading={isLoadingCurrentMonth}
            helpText="Representatividade (%) das vendas totais da marca em comparativo com as vendas totais do seu segmento do mês vigente."
          />
          <BigNumber.Card
            title="Ranking de evolução SSS"
            contentValue={currentMonthBigNumbers?.rankBySegment}
            isRanking
            color="orange"
            isLoading={isLoadingCurrentMonth}
            helpText="Rankeamento do crescimento da marca em comparativo as demais marcas do seu segmento no mês vigente, considerando o SSS, que trata-se das vendas da marca que se encontra no mesmo local e mesmo ABL (área em m²) no período atual e no mesmo período do ano anterior."
          />
          <BigNumber.Card
            title="Market Share Pavimento"
            contentValue={currentMonthBigNumbers?.marketShareByFlooring}
            isPercentage
            color="blue"
            isLoading={isLoadingCurrentMonth}
            helpText="Representatividade (%) das vendas totais da marca em comparativo com as vendas totais das marcas situadas no mesmo pavimento (piso) no mês vigente."
          />
          <BigNumber.Card
            title="Ranking SSS no Piso"
            contentValue={currentMonthBigNumbers?.rankByFlooring}
            isRanking
            color="blue"
            isLoading={isLoadingCurrentMonth}
            helpText="Rankeamento do crescimento das vendas da marca em comparativo as demais marcas situadas no mesmo pavimento (piso) no mês vigente, considerando o SSS, que trata-se das vendas das marcas que se encontram no mesmo local e mesmo ABL (área em m²) no período atual e no mesmo período do ano anterior."
          />
        </BigNumbersGroup>
      </Stack>
      <Stack gap={0.5} direction="row">
        <BigNumbersGroup title={previousMonthTitle}>
          <BigNumber.Card
            title="Vendas"
            contentValue={previousMonthBigNumbers?.salesAmount}
            color="green"
            isLoading={isLoadingPreviousMonth}
            helpText="Somatório das vendas da marca no mês anterior."
          />
          <BigNumber.Card
            title="Market Share Segmento"
            contentValue={previousMonthBigNumbers?.marketShareBySegment}
            isPercentage
            color="orange"
            isLoading={isLoadingPreviousMonth}
            helpText="Representatividade (%) das vendas totais da marca em comparativo com as vendas totais do seu segmento do mês anterior."
          />
          <BigNumber.Card
            title="Ranking de evolução SSS"
            contentValue={previousMonthBigNumbers?.rankBySegment}
            isRanking
            color="orange"
            isLoading={isLoadingPreviousMonth}
            helpText="Rankeamento do crescimento da marca em comparativo as demais marcas do seu segmento no mês anterior, considerando o SSS, que trata-se das vendas das marcas que se encontram no mesmo local e mesmo ABL (área em m²) no período atual e no mesmo período do ano anterior."
          />
          <BigNumber.Card
            title="Market Share Pavimento"
            contentValue={previousMonthBigNumbers?.marketShareByFlooring}
            isPercentage
            color="blue"
            isLoading={isLoadingPreviousMonth}
            helpText="Representatividade (%) das vendas totais da marca em comparativo com as vendas totais das marcas situadas no mesmo pavimento (piso) no mês anterior."
          />
          <BigNumber.Card
            title="Ranking SSS no Piso"
            contentValue={previousMonthBigNumbers?.rankByFlooring}
            isRanking
            color="blue"
            isLoading={isLoadingPreviousMonth}
            helpText="Rankeamento do crescimento das vendas da marca em comparativo as demais marcas situadas no mesmo pavimento (piso) no mês anterior, considerando o SSS, que trata-se das vendas das marcas que se encontram no mesmo local e mesmo ABL (área em m²) no período atual e no mesmo período do ano anterior."
          />
        </BigNumbersGroup>
      </Stack>
    </>
  );
}
