import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { ListUsersResponseDTO } from '../../model';
import { HTTPService } from '../../../services/shared/axios';

type Role = {
  id: number;
  name: string;
  description: string;
  slug: string;
  active: boolean;
};

type User = {
  id?: string;
  name: string;
  email: string;
  enable?: boolean;
  stores?: Role[];
  profile: string;
};

type GetUsersDTO = {
  users: User[];
  total: number;
};

const mapper = (res: AxiosResponse<ListUsersResponseDTO>) => {
  const { data } = res;

  const mappedUsers = data.allUsers.map(
    (user): User => ({
      id: user.id,
      email: user.email,
      name: user.name,
      profile: user.usersRoles ? user.usersRoles[0].name : '',
      enable: user.enable,
      stores: []
    })
  );

  return {
    users: mappedUsers,
    total: data.total
  } as unknown as Promise<GetUsersDTO>;
};

// eslint-disable-next-line
export const getUsersMutator = <T>(
  config: AxiosRequestConfig
): Promise<GetUsersDTO> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getUsersMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
