import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { GetSalesAndMarketShareResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';

type GetSalesAndMarketShare = GetSalesAndMarketShareResponseDto;

const mapper = (res: AxiosResponse<GetSalesAndMarketShareResponseDto>) => {
  const { data } = res;
  return {
    sales: data.sales,
    marketShare: {
      segment: data.marketShare.segment,
      flooring: data.marketShare.flooring
    }
  } as unknown as Promise<GetSalesAndMarketShare>;
};

// eslint-disable-next-line
export const getSalesAndMarketShareMutator = <T>(
  config: AxiosRequestConfig
): Promise<GetSalesAndMarketShare> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getSalesAndMarketShareMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
