import { TextField } from '@mui/material';
import { useRef, useState } from 'react';
import ReactDatePicker, { CalendarProps, Value } from 'react-multi-date-picker';
import ptBrLocale from './pt-br.locale';

type DatePickerInputProps = {
  value?: string[];
  handleValueChange?: () => void;
  openCalendar?: () => void;
};

function DatePickerInput({
  openCalendar,
  value,
  handleValueChange
}: DatePickerInputProps) {
  return (
    <TextField
      margin="dense"
      sx={{ fontWeight: 'bold', minWidth: '200px' }}
      variant="outlined"
      size="small"
      value={value?.join(' - ')}
      onChange={handleValueChange}
      onFocus={openCalendar}
    />
  );
}

type DatePickerProps = Omit<CalendarProps, 'locale' | 'render' | 'onChange'> & {
  onChange?: (selectedDates: Date[]) => void;
};

export function DatePicker(props: DatePickerProps) {
  const [, setShouldCloseDatepicker] = useState(true);
  const datePickerRef = useRef<{ closeCalendar: () => void }>();

  const handleDateChange = (selectedDates: Value) => {
    const { onChange } = props;
    const formatedSelectedDates = selectedDates!
      .toLocaleString()
      .split(',')!
      .map(
        (selectedDate) => new Date(selectedDate.split('/').reverse().join('/'))
      );

    setShouldCloseDatepicker((prev) => {
      if (!prev) datePickerRef.current?.closeCalendar();
      return !prev;
    });

    if (onChange) onChange(formatedSelectedDates);
  };

  return (
    <ReactDatePicker
      {...props}
      ref={datePickerRef}
      locale={ptBrLocale}
      render={<DatePickerInput />}
      onChange={handleDateChange}
    />
  );
}
