import { useMemo } from 'react';
import { Stack } from '@mui/material';
import { useGetTotalSalesAndMarketShare } from '../../../../../../../api';
import { BigNumber } from '../../../../../../../common/analytics/BigNumber';
import { useDashboard } from '../../../../../hooks/useDashboard';
import { useDashboardDailyContext } from '../../hooks/useDashboardDailyContext';

export function SalesAndMarketShare() {
  const { selectedStore } = useDashboard();
  const { period } = useDashboardDailyContext();
  const { data: salesAndMarketShareResponse, isLoading } =
    useGetTotalSalesAndMarketShare(
      selectedStore,
      {
        'period.since': period.since,
        'period.to': period.to ?? ''
      },
      {
        query: {
          enabled: !!period.since && !!period.to && !!selectedStore
        }
      }
    );

  const storeSalesAndMarketShare = useMemo(
    () => salesAndMarketShareResponse,
    [salesAndMarketShareResponse]
  );

  return (
    <Stack gap={1.5} direction="row">
      <BigNumber.Box
        title="Vendas no Período Selecionado"
        contentValue={storeSalesAndMarketShare?.salesSelected ?? 0}
        isLoading={isLoading}
        helpText="Somatório das vendas da marca no período de datas selecionado."
      />
      <BigNumber.Box
        title="Market Share"
        isPercentage
        contentValue={storeSalesAndMarketShare?.marketShareSegment ?? 0}
        color="orange"
        isLoading={isLoading}
        helpText="Representatividade (%) das vendas totais da marca em comparativo com as vendas totais do seu segmento no período de datas selecionado."
      />
      <BigNumber.Box
        title="Market Share no Período Selecionado"
        isPercentage
        contentValue={storeSalesAndMarketShare?.marketShareFlooring ?? 0}
        color="blue"
        isLoading={isLoading}
        helpText="Representatividade (%) das vendas totais da marca em comparativo com as vendas totais das marcas situadas no mesmo pavimento (piso) no período de datas selecionado."
      />
    </Stack>
  );
}
