import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { GetStoreDetailsResponseDto } from '../../model';
import { HTTPService } from '../../../services/shared/axios';

type StoreDetails = {
  brandName: string;
  segment: string;
  flooring: string;
  mallName: string;
  branch: string;
  lucCode: string;
};

const mapper = (res: AxiosResponse<GetStoreDetailsResponseDto>) => {
  const { data } = res;

  return {
    brandName: data.brandName,
    segment: data.segment,
    flooring: data.flooring,
    mallName: data.mallName,
    branch: data.branch,
    lucCode: data.lucCode
  } as unknown as Promise<StoreDetails>;
};

// eslint-disable-next-line
export const getStoreDetailsDailyMutator = <T>(
  config: AxiosRequestConfig
): Promise<StoreDetails> => {
  const source = axios.CancelToken.source();
  const promise = HTTPService.request(config).then((res) => mapper(res));

  // eslint-disable-next-line
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default getStoreDetailsDailyMutator;

// eslint-disable-next-line
export interface ErrorType<Error> extends AxiosError<Error> {}
