import {
  AutocompleteProps as MuiAutocompleteProps,
  Autocomplete as MuiAutocomplete,
  TextField,
  AutocompleteValue
} from '@mui/material';

interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'loadingText' | 'noOptionsText' | 'renderInput' | 'onChange' | 'inputLabel'
  > {
  testid?: string;
  inputlabel?: string;
  onChange?: (value: NonNullable<string | T>) => void;
}

function Autocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const { testid, inputlabel, onChange } = props;
  // const classes = useStyles();

  const handleOnChange = (
    // eslint-disable-next-line
    event: React.SyntheticEvent,
    value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>
  ) => {
    if (typeof onChange === 'function') {
      if (typeof value === 'string') onChange(value);
      else if (typeof value === 'object') onChange(value as NonNullable<T>);
    }
  };

  return (
    <MuiAutocomplete
      {...props}
      data-testid={testid}
      sx={{
        background: 'white',
        fontWeight: 'bold',
        width: 'auto',
        minWidth: '210px'
      }}
      clearOnEscape
      // classes={classes}
      loadingText="Carregando..."
      noOptionsText="Nenhum registro encontrado"
      onChange={handleOnChange}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            sx: { paddingY: '0px !important', width: 'auto' }
          }}
          InputLabelProps={{
            ...params.InputLabelProps,
            sx: { lineHeight: '.9rem', top: '-3px' }
          }}
          label={inputlabel}
        />
      )}
    />
  );
}

export default Autocomplete;
