import jwtDecode from 'jwt-decode';
import { User } from '../../model/user';
import { HTTPService } from '../../services/shared/axios';

export enum ActionTypes {
  SIGNIN = 'SIGNIN',
  SIGNOUT = 'SIGNOUT',
  INITIALIZE = 'INITIALIZE',
  ADD_PERMISSIONS = 'ADD_PERMISSIONS'
}

type SignInAction = {
  type: 'SIGNIN';
  payload: {
    user: User;
  };
};

type AddPermissionsAction = {
  type: 'ADD_PERMISSIONS';
  payload: {
    user: User;
  };
};

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    user: User | null;
  };
};

type SignOutAction = {
  type: 'SIGNOUT';
};

export type Action =
  | SignInAction
  | SignOutAction
  | InitializeAction
  | AddPermissionsAction;

type UserJwtToken = {
  roles: string[];
};

export async function addPermissionsAction(
  user: User,
  permissionsToken: string
): Promise<AddPermissionsAction> {
  const userToken = jwtDecode<UserJwtToken>(permissionsToken);

  HTTPService.defaults.headers.common['x-b2b-permissions'] = permissionsToken;

  return {
    type: ActionTypes.ADD_PERMISSIONS,
    payload: {
      user: {
        id: user.id,
        email: user.email,
        photoURL: user.photoURL,
        displayName: user.displayName,
        isLoading: false,
        roles: userToken.roles
      }
    }
  };
}

export async function signInAction(user: User): Promise<SignInAction> {
  return {
    type: ActionTypes.SIGNIN,
    payload: {
      user: {
        id: user.id,
        email: user.email,
        photoURL: user.photoURL,
        displayName: user.displayName,
        isLoading: true,
        roles: []
      }
    }
  };
}

export function signOutAction(): SignOutAction {
  return { type: ActionTypes.SIGNOUT };
}

export function initializeAction(userData?: User): InitializeAction {
  return {
    type: ActionTypes.INITIALIZE,
    payload: {
      user: userData
        ? {
            ...userData,
            roles: []
          }
        : null
    }
  };
}
