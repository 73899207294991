import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const NavContainer = styled('nav')`
  ul {
    list-style-type: none;
    display: flex;
  }
`;

export const NavItem = styled(NavLink)`
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  padding: 0 1rem;

  &.active {
    background: ${(props) => props.theme.colors.dashboard.blue};
    color: white;
  }
`;

export const Header = styled('header')`
  color: ${(props) => props.theme.palette.common.black};
  border-bottom: solid 1px ${(props) => props.theme.palette.grey[300]};

  .title-container {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  .subtitle {
    color: ${(props) => props.theme.colors.secondary.main};
    line-height: 1.6;
  }

  .dashboard-header-select {
    height: 38px;
  }

  nav {
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
`;
