import { CircularProgress, TableBody, TableRow } from '@mui/material';
import { TableBodyPropGetter, TableBodyProps, Row } from 'react-table';
import { ItemsNotFoundContainer, StyledTableCell } from './styles';

interface BodyProps<T extends object> {
  getTableBodyProps: (propGetter?: TableBodyPropGetter<T>) => TableBodyProps;
  rows: Row<T>[];
  prepareRow: (row: Row<T>) => void;
  isLoading: boolean;
  colSpan: number;
}

const Body = <T extends object>({
  getTableBodyProps,
  rows,
  prepareRow,
  colSpan,
  isLoading
}: BodyProps<T>) => {
  if (isLoading || rows.length === 0) {
    return (
      <TableBody {...getTableBodyProps()}>
        <TableRow>
          <StyledTableCell colSpan={colSpan}>
            {isLoading ? (
              <CircularProgress
                size={30}
                sx={{ display: 'block', margin: '0 auto' }}
              />
            ) : (
              <ItemsNotFoundContainer>
                Dados indisponíveis no momento
              </ItemsNotFoundContainer>
            )}
          </StyledTableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <TableRow {...row.getRowProps()}>
            {row.cells.map((cell) => (
              <StyledTableCell {...cell.getCellProps()}>
                {cell.render('Cell')}
              </StyledTableCell>
            ))}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export { Body };
