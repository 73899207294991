import { Divider, List, ListItem, styled } from '@mui/material';

export const ListContent = styled('fieldset')`
  border: 1px #1a75ff solid;
  border-radius: 4px;
  width: 100%;
`;

export const ListLegend = styled('legend')`
  margin-left: 1em;
  padding: 0.2em 0.8em;
  color: #1a75ff;
  font-weight: normal;
  font-size: 0.75rem;
`;

export const StyledList = styled(List)`
  padding: 0px;
`;

export const StyledDivider = styled(Divider)`
  background: #1a75ff;
`;

export const StyledListItem = styled(ListItem)`
  padding: 15px;
`;
