import { Stack } from '@mui/material';
import { Dashboard } from '../../..';
import { Loading } from '../../../../../components/Loading';
import { useDashboard } from '../../../hooks/useDashboard';
import { BrandsSegmentBranch } from '../BrandsSegmentBranch';
import { BranchSales } from './components/BranchSales';
import { BranchSegmentSales } from './components/BranchSegmentSales';
import { FlooringSales } from './components/FlooringSales';
import { RangeDatepicker } from './components/RangeDatepicker';
import { SalesAndMarketShare } from './components/SalesAndMarketShare';
import { SegmentSales } from './components/SegmentSales';
import { SquareMeterSales } from './components/SquareMeterSales';
import { StoreSales } from './components/StoreSales';
import { StoreViewDailyProvider } from './context/storeViewDailyContext';

const StoreViewDaily = () => {
  const { selectedStore, isLoading, storeDetails } = useDashboard();

  if (isLoading) return <Loading />;

  return (
    <>
      <Dashboard
        items={[
          `Marca ${storeDetails?.brand ?? ''}`,
          `Segmento ${storeDetails?.segment ?? ''}`,
          `Piso ${storeDetails?.flooring ?? ''}`
        ]}
      />
      <Stack direction="column" gap={2}>
        {selectedStore.length ? (
          <>
            <StoreViewDailyProvider>
              <RangeDatepicker />
              <SalesAndMarketShare />
              <StoreSales />
              <Stack direction="row" gap={2}>
                <BranchSegmentSales />
                <BranchSales />
                <FlooringSales />
              </Stack>
              <SegmentSales />
              <SquareMeterSales />
            </StoreViewDailyProvider>
            <BrandsSegmentBranch />
          </>
        ) : (
          <Stack justifyContent="center" alignItems="center" marginTop={6}>
            <h2>Selecione uma loja para continuar</h2>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export { StoreViewDaily };
