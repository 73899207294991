import { Axios, AxiosError } from 'axios';
import { HTTPService } from '../shared/axios';
import { Status } from '../../model/status';

interface IntegrationServiceError {
  errors: [{ type: string; message: string }];
}

export interface IntegrationServiceResponse<T> {
  data?: T;
  errors?: string[];
}

export interface PaginatedResponse<T> {
  page_number: number;
  page_size: number;
  total_record_count: number;
  records: T;
}

export interface GetProjectItemsProps {
  projectId: string;
  itemsByPage?: number;
  page?: number;
  filter?: { status?: Status };
}

class HttpIntegrationService {
  protected readonly httpService: Axios;

  constructor() {
    this.httpService = HTTPService;
  }

  protected static handleClientError(
    error: AxiosError<IntegrationServiceError>
  ): { errors: string[] } {
    if (error.response) {
      const { status, data } = error.response;
      if (status >= 400 && status < 500) {
        if (data) {
          return {
            errors: data.errors.map((e) => e.message)
          };
        }
      }
    }

    return HttpIntegrationService.handleServerError();
  }

  protected static handleServerError() {
    return {
      errors: ['Ocorreu um erro, tente novamente mais tarde']
    };
  }

  protected static handleError(error: Error) {
    if (error instanceof AxiosError) {
      const formattedError = HttpIntegrationService.handleClientError(
        error as AxiosError<IntegrationServiceError>
      );

      return {
        errors: formattedError.errors
      };
    }

    return {
      errors: HttpIntegrationService.handleServerError().errors
    };
  }
}

export default HttpIntegrationService;
