import { CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { lazy, Suspense } from 'react';
import { Outlet, useRoutes } from 'react-router-dom';

import { Loading } from '../components/Loading';
import RequireAuth from '../components/RequireAuth';
import { ROLES } from '../constants/roles';
import { StoreViewDaily } from '../pages/Dashboard/components/StoreView/Daily';
import { StoreViewMonthly } from '../pages/Dashboard/components/StoreView/Monthly';
import { StoreViewShopping } from '../pages/Dashboard/components/StoreView/Shopping';
import { BrMallsLightTheme } from '../theme/schemes/BrMallsLightTheme';
import { Routes } from './Routes';

const SignInPage = lazy(() => import('../pages/SignIn'));
const MainLayout = lazy(() => import('../pages/MainLayout'));
const NewProjectPage = lazy(() => import('../pages/NewProjectPage'));
const ProjectListPage = lazy(() => import('../pages/ProjectListPage'));
const RecoverPasswordPage = lazy(() => import('../pages/RecoverPasswordPage'));
const ResetPasswordPage = lazy(() => import('../pages/ResetPasswordPage'));
const ProjectItemsListsPage = lazy(() => import('../pages/ProjectItemsLists'));
const UserManagementPage = lazy(() => import('../pages/UserManagementPage'));
const NotFoundPage = lazy(() => import('../pages/HTTPStatusPage/404'));
const UnauthorizedPage = lazy(() => import('../pages/HTTPStatusPage/401'));
const BenefitsCouponListingPage = lazy(
  () => import('../pages/BenefitsPage/Coupons/Listing')
);
const BenefitsCouponShowPage = lazy(
  () => import('../pages/BenefitsPage/Coupons/Show')
);

export function AppRoutes() {
  const routes = useRoutes([
    {
      path: Routes.SignIn,
      element: (
        <Suspense fallback={<Loading />}>
          <SignInPage />
        </Suspense>
      )
    },
    {
      path: Routes.RecoverPassword,
      element: (
        <Suspense fallback={<Loading />}>
          <RecoverPasswordPage />
        </Suspense>
      )
    },
    {
      path: Routes.ResetPassword,
      element: (
        <Suspense fallback={<Loading />}>
          <ResetPasswordPage />
        </Suspense>
      )
    },
    {
      path: Routes.Home,
      element: (
        <Suspense fallback={<Loading />}>
          <MainLayout />
        </Suspense>
      ),
      children: [
        {
          path: '/*',
          element: <NotFoundPage />
        },
        {
          path: Routes.Unauthorized,
          element: <UnauthorizedPage />
        },
        {
          path: Routes.Benefits,
          element: (
            <Suspense fallback={<Loading />}>
              <SnackbarProvider>
                <ThemeProvider theme={BrMallsLightTheme}>
                  <CssBaseline />
                  <Outlet />
                </ThemeProvider>
              </SnackbarProvider>
            </Suspense>
          ),
          children: [
            {
              path: Routes.Coupons,
              element: <BenefitsCouponListingPage />
            },

            {
              path: `${Routes.Coupon}/:couponId`,
              element: <BenefitsCouponShowPage />
            }
          ]
        },
        {
          path: Routes.Dashboard,
          element: (
            <Suspense fallback={<Loading />}>
              <RequireAuth
                allowedRoles={[
                  ROLES.Admin.slug,
                  ROLES.AnalyticsManager.slug,
                  ROLES.AnalyticsBrand.slug,
                  ROLES.AnalyticsStore.slug
                ]}
              />
            </Suspense>
          ),
          children: [
            {
              path: Routes.DashboardStore,
              children: [
                {
                  path: `${Routes.DashboardStoreMonthly}`,
                  element: (
                    <Suspense fallback={<Loading />}>
                      <StoreViewMonthly />
                    </Suspense>
                  )
                },
                {
                  path: `${Routes.DashboardStoreDaily}`,
                  element: (
                    <Suspense fallback={<Loading />}>
                      <StoreViewDaily />
                    </Suspense>
                  )
                },
                {
                  path: `${Routes.DashboardStoreMall}`,
                  element: (
                    <Suspense fallback={<Loading />}>
                      <StoreViewShopping />
                    </Suspense>
                  )
                }
              ]
            }
          ]
        },
        {
          path: Routes.Catalog,
          element: (
            <RequireAuth
              allowedRoles={[ROLES.Admin.slug, ROLES.Catalog.slug]}
            />
          ),
          children: [
            {
              path: '',
              element: (
                <Suspense fallback={<Loading />}>
                  <NewProjectPage />
                </Suspense>
              )
            },
            {
              path: Routes.ProjectsCurated,
              element: (
                <Suspense fallback={<Loading />}>
                  <ProjectListPage />
                </Suspense>
              )
            },
            {
              path: Routes.ProjectItemsFromCurated,
              element: (
                <Suspense fallback={<Loading />}>
                  <ProjectItemsListsPage />
                </Suspense>
              )
            }
          ]
        },
        {
          path: Routes.Management,
          element: (
            <RequireAuth
              allowedRoles={[
                ROLES.Admin.slug,
                ROLES.BenefitsAccessManagement.slug
              ]}
            />
          ),
          children: [
            {
              path: '',
              element: (
                <Suspense fallback={<Loading />}>
                  <h1>Configurações</h1>
                </Suspense>
              )
            },
            {
              path: Routes.UserManagement,
              element: (
                <Suspense fallback={<Loading />}>
                  <UserManagementPage />
                </Suspense>
              )
            }
          ]
        }
      ]
    }
  ]);

  return routes;
}
