import { useEffect, useMemo, useState } from 'react';
import { useGetSegmentSalesByMonthPeriod } from '../../../../../../../api/analytics/hooks/use-segment-sales';
import { GetSegmentSalesByMonthPeriodType } from '../../../../../../../api/analytics/mappers/segment-sales.mapper';
import { ColumnChartWithLine } from '../../../../../../../common/analytics/ColumnChartWithLine';
import { useDashboard } from '../../../../../hooks/useDashboard';
import { useDashboardMonthlyContext } from '../../hooks/useDashboardMonthlyContext';

const SegmentSales = () => {
  const { selectedStore } = useDashboard();
  const { period } = useDashboardMonthlyContext();
  const [keyCount, setKeyCount] = useState(0);

  const { data, isLoading } = useGetSegmentSalesByMonthPeriod({
    since: period.since,
    to: period.to,
    accessDataKey: selectedStore
  });

  const segmentSalesList = useMemo<GetSegmentSalesByMonthPeriodType[]>(
    () => (data?.data ?? []) as GetSegmentSalesByMonthPeriodType[],
    [data?.data]
  );

  useEffect(() => {
    setKeyCount((prev) => prev + 1);
  }, [segmentSalesList]);

  return (
    <ColumnChartWithLine
      key={keyCount}
      title="VENDAS MENSAIS DO SEGMENTO"
      data={segmentSalesList}
      isLoading={isLoading}
      stacked
      helpText="Comparativo das vendas totais da marca em cada mês do período selecionado versus as vendas totais do segmento e sua representatividade (%)."
    />
  );
};

export { SegmentSales };
